import React from 'react';
import {
  SliceConfig,
  IntersectionAnimation
} from '@lam-agency/toolkit/components';
import { useInView } from 'react-intersection-observer';
import { IToolkitColorGrid } from '@lam-agency/sanity';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import ColorTile from './components/ColorTile';
import { useMVT } from '@lam-agency/toolkit/stores';

interface IProps {
  data: IToolkitColorGrid;
}

export interface INewColorDetails {
  title: string;
  hex: string;
}

const ColorGrid = ({ data: { category, sliceConfig } }: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  const sliceBackgroundColor = sliceConfig.backgroundColor?.hex;

  const { colors } = useMVT();

  const filteredColors = colors.filter((color) => color.category === category);

  const getBorderColor = (index: number) => {
    const tileColor = filteredColors[index].hex;
    if (sliceBackgroundColor?.toLowerCase() !== tileColor.toLowerCase())
      return ``;

    if (index === 0) {
      return filteredColors[1].hex;
    }

    return filteredColors[index - 1].hex;
  };

  const isThreeColumn = filteredColors.length < 3;

  const ANIMATION_STAGGER = 150;

  return (
    <SliceConfig config={sliceConfig}>
      <IntersectionAnimation>
        <ul
          className={cn(styles.grid, { [styles.threeColumn]: isThreeColumn })}
          ref={ref}
        >
          <>
            {filteredColors.map((color, i) => (
              <IntersectionAnimation
                key={color.id}
                animation="fadeGrow"
                trigger={inView}
                delay={i * ANIMATION_STAGGER}
              >
                <ColorTile color={color} borderColor={getBorderColor(i)} />
              </IntersectionAnimation>
            ))}
          </>
        </ul>
      </IntersectionAnimation>
    </SliceConfig>
  );
};

export default ColorGrid;
