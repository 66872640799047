import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { LayoutProvider } from '@lam-agency/toolkit/components';
import { SVG } from '@lam-agency/toolkit/components';
import { useMVT } from '@lam-agency/toolkit/stores';
import { getTextThemeByBackgroundHex } from '@lam-agency/toolkit/utils';
import { IColor } from 'lam-toolkit-figma-plugin';

import * as styles from './styles.module.scss';

interface IProps {
  data: {
    svg: 'wordmark' | 'logomark';
    category: 'primary' | 'secondary';
  };
  className?: string;
}

const LogoFrames = ({ data, className }: IProps) => {
  const { svg, category } = data || {};
  const { colors } = useMVT();
  const filteredColors = colors.filter((color) => color.category === category);

  const [selectedColor, setSelectedColor] = useState<IColor | null>(null);
  const [selectedTheme, setSelectedTheme] = useState('dark');

  useEffect(() => {
    if (selectedColor || !filteredColors?.[0]) return;

    setSelectedColor(filteredColors?.[0]);
  }, [filteredColors]);

  useEffect(() => {
    if (!selectedColor) return;

    const theme = getTextThemeByBackgroundHex(selectedColor?.hex);
    setSelectedTheme(theme);
  }, [selectedColor]);

  // const theme = getTextThemeByBackgroundHex(hex);

  return (
    <section className={cn(styles.container, className)}>
      <LayoutProvider grid>
        {/* tablet/desktop */}
        {filteredColors.map((color, index) => {
          const theme = getTextThemeByBackgroundHex(color.hex);

          return (
            <div
              key={`logo-frames-frame-${color}-${index}`}
              className={cn(styles.logoFrame, styles[theme], styles.fixed)}
              style={{ background: `${color.hex}` }}
            >
              <figure>
                <SVG svg={svg} />
              </figure>
            </div>
          );
        })}

        {/* mobile */}
        <div className={styles.mobile}>
          <div
            className={cn(
              styles.logoFrame,
              styles[selectedTheme],
              styles.dynamic
            )}
            style={{ background: `${selectedColor?.hex}` }}
          >
            <figure>
              <SVG svg={svg} />
            </figure>
          </div>

          <ul className={styles.swatches}>
            {filteredColors.map((color, index) => {
              const selected = selectedColor === color;

              return (
                <li
                  key={`logo-frames-frame-mobile-${color}-${index}`}
                  className={cn(styles.swatch, styles[selectedTheme])}
                  style={{ background: `${color.hex}` }}
                  onClick={() => setSelectedColor(color)}
                >
                  <figure
                    className={cn(styles.check, { [styles.visible]: selected })}
                  >
                    <SVG svg="check" />
                  </figure>
                </li>
              );
            })}
          </ul>
        </div>
      </LayoutProvider>
    </section>
  );
};

export default LogoFrames;
