import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  children: React.ReactNode;
  className?: string;
  removePadding?: boolean;
  fullWidth?: boolean;
}

const Grid = ({
  children,
  removePadding = false,
  fullWidth = false,
  className
}: IProps) => (
  <div
    className={cn(styles.grid, className, {
      [styles.removePadding]: removePadding,
      [styles.fullWidth]: fullWidth
    })}
  >
    {children}
  </div>
);
export default Grid;
