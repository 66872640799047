import { IProps as IMagicPenPromptCategory } from "./components/PromptCategory";

export const templatePrompts: IMagicPenPromptCategory[] = [
  {
    title: "Social Media Post Creation",
    promptTemplates: [
      "Craft a short and punchy headline for [topic]",
      "Write an engaging tweet announcing a new partnership with [partner company]",
      "Create a LinkedIn post celebrating [milestone] achieved by [startup name]."
    ]
  },
  {
    title: "Blog Post Ideas",
    promptTemplates: [
      "Develop a blog title for an article about [topic] in the Australian startup scene",
      "Outline a blog post discussing the impact of [topic] on startups",
      "Generate an introductory paragraph for a blog about Euphemia's investment philosophy and its benefits for [startup type]"
    ]
  },
  {
    title: "Email Marketing",
    promptTemplates: [
      "Compose a subject line for an email newsletter focusing on [topic]",
      "Write an email introduction inviting recipients to [event] at [location]"
    ]
  },
  {
    title: "Inspirational and Educational Content",
    promptTemplates: [
      "Develop an inspirational quote graphic text about [topic] for Instagram",
      "Write a short Facebook post offering tips on [topic]",
      "Create a LinkedIn article title about lessons learned from [event] in the startup world"
    ]
  }
];
export const aboutEuphemiaPrompts: IMagicPenPromptCategory[] = [
  {
    title: "Brand and Strategy Insights",
    promptTemplates: [
      "Explain Euphemia's 'Go big and grow home' approach to investing",
      "How does Euphemia contribute to amplifying Australian innovation?",
      "Can you describe the unique challenges Euphemia sees in the venture capital landscape for Australian startups?"
    ]
  },
  {
    title: "Investment Philosophy and Approach",
    promptTemplates: [
      "What does 'Thoughtful Capital' mean in the context of Euphemia's investment strategy?",
      "How does Euphemia identify and support potential 'God-Founders' in the startup scene?",
      "Discuss Euphemia's perspective on the importance of connections and networks in startup success"
    ]
  },
  {
    title: "Startup Success Stories and Insights",
    promptTemplates: [
      "Share a success story of a startup that Euphemia helped grow.",
      "How does Euphemia's approach differ from traditional venture capital in nurturing startups?",
      "Illustrate the role of 'God-Maker' Euphemia plays in the journey of a startup."
    ]
  },
  {
    title: "Euphemia's Community and Culture",
    promptTemplates: [
      "Describe the community and environment Euphemia is fostering for tomorrow's startup scene.",
      "How does Euphemia maintain a diverse, progressive, and ambitious community?",
      "What initiatives does Euphemia undertake to ensure a dynamic and responsive ecosystem for startups?"
    ]
  }
];

export interface IPlatformData {
  title: string;
  examples: string[];
}

export const platforms: IPlatformData[] = [
  {
    title: "Instagram",
    examples: [
      "We loved chatting to Day One FM about all things investing, entrepreneurial spirit, and more.\n\nYou can listen to @fasttrackjudy on the first Cheque podcast with Euphemia portfolio company founder Cheryl Mack from Aussie Angels and portfolio fund Managing Partner, Maxine Minter from Co Ventures.\n\nLink in bio.",
      "Does Jackie Vullinghs think becoming a unicorn is awesome or average? What about remote work or ChatGPT?\n\nListen on, and follow the link in our bio to read about our awesome partnerships with @airtreevc.",
      "Very few startups end up as the company they started as, especially on a 10 year+ journey.\n\nSo what does @airtreevc look for in a founder? Jackie Vullinghs shares what stands out for her and is usually indicative of a founder being on the right path.\n\nFurthermore, together with @fasttrackjudy and @dompym, we discuss the founder/vc relationship, and what questions a founder can/should ask a VC.\n\nRead the full awesome story via the link in bio.",
      "It was a full house at Zeller HQ in Melbourne for The Startup Network FinTech Pitch Night recently, which was an awesome precursor to FinTech Australia’s Intersekt Conference.\n\nCongrats to the winner, @parperamoney. We’re excited to watch their journey and be a supporter.\n\nPictured is the crowd at the event and the winning Founder pitching, @dancannizzaro.",
      "Recently @dompym had the awesome opportunity to be a guest speaker for Swinburne's school of entrepreneurship.\n\nThe fireside chat was a chance for student’s to ask questions about Dom’s Journey from Ferocia to Up, to the awesome founders we’re supporting at Euphemia today, and looking for in the future.\n\nLink to the full video in bio."
    ]
  },
  {
    title: "LinkedIn",
    examples: [
      "We loved chatting to Day One FM about all things investing, entrepreneurial spirit, and more.\n\nYou can listen to @fasttrackjudy on the first Cheque podcast with Euphemia portfolio company founder Cheryl Mack from Aussie Angels and portfolio fund Managing Partner, Maxine Minter from Co Ventures.\n\nLink in bio.",
      "Does Jackie Vullinghs think becoming a unicorn is awesome or average? What about remote work or ChatGPT?\n\nListen on, and follow the link in our bio to read about our awesome partnerships with @airtreevc.",
      "Very few startups end up as the company they started as, especially on a 10 year+ journey.\n\nSo what does @airtreevc look for in a founder? Jackie Vullinghs shares what stands out for her and is usually indicative of a founder being on the right path.\n\nFurthermore, together with @fasttrackjudy and @dompym, we discuss the founder/vc relationship, and what questions a founder can/should ask a VC.\n\nRead the full awesome story via the link in bio.",
      "It was a full house at Zeller HQ in Melbourne for The Startup Network FinTech Pitch Night recently, which was an awesome precursor to FinTech Australia’s Intersekt Conference.\n\nCongrats to the winner, @parperamoney. We’re excited to watch their journey and be a supporter.\n\nPictured is the crowd at the event and the winning Founder pitching, @dancannizzaro.",
      "Recently @dompym had the awesome opportunity to be a guest speaker for Swinburne's school of entrepreneurship.\n\nThe fireside chat was a chance for student’s to ask questions about Dom’s Journey from Ferocia to Up, to the awesome founders we’re supporting at Euphemia today, and looking for in the future.\n\nLink to the full video in bio."
    ]
  }
];
