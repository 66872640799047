import React from 'react';
import {
  IconInspector,
  LayoutProvider,
  SliceHeader,
  TextColumns
} from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';

interface IProps {
  data: {
    fields: {
      heading: string;
      subheading: string;
      bodyLeft: string;
      bodyCenter: string;
      bodyRight: string;
    };
  };
}

const FigmaIllustrations = ({ data: { fields } }: IProps) => {
  const { heading, subheading, bodyLeft, bodyCenter, bodyRight } = fields || {};

  // const buttons = parseButtonDataFromFigma([buttonText1, buttonText2]);

  return (
    <LayoutProvider paddingX paddingY>
      <LayoutProvider grid>
        <SliceHeader className={styles.header} data={{ heading }} />
      </LayoutProvider>

      <IconInspector />

      <TextColumns
        data={{ heading: subheading, bodyLeft, bodyCenter, bodyRight }}
      />
    </LayoutProvider>
  );
};

export default FigmaIllustrations;
