import React from 'react';
import {
  LayoutProvider,
  MediaItem,
  SliceHeader,
  TwoColumnText
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { ISliceMediaGrid } from 'lam-toolkit-figma-plugin';

export const FigmaMediaGrid = ({
  mediaItems,
  sliceHeader,
  twoColumnText
}: ISliceMediaGrid) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <LayoutProvider paddingX paddingY>
          <SliceHeader {...sliceHeader} />

          {twoColumnText && <TwoColumnText {...twoColumnText} />}

          <LayoutProvider grid>
            {mediaItems?.map((mediaItem, i) => (
              <MediaItem className={styles.gridItem} key={i} {...mediaItem} />
            ))}
          </LayoutProvider>
        </LayoutProvider>
      </div>
    </div>
  );
};
