// extracted by mini-css-extract-plugin
export var colorTiles = "styles-module--colorTiles--0bb67";
export var container = "styles-module--container--75102";
export var copy = "styles-module--copy--603b2";
export var h1 = "styles-module--h1--3f512";
export var h2 = "styles-module--h2--e0eae";
export var images = "styles-module--images--8a4d2";
export var list = "styles-module--list--2690b";
export var openPaletteButton = "styles-module--openPaletteButton--d2488";
export var pageLayout = "styles-module--pageLayout--9453f";
export var section = "styles-module--section--7731d";
export var titleAndPaletteButton = "styles-module--titleAndPaletteButton--829a8";
export var video = "styles-module--video--b005d";
export var videos = "styles-module--videos--484a9";