import React, { useRef } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

const ShoeModel = () => {
  const ref = useRef<any>(null);

  useFrame((_state, delta) => {
    ref.current.rotation.z += delta / 2;
    ref.current.rotation.y += delta / 3;
  });

  // Load the OBJ file using useLoader and OBJLoader
  const obj = useLoader(OBJLoader, `/webgl/models/shoe/low_obj_7000.obj`);

  return (
    <mesh ref={ref} position={[0, 0, -45]}>
      <primitive object={obj} />
    </mesh>
  );
};

export default ShoeModel;
