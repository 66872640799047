// extracted by mini-css-extract-plugin
export var colour = "styles-module--colour--bab10";
export var colours = "styles-module--colours--41fe8";
export var container = "styles-module--container--19a6e";
export var desktop = "1340px";
export var fourCol = "styles-module--fourCol--06e77";
export var giant = "2200px";
export var grid = "styles-module--grid--3adfa";
export var heading = "styles-module--heading--61829";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";