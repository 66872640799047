import React from 'react';
import * as styles from './styles.module.scss';
import CopyElementToPalette from '../CopyElementToPalette';
import { IPaletteLogo } from '../../types';

const PaletteLogo = ({ url, id }: IPaletteLogo) => {
  return (
    <CopyElementToPalette className={styles.container} type="logo" id={id}>
      <img className={styles.image} src={url} alt="" />
    </CopyElementToPalette>
  );
};

export default PaletteLogo;
