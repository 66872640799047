// extracted by mini-css-extract-plugin
export var button = "styles-module--button--79218";
export var categoryPages = "styles-module--categoryPages--39bf5";
export var categoryTitle = "styles-module--categoryTitle--7c0fb";
export var container = "styles-module--container--52613";
export var contentContainer = "styles-module--contentContainer--556ae";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--f82d6";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var logo = "styles-module--logo--82378";
export var mobile = "400px";
export var pageLink = "styles-module--pageLink--c4304";
export var pages = "styles-module--pages--3eb0b";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var year = "styles-module--year--cf214";