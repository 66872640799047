import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  color: string;
  active: boolean;
  handleClick: () => void;
}

const ColorButton = ({ active, color, handleClick }: IProps) => {
  return (
    <button
      type="button"
      style={{ backgroundColor: color }}
      onClick={handleClick}
      className={cn(styles.button, { [styles.active]: active })}
      aria-label="Change color"
    />
  );
};

export default ColorButton;
