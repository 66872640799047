import React, { useState } from 'react';
import * as styles from './styles.module.scss';
import {
  ImageCopyDownloadButtons,
  LayoutProvider,
  PreviewGridButton,
  SliceHeader,
  TextAnimation
} from '@lam-agency/toolkit/components';
import { ISliceIconSet } from 'lam-toolkit-figma-plugin';
import {
  BrandIcon,
  brandIcons,
  TBrandIcon
} from '@lam-agency/toolkit/custom/components/BrandIcon';
import { svgToDataURL, toKebabCase } from '@lam-agency/toolkit/utils';

export const FigmaIconSet = ({ sliceHeader }: ISliceIconSet) => {
  const brandIconNames = Object.keys(brandIcons) as TBrandIcon[];

  const [activeIcon, setActiveIcon] = useState<TBrandIcon>(brandIconNames[0]);

  const iconDataURL = svgToDataURL(<BrandIcon icon={activeIcon} />);

  return (
    <LayoutProvider paddingX paddingY>
      <SliceHeader {...sliceHeader} />

      <LayoutProvider grid className={styles.grid}>
        <div className={styles.options}>
          <div className={styles.optionsGrid}>
            {brandIconNames.map((icon, i) => (
              <PreviewGridButton
                key={i}
                active={activeIcon === icon}
                onClick={() => setActiveIcon(icon)}
              >
                <BrandIcon className={styles.iconButton} icon={icon} />
              </PreviewGridButton>
            ))}
          </div>
        </div>

        <div className={styles.inspectorColumn}>
          <div className={styles.inspector}>
            <div className={styles.inspectorContent}>
              <BrandIcon className={styles.iconPreview} icon={activeIcon} />
            </div>

            <ImageCopyDownloadButtons
              colorScheme="light"
              logoDataUrl={iconDataURL}
              className={styles.copyDownloadButtons}
              imageDownloadFilename={toKebabCase(activeIcon)}
            />
          </div>

          <TextAnimation
            text={activeIcon}
            animation="typed"
            element="h2"
            className="h2"
            trigger
          />
        </div>
      </LayoutProvider>
    </LayoutProvider>
  );
};
