// extracted by mini-css-extract-plugin
export var buttonIcon = "styles-module--buttonIcon--b17a7";
export var container = "styles-module--container--cd9c2";
export var content = "styles-module--content--8dd95";
export var desktop = "1340px";
export var giant = "2200px";
export var handle = "styles-module--handle--e906a";
export var label = "styles-module--label--5c98a";
export var labelText = "styles-module--labelText--9fcdd";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var profilePic = "styles-module--profilePic--eb8a0";
export var saveButton = "styles-module--saveButton--33227";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var topContent = "styles-module--topContent--05661";