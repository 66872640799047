import React from 'react';
import {
  LayoutProvider,
  SliceHeader,
  TextImageGrid
} from '@lam-agency/toolkit/components';

interface IProps {
  data: {
    fields: {
      heading?: string;
      body?: string;
    };
  };
}

const FigmaPeopleImagery = ({ data: { fields } }: IProps) => {
  const { heading, body } = fields || {};

  const imageGridItems = [
    {
      image: '/mvt-images/art-direction/people-imagery-1.jpg'
    },
    {
      image: '/mvt-images/art-direction/people-imagery-2.jpg'
    }
  ];

  return (
    <LayoutProvider paddingX paddingY>
      <SliceHeader data={{ heading }} />

      <TextImageGrid body={body} bodyPosition="right" items={imageGridItems} />
    </LayoutProvider>
  );
};

export default FigmaPeopleImagery;
