import React from 'react';
import * as styles from './styles.module.scss';
import SelectedContentWrapper from '../SelectedContentWrapper';

interface IProps {
  hex: string;
  id: string;
}

const SelectedColor = ({ hex, id }: IProps) => {
  return (
    <SelectedContentWrapper id={id} type="colors">
      <div style={{ background: hex }} className={styles.container} />
    </SelectedContentWrapper>
  );
};

export default SelectedColor;
