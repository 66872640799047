import React from 'react';
import { Button } from '@lam-agency/toolkit/components';
import cn from 'classnames';

import * as styles from './styles.module.scss';

export interface IProductCard {
  image?: string;
  title?: string;
  body?: string;
  buttonText?: string;
  buttonUrl?: string;
}

interface IProps {
  data: IProductCard;
  className?: string;
}

const ProductCard = ({ data, className }: IProps) => {
  const { image, title, body, buttonText, buttonUrl } = data || {};

  return (
    <article className={cn(styles.container, className)}>
      {image && (
        <figure className={styles.image}>
          <img src={image} alt={title} />
        </figure>
      )}

      <div className={styles.text}>
        {title && <p className={cn('b1', styles.title)}>{title}</p>}
        {body && <p className="b2">{body}</p>}

        {buttonText && buttonUrl && (
          <div>
            <Button to={buttonUrl}>{buttonText}</Button>
          </div>
        )}
      </div>
    </article>
  );
};

export default ProductCard;
