import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import useMagicPenContext from '../../context';
import ActionButtons from '../ActionButtons';
import EditingUI from '../EditingUI';
import LoadingOverlay from '../LoadingOverlay';
import AddMediaButton from '../AddMediaButton';
import ImageUI from '../ImageUI';

interface IProps {
  content?: string;
  getPostContent?: () => Promise<void>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  isEditing: boolean;
  handleUpdateActivePostContent: (updatedContent: string) => void;
}

const LinkedIn = ({
  content,
  getPostContent,
  handleUpdateActivePostContent,
  isEditing,
  setIsEditing
}: IProps) => {
  const { setShowMediaLibrary, selectedMedia } = useMagicPenContext();

  const isLoading = !content && !isEditing;

  return (
    <div className={styles.container}>
      <LoadingOverlay isLoading={isLoading} />

      <div className={styles.topContent}>
        <img src="" className={styles.profilePic} />
        <div className={cn('b1', styles.handle)}>Love + Money</div>
      </div>

      {isEditing && (
        <EditingUI
          content={content}
          handleUpdateActivePostContent={handleUpdateActivePostContent}
          setIsEditing={setIsEditing}
        />
      )}

      {!isEditing && (
        <>
          <p className={cn('b1', styles.content)}>{content}</p>

          <ActionButtons
            activePostContent={content || ''}
            getPostContent={getPostContent}
            setIsEditing={setIsEditing}
            className={styles.actionButtons}
          />
        </>
      )}

      {!selectedMedia && (
        <AddMediaButton setShowMediaLibrary={setShowMediaLibrary} />
      )}

      {selectedMedia && (
        <ImageUI
          selectedMedia={selectedMedia}
          setShowMediaLibrary={setShowMediaLibrary}
        />
      )}
    </div>
  );
};

export default LinkedIn;
