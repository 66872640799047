// extracted by mini-css-extract-plugin
export var borderBottom = "styles-module--borderBottom--e1361";
export var chevron = "styles-module--chevron--57fde";
export var container = "styles-module--container--b5055";
export var content = "styles-module--content--d418b";
export var contentWrapper = "styles-module--contentWrapper--f7be8";
export var desktop = "1340px";
export var expanded = "styles-module--expanded--35591";
export var footer = "styles-module--footer--792ca";
export var footerButtons = "styles-module--footerButtons--822bd";
export var giant = "2200px";
export var header = "styles-module--header--3a613";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var link = "styles-module--link--2367a";
export var linkEllipsis = "styles-module--linkEllipsis--c37de";
export var linkSvg = "styles-module--linkSvg--759d9";
export var links = "styles-module--links--95db1";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";