import React, { useState } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import {
  LoadingSpinner,
  SliceConfig,
  TextInput
} from '@lam-agency/toolkit/components';
import { useApp } from '@lam-agency/toolkit/hooks';
import { Button } from '@lam-agency/toolkit/components';

type TState = 'idle' | 'loading' | 'success' | 'error';

const AndrewOnTheBlower = () => {
  const [input, setInput] = useState('');
  const [state, setState] = useState<TState>('idle');
  const [response, setResponse] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { updateNotification } = useApp();

  const handleGetResponse = async () => {
    setState('loading');

    const prompt = `
      Below is a brief, or a transcript from a briefing video. Please format the contents of the transcript into an Asana brief for a to the following LaM Standard Briefing Format.

      Start with an OVERVIEW of the brief. Note: this should be an overview of the task, not of the transcript. Include the following, to the best of your ability:
      Brief Author [Name of USER]
      Client Name [CLIENT NAME]
      Due Date: [2 Weeks from DATE]
      Deliverables: [A short list of the SUBTASKS]
      Team: [A list of the TEAM members required to perform the SUBTASKS]
      Specs [OUTPUTS]
      Rounds of Feedback [1]
      
      Set an appropriate SMART goal (OBJECTIVE). Try your best to determine the approx TIME needed for each SUBTASK [given in half-day or full day sprints]. 
      
      Break the brief to be completed NOW into SUBTASKS (e.g. 'Build a Button component') from the contents of the transcript. 
      
      Make suggestions on the OUTPUTS for each brief (e.g. any 'Instagram Campaigns' would need to be matched to a FORMAT [e.g. .jpg, .mov, etc.], DIMENSIONS [e.g. 16x9, etc.], as well as any format-specific details like DURATION or FRAME RATE for video.)
      
      If details are missing, prompt the User for further clarification. 
      Make it clear that all work is subject to Love and Money's Standard Terms and Conditions.
      
      Here is the brief / transcript:

      ===

      ${input}
    `;

    try {
      const response = await fetch(
        'https://chatgpt-express-app.onrender.com/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ prompt })
        }
      );
      console.log(response);

      if (response.status !== 200) throw new Error('Something went wrong');

      const data = await response.text();
      setResponse(data);
      setState('success');
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message as string);
      setState('error');
    }
  };

  const handleCopyResponse = () => {
    navigator.clipboard.writeText(response);
    updateNotification(`Copied to clipboard!`);
  };

  return (
    <section>
      <SliceConfig>
        <h1 className={cn('h1', styles.title)}>Andrew On The Blower</h1>

        <p className={cn('h3', styles.description)}>
          Paste in a Loom transcript and get a brief.
        </p>

        {state === 'idle' && (
          <>
            <TextInput
              value={input}
              onChange={setInput}
              textarea
              className={styles.textarea}
              disableMaxLength
              placeholder="Enter transcript..."
            />

            <Button onClick={handleGetResponse} disabled={!input}>
              Submit
            </Button>
          </>
        )}

        {state === 'loading' && <LoadingSpinner />}

        {state === 'success' && (
          <div className={styles.responseContainer}>
            <p className={cn('b1', styles.response)}>{response}</p>

            <Button onClick={handleCopyResponse} iconLeft="copy">
              Copy
            </Button>
          </div>
        )}

        {state === 'error' && <p className={cn('b1')}>Error: {errorMessage}</p>}
      </SliceConfig>
    </section>
  );
};

export default AndrewOnTheBlower;
