import React from 'react';
import { LayoutProvider, TextColumns } from '@lam-agency/toolkit/components';

interface IProps {
  data: {
    fields: {
      heading?: string;
      buttonText?: string;
      subheading?: string;
      body1?: string;
      body2?: string;
      body3?: string;
    };
  };
}

const delay = 100;

const FigmaTextColumns = ({ data: { fields } }: IProps) => {
  const { heading, body1, body2, body3 } = fields || {};

  return (
    <LayoutProvider paddingX>
      <TextColumns
        data={{ heading, bodyLeft: body1, bodyCenter: body2, bodyRight: body3 }}
        animationDelay={delay}
      />
    </LayoutProvider>
  );
};

export default FigmaTextColumns;
