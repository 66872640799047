import React from 'react';
import * as styles from './styles.module.scss';
import { IWidgetProps } from '../..';
import { Button } from '@lam-agency/toolkit/components';

const ATFWidgetContents = ({
  assetLinks,
  contactURL,
  quickLinks
}: IWidgetProps) => {
  return (
    <div className={styles.container}>
      {assetLinks?.[0] && (
        <div className={styles.body}>
          <p className="caption">Downloadable Assets</p>

          <ul className={styles.assetLinks}>
            {assetLinks.map(({ text, url }, i) => (
              <li key={i}>
                <>
                  {/* Mobile */}
                  <div className={styles.mobileAssetLink}>
                    <Button fluid iconRight="download" to={url}>
                      {text}
                    </Button>
                  </div>

                  {/* Desktop */}
                  <div className={styles.desktopAssetLink}>
                    <span className="b1">{text}</span>
                    <span className={styles.ellipsis} />
                    <Button variant="text" iconRight="download" to={url}>
                      Download
                    </Button>
                  </div>
                </>
              </li>
            ))}
          </ul>
        </div>
      )}

      {quickLinks?.[0] && (
        <div className={styles.body}>
          <p className="caption">Quick Links</p>

          <ul className={styles.quickLinks}>
            {quickLinks.map(({ text, url }, i) => (
              <li key={i}>
                <Button fluid iconRight="externalLink" to={url}>
                  {text}
                </Button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {contactURL && (
        <div className={styles.body}>
          <p className="caption">Contact</p>

          <div className={styles.contactBody}>
            <p className="b2">
              Can’t find what you’re after or need to talk to someone about
              specifics?
            </p>

            <Button to={contactURL}>Get in Touch</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ATFWidgetContents;
