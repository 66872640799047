import React from 'react';
import { LayoutProvider, SliceHeader } from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { ISliceLockedSection } from 'lam-toolkit-figma-plugin';

export const FigmaLockedSection = ({ title }: ISliceLockedSection) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <LayoutProvider paddingX paddingY>
          <SliceHeader locked heading={title} partition />
        </LayoutProvider>
      </div>
    </div>
  );
};
