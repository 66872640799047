// extracted by mini-css-extract-plugin
export var container = "styles-module--container--09761";
export var desktop = "1340px";
export var giant = "2200px";
export var image = "styles-module--image--35507";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--04eb5";
export var title = "styles-module--title--e4a63";