// extracted by mini-css-extract-plugin
export var button = "styles-module--button--ea5bf";
export var container = "styles-module--container--723d0";
export var desktop = "1340px";
export var formContainer = "styles-module--formContainer--8f3e8";
export var formContent = "styles-module--formContent--1e3a5";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var widget = "styles-module--widget--b81f4";
export var xIcon = "styles-module--xIcon--ffca9";