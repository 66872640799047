import React, { useState } from 'react';
import cn from 'classnames';
import {
  IntersectionAnimation,
  LayoutProvider
} from '@lam-agency/toolkit/components';
import { SVG, TSVG, svgs } from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';

const IconInspector = () => {
  const svgKeys: TSVG[] = Object.keys(svgs) as TSVG[];
  const [selectedSvg, setSelectedSvg] = useState(svgKeys[0]);

  const reformatCamelCase = (value: string) => {
    let formattedString = value.replace(/([A-Z])/g, ' $1');

    formattedString =
      formattedString.charAt(0).toUpperCase() + formattedString.slice(1);
    return formattedString.trim(); // Trim any leading/trailing whitespace
  };

  return (
    <div className={styles.container}>
      <LayoutProvider className={styles.grid} grid>
        {selectedSvg && (
          <IntersectionAnimation
            className={styles.preview}
            animation="fadeGrow"
          >
            <figure className={styles.previewSvg}>
              <SVG svg={selectedSvg} />
            </figure>

            <p className="h2">{reformatCamelCase(selectedSvg)}</p>
          </IntersectionAnimation>
        )}

        {svgKeys?.[0] && (
          <div className={styles.library}>
            {svgKeys.map((svg: TSVG, index) => {
              const isSelected = svg === selectedSvg;

              return (
                <IntersectionAnimation
                  key={`icon-inspector-svg-${svg}`}
                  delay={index * 25}
                >
                  <figure
                    className={cn(styles.librarySvg, {
                      [styles.selected]: isSelected
                    })}
                    onClick={() => setSelectedSvg(svg)}
                  >
                    <SVG svg={svg} />
                  </figure>
                </IntersectionAnimation>
              );
            })}
          </div>
        )}
      </LayoutProvider>
    </div>
  );
};

export default IconInspector;
