import React from 'react';
import * as styles from './styles.module.scss';
import { TPage } from '@lam-agency/toolkit/hooks/usePageNavigation';
import cn from 'classnames';
import { Link } from 'gatsby';

interface IProps {
  page: TPage;
}

const LinkTile = ({ page }: IProps) => {
  const Element = page.disabled ? `div` : Link;

  return (
    <Element
      to={`/${page.slug.current}`}
      className={cn(styles.container, { [styles.disabled]: page.disabled })}
    >
      <div className={styles.content}>
        <div className="b2">{page.title}</div>
        <div className={cn(`d1`, styles.abbreviation)}>{page.abbreviation}</div>
      </div>
    </Element>
  );
};

export default LinkTile;
