import React from 'react';
import {
  SliceConfig,
  IntersectionAnimation,
  Image
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import cn from 'classnames';
import { IToolkitMedia } from '@lam-agency/sanity';

interface IProps {
  data: IToolkitMedia;
}

const Media = ({ data: { mediaItems, sliceConfig } }: IProps) => {
  if (!mediaItems) return null;

  const numberOfColumns: () => number = () => {
    const numberOfItems = mediaItems.length;

    if (numberOfItems === 1) {
      return 1;
    }

    // If items is divisible by 2, but NOT by 3
    if (numberOfItems % 2 === 0 && numberOfItems % 3 !== 0) {
      return 2;
    }

    return 3;
  };

  return (
    <IntersectionAnimation>
      <SliceConfig config={sliceConfig}>
        <div
          className={cn(styles.grid, {
            [styles.oneColumnLayout]: numberOfColumns() === 1,
            [styles.twoColumnLayout]: numberOfColumns() === 2,
            [styles.threeColumnLayout]: numberOfColumns() === 3
          })}
        >
          {mediaItems.map((item, i) => {
            if (!item) return null;

            return (
              <IntersectionAnimation
                animation="fadeGrow"
                delay={i * 100}
                key={i}
                className={styles.mediaItemContainer}
              >
                {item._type === 'altImage' && <Image image={item} />}

                {item._type === 'videoFile' && (
                  <video
                    className={styles.video}
                    src={item.file.asset.url}
                    muted
                    loop
                    autoPlay
                    playsInline
                  />
                )}

                {item._type === 'videoURL' && (
                  <video
                    src={item.url}
                    className={styles.video}
                    muted
                    loop
                    autoPlay
                    playsInline
                  />
                )}
              </IntersectionAnimation>
            );
          })}
        </div>
      </SliceConfig>
    </IntersectionAnimation>
  );
};

export default Media;
