import React from 'react';
import {
  LayoutProvider,
  SliceHeader,
  TextColumns,
  ProductGrid
} from '@lam-agency/toolkit/components';

interface IProps {
  data: {
    fields: {
      heading?: string;
      subheading?: string;
      body1?: string;
      body2?: string;
      body3?: string;
      cardTitle1?: string;
      cardBody1?: string;
      cardTitle2?: string;
      cardBody2?: string;
      cardTitle3?: string;
      cardBody3?: string;
      cardButtonText1?: string;
      cardButtonUrl1?: string;
      cardButtonText2?: string;
      cardButtonUrl2?: string;
      cardButtonText3?: string;
      cardButtonUrl3?: string;
    };
  };
}

const FigmaProductImagery = ({ data: { fields } }: IProps) => {
  const {
    heading,
    subheading,
    body1,
    body2,
    body3,
    cardTitle1,
    cardBody1,
    cardTitle2,
    cardBody2,
    cardTitle3,
    cardBody3,
    cardButtonText1,
    cardButtonUrl1,
    cardButtonText2,
    cardButtonUrl2,
    cardButtonText3,
    cardButtonUrl3
  } = fields || {};

  const productGridItems = [
    {
      image: '/mvt-images/art-direction/product-imagery-1.jpg',
      title: cardTitle1,
      body: cardBody1,
      buttonText: cardButtonText1,
      buttonUrl: cardButtonUrl1
    },
    {
      image: '/mvt-images/art-direction/product-imagery-2.jpg',
      title: cardTitle2,
      body: cardBody2,
      buttonText: cardButtonText2,
      buttonUrl: cardButtonUrl2
    },
    {
      image: '/mvt-images/art-direction/product-imagery-3.jpg',
      title: cardTitle3,
      body: cardBody3,
      buttonText: cardButtonText3,
      buttonUrl: cardButtonUrl3
    }
  ];

  return (
    <LayoutProvider paddingX>
      <SliceHeader data={{ heading }} />

      <TextColumns
        data={{
          heading: subheading,
          bodyLeft: body1,
          bodyCenter: body2,
          bodyRight: body3
        }}
        removeBorder
      />

      <ProductGrid data={{ items: productGridItems }} />
    </LayoutProvider>
  );
};

export default FigmaProductImagery;
