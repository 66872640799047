// extracted by mini-css-extract-plugin
export var active = "styles-module--active--0c600";
export var activeBackground = "styles-module--activeBackground--22b54";
export var chevron = "styles-module--chevron--d4e83";
export var container = "styles-module--container--923e2";
export var contentContainer = "styles-module--contentContainer--086c9";
export var contentWrapper = "styles-module--contentWrapper--ca78d";
export var desktop = "1340px";
export var expanded = "styles-module--expanded--6550b";
export var giant = "2200px";
export var header = "styles-module--header--fbe83";
export var headerContent = "styles-module--headerContent--68e36";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";