// extracted by mini-css-extract-plugin
export var active = "styles-module--active--cb208";
export var arrowIcon = "styles-module--arrowIcon--45dd7";
export var background = "styles-module--background--5f80d";
export var button = "styles-module--button--f5ca7";
export var content = "styles-module--content--c2ef1";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";