// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--f3a82";
export var appearDown = "styles-module--appear-down--8abf8";
export var appearLeft = "styles-module--appear-left--69b6c";
export var appearRight = "styles-module--appear-right--1f6ba";
export var appearUp = "styles-module--appear-up--44dba";
export var colors = "styles-module--colors--c3bd3";
export var container = "styles-module--container--0f24c";
export var content = "styles-module--content--22c35";
export var contentType = "styles-module--contentType--d9b4c";
export var createButton = "styles-module--createButton--c8b8b";
export var fadeGrowExtra = "styles-module--fade-grow-extra--f353d";
export var image = "styles-module--image--572e7";
export var images = "styles-module--images--77fb2";
export var textContainer = "styles-module--textContainer--1e3fb";
export var textItem = "styles-module--textItem--073c9";
export var typeTitle = "styles-module--typeTitle--60415";
export var video = "styles-module--video--22c59";
export var videos = "styles-module--videos--847e2";