// extracted by mini-css-extract-plugin
export var container = "styles-module--container--67d38";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--a2850";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var library = "styles-module--library--84b11";
export var librarySvg = "styles-module--librarySvg--037ac";
export var mobile = "400px";
export var preview = "styles-module--preview--d55fe";
export var previewSvg = "styles-module--previewSvg--06973";
export var selected = "styles-module--selected--89f82";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";