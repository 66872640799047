import React from 'react';
import {
  LayoutProvider,
  SliceHeader,
  TextColumns,
  TypeContainer,
  LogoFrames
} from '@lam-agency/toolkit/components';
import { LogoFrame } from '@lam-agency/toolkit/components';

interface IProps {
  data: {
    fields: {
      heading?: string;
      buttonText?: string;
      subheading?: string;
      body1?: string;
      body2?: string;
      body3?: string;
    };
  };
}

const delay = 100;

const FigmaWordmark = ({ data: { fields } }: IProps) => {
  const { heading, subheading, body1, body2, body3 } = fields || {};

  const typeContainers = [
    { scale: 100 },
    { scale: 80 },
    { scale: 60 },
    { scale: 40 },
    { scale: 20 },
    { scale: 10 }
  ];

  // todo: enable when figma plugin is hooked up
  // const buttons = parseButtonDataFromFigma([buttonText1, buttonText2]);
  // const buttons = [];

  return (
    <LayoutProvider paddingX>
      <SliceHeader data={{ heading }} />

      <LogoFrame data={{ svg: 'wordmark' }} />

      <TextColumns
        data={{
          heading: subheading,
          bodyLeft: body1,
          bodyCenter: body2,
          bodyRight: body3
        }}
        animationDelay={delay}
      />

      {typeContainers.map(({ scale }, index) => {
        return (
          <TypeContainer
            key={`figma-wordmark-type-${index}`}
            data={{ svg: 'wordmark', scale }}
          />
        );
      })}

      <LogoFrames data={{ svg: 'wordmark', category: 'secondary' }} />
    </LayoutProvider>
  );
};

export default FigmaWordmark;
