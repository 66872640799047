// extracted by mini-css-extract-plugin
export var active = "styles-module--active--2ed95";
export var alignmentButton = "styles-module--alignmentButton--8d6da";
export var alignmentButtons = "styles-module--alignmentButtons--32ac1";
export var alignmentSetting = "styles-module--alignmentSetting--5f55a";
export var button = "styles-module--button--937d9";
export var config = "styles-module--config--8d697";
export var desktop = "1340px";
export var em = "styles-module--em--1e57f";
export var error = "styles-module--error--62a52";
export var giant = "2200px";
export var header = "styles-module--header--a0f82";
export var heading = "styles-module--heading--1b263";
export var input = "styles-module--input--68c8d";
export var inputBackground = "styles-module--inputBackground--c859c";
export var inputError = "styles-module--inputError--e63a0";
export var inputWrapper = "styles-module--inputWrapper--66c7c";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var resetButton = "styles-module--resetButton--607a6";
export var resetButtonContainer = "styles-module--resetButtonContainer--53e89";
export var setting = "styles-module--setting--19453";
export var settings = "styles-module--settings--65e1f";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var visible = "styles-module--visible--36b21";