import React from 'react';
import {
  BentoSection,
  LayoutProvider,
  SliceHeader
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { ISliceBentoBox } from 'lam-toolkit-figma-plugin';

export const FigmaBentoBox = ({
  sliceHeader,
  bentoSections
}: ISliceBentoBox) => {
  return (
    <LayoutProvider paddingX paddingY>
      <SliceHeader {...sliceHeader} />

      <LayoutProvider grid>
        {bentoSections.map((bentoSection, i) => (
          <BentoSection key={i} {...bentoSection} className={styles.section} />
        ))}
      </LayoutProvider>
    </LayoutProvider>
  );
};
