import React from 'react';
import {
  LayoutProvider,
  SliceHeader,
  TextMediaColumns
} from '@lam-agency/toolkit/components';
import { ISliceTextMediaColumns } from 'lam-toolkit-figma-plugin';

export const FigmaTextMediaColumns = ({
  textMediaColumns,
  sliceHeader
}: ISliceTextMediaColumns) => {
  return (
    <LayoutProvider paddingX paddingY>
      <SliceHeader {...sliceHeader} />

      {textMediaColumns && <TextMediaColumns {...textMediaColumns} />}
    </LayoutProvider>
  );
};
