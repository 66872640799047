// extracted by mini-css-extract-plugin
export var check = "styles-module--check--12d99";
export var container = "styles-module--container--fa0b8";
export var dark = "styles-module--dark--a62c9";
export var desktop = "1340px";
export var dynamic = "styles-module--dynamic--1586a";
export var fixed = "styles-module--fixed--e8dd5";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var light = "styles-module--light--29dfc";
export var logoFrame = "styles-module--logoFrame--7e6bb";
export var mobile = "styles-module--mobile--6fc16";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var swatch = "styles-module--swatch--0c7cf";
export var swatches = "styles-module--swatches--aea91";
export var tablet = "769px";
export var visible = "styles-module--visible--be77f";