import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import useMagicPenContext from '../../context';
import ActionButtons from '../../components/ActionButtons';

const TemplateResults = () => {
  const [gptResponse, setGptResponse] = useState('');
  // const [isEditing, setIsEditing] = useState(false);

  const { constructedPrompt } = useMagicPenContext();

  const getResponse = async () => {
    setGptResponse('');

    const response = await fetch('/.netlify/functions/chat-gpt', {
      method: 'POST',
      body: JSON.stringify({ prompt: constructedPrompt })
    });

    const data = await response.text();
    setGptResponse(data);
  };

  useEffect(() => {
    getResponse();
  }, []);

  return (
    <div>
      <div className={styles.messageContainer}>
        <img src="images/user.png" className={styles.icon} />
        <div>
          <div className={cn('b2', styles.userLabel)}>You:</div>
          <p className={cn('b1', styles.messageContent)}>{constructedPrompt}</p>
        </div>
      </div>

      <div className={styles.messageContainer}>
        <img src="/images/magic-pen.png" className={styles.icon} />
        <div>
          <div className={cn('b2', styles.userLabel)}>Magic Pen:</div>
          <p className={cn('b1', styles.messageContent)}>
            {gptResponse || 'Thinking...'}
          </p>

          {gptResponse && (
            <ActionButtons
              activePostContent={gptResponse}
              getPostContent={getResponse}
              // setIsEditing={setIsEditing}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TemplateResults;
