import React from 'react';
import {
  SliceConfig,
  PortableTextRenderer,
  IntersectionAnimation
} from '@lam-agency/toolkit/components';
import { IToolkitSliceConfig } from '@lam-agency/sanity';
import * as styles from './styles.module.scss';
import { Button } from '@lam-agency/toolkit/components';

interface IProps {
  data: {
    links: {
      _type: 'linkInternal' | 'linkExternal';
      title: string;
      url?: string;
      reference?: {
        slug: {
          current: string;
        };
      };
    }[];
    _rawText: any;
    text: any;
    sliceConfig: IToolkitSliceConfig;
  };
}

const FeaturedText = ({
  data: { links, _rawText, sliceConfig, text }
}: IProps) => (
  <SliceConfig config={sliceConfig}>
    <div className={styles.content}>
      <IntersectionAnimation>
        <PortableTextRenderer rawText={_rawText || text} />
      </IntersectionAnimation>

      {links?.[0] && (
        <IntersectionAnimation delay={150}>
          <div className={styles.links}>
            {links?.map((link, i) => {
              if (link._type === `linkInternal`) {
                return (
                  <Button key={i} to={`/${link.reference?.slug.current}`}>
                    {link.title}
                  </Button>
                );
              }

              if (link._type === `linkExternal`) {
                return (
                  <Button key={i} to={link.url}>
                    {link.title}
                  </Button>
                );
              }

              return null;
            })}
          </div>
        </IntersectionAnimation>
      )}
    </div>
  </SliceConfig>
);

export default FeaturedText;
