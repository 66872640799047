import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { IColourTile } from 'lam-toolkit-figma-plugin';
import { CopyText } from '../../Common/CopyText';
import { getTextThemeByBackgroundHex } from '@lam-agency/toolkit/utils';

interface IProps extends IColourTile {
  className?: string;
  smallText?: boolean;
}

export const ColourTile = ({
  cymk,
  hex,
  pantone,
  ral,
  rgb,
  title,
  gradient,
  smallText,
  className
}: IProps) => {
  const textTheme = getTextThemeByBackgroundHex(hex);

  return (
    <article
      style={{ background: hex || gradient }}
      className={cn(styles.container, styles[`text${textTheme}`], className)}
    >
      <CopyText
        className={cn(styles.title, smallText ? 'caption' : 'b1')}
        copyType="title"
        text={title}
        element="h2"
        isColor
      />

      <div className={styles.bottomItems}>
        <CopyText copyType="hex" className="caption" text={hex} element="p" />
        <CopyText copyType="rgb" className="caption" text={rgb} element="p" />
        <CopyText copyType="cymk" className="caption" text={cymk} element="p" />
        <CopyText
          copyType="pantone"
          className="caption"
          text={pantone}
          element="p"
        />
        <CopyText copyType="ral" className="caption" text={ral} element="p" />
        <CopyText
          copyType="gradient"
          overrideDisplay="Copy gradient"
          className="caption"
          text={gradient}
          element="p"
        />
      </div>
    </article>
  );
};
