import React, { useState } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { useInView } from 'react-intersection-observer';
import ToggleButtons from './components/ToggleButtons';
import { IToolkitSliceConfig } from '@lam-agency/sanity';
import {
  IntersectionAnimation,
  SliceConfig
} from '@lam-agency/toolkit/components';

interface IProps {
  sliceConfig: IToolkitSliceConfig;
}

export type TCase = `uppercase` | `lowercase`;

const TypeGrid = ({ sliceConfig }: IProps) => {
  const [letterCase, setLetterCase] = useState<TCase>(`uppercase`);

  const characters = `abcdefghijklmnopqrstuvwxyz0123456789♡+$←→™`.split(``);

  const { ref, inView } = useInView({
    rootMargin: `-90px`
  });

  return (
    <SliceConfig config={sliceConfig}>
      <div className={styles.container}>
        <ToggleButtons
          className={styles.toggleButtons}
          letterCase={letterCase}
          setLetterCase={setLetterCase}
        />

        <div
          ref={ref}
          className={cn(styles.grid, {
            [styles.uppercase]: letterCase === `uppercase`
          })}
        >
          {characters.map((character, i) => (
            <IntersectionAnimation
              className={styles.gridItem}
              trigger={inView}
              delay={i * 50}
              animation="fadeGrow"
              key={i}
            >
              <div className={cn(`h1`, styles.gridItemContent)}>
                {character}
              </div>
            </IntersectionAnimation>
          ))}
        </div>
      </div>
    </SliceConfig>
  );
};

export default TypeGrid;
