// extracted by mini-css-extract-plugin
export var bentoBoxVariant1bentoBlock1 = "styles-module--bentoBoxVariant1bentoBlock1--53e1e";
export var bentoBoxVariant1bentoBlock2 = "styles-module--bentoBoxVariant1bentoBlock2--29178";
export var bentoBoxVariant1bentoBlock3 = "styles-module--bentoBoxVariant1bentoBlock3--ba5b6";
export var bentoBoxVariant1bentoBlock4 = "styles-module--bentoBoxVariant1bentoBlock4--d5be6";
export var bentoBoxVariant2bentoBlock1 = "styles-module--bentoBoxVariant2bentoBlock1--63fb8";
export var bentoBoxVariant2bentoBlock2 = "styles-module--bentoBoxVariant2bentoBlock2--b650c";
export var bentoBoxVariant3bentoBlock1 = "styles-module--bentoBoxVariant3bentoBlock1--6ac34";
export var bentoBoxVariant3bentoBlock2 = "styles-module--bentoBoxVariant3bentoBlock2--b9b3e";
export var bentoBoxVariant3bentoBlock3 = "styles-module--bentoBoxVariant3bentoBlock3--4c12f";
export var bentoBoxVariant3bentoBlock4 = "styles-module--bentoBoxVariant3bentoBlock4--1c497";
export var bentoBoxVariant4bentoBlock1 = "styles-module--bentoBoxVariant4bentoBlock1--1b996";
export var bentoBoxVariant4bentoBlock2 = "styles-module--bentoBoxVariant4bentoBlock2--137e6";
export var block = "styles-module--block--591f9";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--2e4ad";
export var heroBoxbentoBlock1 = "styles-module--heroBoxbentoBlock1--307f6";
export var heroBoxbentoBlock2 = "styles-module--heroBoxbentoBlock2--7ff2a";
export var heroBoxbentoBlock3 = "styles-module--heroBoxbentoBlock3--d28ab";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";