import React from 'react';
import cn from 'classnames';
import { useAccordion, useBreakpoints } from '@lam-agency/toolkit/hooks';

import * as styles from './styles.module.scss';
import { SVG } from '@lam-agency/toolkit/components';
import { Button } from '@lam-agency/toolkit/components';

export interface IAnchorLink {
  title: string;
}

interface IProps {
  data: {
    heading?: string;
    buttonLeft?: string;
    buttonRight?: string;
    footerHeading?: string;
    links: IAnchorLink[];
  };
  className?: string;
}

const AnchorLinks = ({ data, className }: IProps) => {
  const {
    heading = 'On this page',
    buttonLeft = '',
    buttonRight = '',
    links,
    footerHeading = 'Quick Links'
  } = data || {};
  const { containerHeight, contentRef, isExpanded, setIsExpanded } =
    useAccordion();

  const { smallTablet, smallDesktop } = useBreakpoints();

  const hasFooter = buttonLeft || buttonRight;

  const getLinkJsx = (link: IAnchorLink, index: number) => {
    return (
      <li
        key={`download-links-${link?.title}-${index}`}
        className={styles.link}
      >
        <figure className={styles.linkSvg}>
          <SVG svg="circle" />
        </figure>

        <a href={`#${link.id}`} className="b1">
          {link.title}
        </a>

        <span className={styles.linkEllipsis} />
      </li>
    );
  };

  const getFooterJsx = (
    footerHeading: string,
    buttonLeft: string,
    buttonRight: string
  ) => {
    return (
      <footer className={styles.footer}>
        <p className="caption">{footerHeading}</p>

        {(buttonLeft || buttonRight) && (
          <div className={styles.footerButtons}>
            {buttonLeft && (
              <Button iconRight="download" fluid>
                {buttonLeft}
              </Button>
            )}

            {buttonRight && (
              <Button iconRight="download" fluid>
                {buttonRight}
              </Button>
            )}
          </div>
        )}
      </footer>
    );
  };

  return (
    <aside className={cn(styles.container, className)}>
      {(smallDesktop && (
        <>
          <header
            className={styles.header}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <p className="caption">{heading}</p>
          </header>

          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              <ul className={styles.links}>
                {links.map((link, index: number) => {
                  return getLinkJsx(link, index);
                })}
              </ul>

              {hasFooter &&
                getFooterJsx(footerHeading, buttonLeft, buttonRight)}
            </div>
          </div>
        </>
      )) || (
        <>
          <header
            className={cn(styles.header, {
              [styles.expanded]: smallDesktop || isExpanded
            })}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <p className="b2">{heading}</p>

            <figure
              className={cn(styles.chevron, {
                [styles.expanded]: isExpanded
              })}
            >
              <SVG svg="chevronDown" />
            </figure>
          </header>

          <div
            className={styles.contentWrapper}
            style={{ height: containerHeight }}
          >
            <div ref={contentRef} className={styles.content}>
              <ul
                className={cn(styles.links, {
                  [styles.borderBottom]: hasFooter
                })}
              >
                {links.map((link, index: number) => {
                  return getLinkJsx(link, index);
                })}
              </ul>

              {hasFooter &&
                getFooterJsx(footerHeading, buttonLeft, buttonRight)}
            </div>
          </div>
        </>
      )}
    </aside>
  );
};

export default AnchorLinks;
