// extracted by mini-css-extract-plugin
export var buttonContent = "styles-module--buttonContent--ad3ab";
export var buttonContentContainer = "styles-module--buttonContentContainer--8f1b3";
export var container = "styles-module--container--f6b14";
export var desktop = "1340px";
export var giant = "2200px";
export var icon = "styles-module--icon--69663";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var optionButton = "styles-module--optionButton--b10e2";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var title = "styles-module--title--a79b8";