import React from 'react';
import cn from 'classnames';
import { SVG } from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';
import { Button } from '../..';

interface IProps {
  data: {
    svg: 'wordmark' | 'logomark';
  };
  className?: string;
}

const LogoFrame = ({ data, className }: IProps) => {
  const { svg } = data || {};
  // todo: placeholder buttons. replace with plugin data
  const buttons = [
    { text: 'Copy PNG', icon: 'copy' },
    { text: 'Copy SVG', icon: 'copy' }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        {buttons.map((button, index) => {
          const { text, icon } = button;

          return (
            <Button key={`logo-frame-button-${text}-${index}`} iconRight={icon}>
              <span>{text}</span>
            </Button>
          );
        })}
      </div>

      <figure className={cn(styles.svg, className)}>
        <SVG svg={svg} />
      </figure>
    </div>
  );
};

export default LogoFrame;
