import React from 'react';
import { Helmet } from 'react-helmet';

const Theme = () => {
  const fontFamilyCss = `
    @font-face {
      font-family: "ABC Diatype Light";
      src: url(/fonts/abcdiatype-light.woff) format("woff");
      font-display: block;
      font-weight: 300;
      font-style: normal;
    }
  `;

  return (
    <Helmet>
      <style>{fontFamilyCss}</style>
    </Helmet>
  );
};

export default Theme;
