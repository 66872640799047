import React from 'react';
import {
  LayoutProvider,
  ImageGrid,
  SliceHeader,
  TextImageGrid
} from '@lam-agency/toolkit/components';

interface IProps {
  data: {
    fields: {
      heading?: string;
      body?: string;
    };
  };
}

const imageGridItemsTop = [
  {
    image: '/mvt-images/art-direction/explanation-imagery-1.jpg'
  }
];

const imageGridItemsBottom = [
  {
    image: '/mvt-images/art-direction/explanation-imagery-4.jpg'
  },
  {
    image: '/mvt-images/art-direction/explanation-imagery-3.jpg'
  },
  {
    image: '/mvt-images/art-direction/explanation-imagery-2.jpg'
  }
];

const FigmaExplanationImagery = ({ data: { fields } }: IProps) => {
  const { heading, body } = fields || {};

  return (
    <LayoutProvider paddingX paddingY>
      <SliceHeader data={{ heading }} />

      <TextImageGrid
        body={body}
        bodyPosition="left"
        items={imageGridItemsTop}
      />

      <ImageGrid items={imageGridItemsBottom} />
    </LayoutProvider>
  );
};

export default FigmaExplanationImagery;
