import React from 'react';
import {
  ColourRow,
  LayoutProvider,
  SliceHeader,
  TextColumns
} from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';

interface IProps {
  data: {
    fields: {
      heading?: string;
      subheadingTop?: string;
      subheadingMiddle?: string;
      subheadingBottom?: string;
      body1?: string;
      body2?: string;
      body3?: string;
    };
  };
}

const FigmaGrid = ({ data: { fields } }: IProps) => {
  const {
    heading,
    subheadingTop,
    subheadingMiddle,
    subheadingBottom,
    body1,
    body2,
    body3
  } = fields || {};

  return (
    <LayoutProvider paddingX>
      <LayoutProvider grid>
        <SliceHeader className={styles.header} data={{ heading }} />
      </LayoutProvider>

      <ColourRow data={{ heading: subheadingTop, category: 'primary' }} />
      <ColourRow data={{ heading: subheadingMiddle, category: 'secondary' }} />
      <TextColumns
        data={{
          heading: subheadingBottom,
          bodyLeft: body1,
          bodyCenter: body2,
          bodyRight: body3
        }}
        removeBorder
      />
      {/* <ColourRow data={{ heading: subheadingBottom, category: 'mono' }} /> */}
    </LayoutProvider>
  );
};

export default FigmaGrid;
