// extracted by mini-css-extract-plugin
export var actionButtons = "styles-module--actionButtons--b78d0";
export var buttonIcon = "styles-module--buttonIcon--07e5d";
export var container = "styles-module--container--c8dc5";
export var content = "styles-module--content--c2b52";
export var desktop = "1340px";
export var editIcon = "styles-module--editIcon--ced17";
export var editMediaButton = "styles-module--editMediaButton--97a5a";
export var giant = "2200px";
export var handle = "styles-module--handle--9be53";
export var imagePlaceholder = "styles-module--imagePlaceholder--bd0c5";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var media = "styles-module--media--d9379";
export var mediaContainer = "styles-module--mediaContainer--00295";
export var mobile = "400px";
export var profilePic = "styles-module--profilePic--79475";
export var saveButton = "styles-module--saveButton--70ff0";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var textarea = "styles-module--textarea--d640c";
export var topContent = "styles-module--topContent--4f6fb";