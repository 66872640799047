import React from 'react';
import { Helmet } from 'react-helmet';
import { useMVT } from '@lam-agency/toolkit/stores';

interface IProps {
  pageTitle: string;
}

const SEO = ({ pageTitle }: IProps) => {
  const {
    projectString,
    seoData: { description, title }
  } = useMVT();

  const PROJECT_REF = process.env.GATSBY_SUPABASE_PROJECT_REF || '';

  const opengraphURL = `https://${PROJECT_REF}.supabase.co/storage/v1/object/public/images/${projectString}/_seo-images/open-graph.jpg?t=${new Date().getTime()}`;
  const faviconURL = `https://${PROJECT_REF}.supabase.co/storage/v1/object/public/images/${projectString}/_seo-images/favicon.png?t=${new Date().getTime()}`;

  const siteTitle = title || 'ToolKit™';
  const siteUrl = process.env.GATSBY_SITE_URL || '';
  const titleText =
    pageTitle === 'Home' ? siteTitle : `${pageTitle} | ${siteTitle}`;

  return (
    <Helmet>
      <title>{titleText}</title>
      <link rel="icon" type="image/png" href={faviconURL} />
      <meta name="description" content={description} />
      <link rel="canonical" href={siteUrl} />

      {/* facebook */}
      <meta property="og:site_name" content={pageTitle} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={opengraphURL} />
      <meta property="og:image:alt" content={pageTitle} />

      {/* twitter */}

      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={opengraphURL} />
      <meta name="twitter:image:alt" content={pageTitle} />
    </Helmet>
  );
};

export default SEO;
