// extracted by mini-css-extract-plugin
export var carouselButtons = "styles-module--carouselButtons--c66e1";
export var carouselContainer = "styles-module--carouselContainer--2b20c";
export var carouselImage = "styles-module--carouselImage--58eab";
export var carouselImageContainer = "styles-module--carouselImageContainer--c367a";
export var carouselRow = "styles-module--carouselRow--cd96f";
export var container = "styles-module--container--ee7e0";
export var content = "styles-module--content--06ebe";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var linkButton = "styles-module--linkButton--2ae03";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var title = "styles-module--title--a8ed6";