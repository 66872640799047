import React from 'react';
import {
  ColourRow,
  LayoutProvider,
  SliceHeader,
  TextColumns
} from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';
import { ISliceBlackAndWhite } from 'lam-toolkit-figma-plugin';

const FigmaBlackAndWhite = ({
  body1,
  body2,
  body3,
  heading,
  subheadingBottom,
  subheadingTop
}: ISliceBlackAndWhite) => {
  return (
    <LayoutProvider paddingX>
      <LayoutProvider grid>
        <SliceHeader className={styles.header} data={{ heading }} />
      </LayoutProvider>

      <ColourRow data={{ heading: subheadingTop, category: 'mono' }} />
      <TextColumns
        data={{
          heading: subheadingBottom,
          bodyLeft: body1,
          bodyCenter: body2,
          bodyRight: body3
        }}
        removeBorder
      />
    </LayoutProvider>
  );
};

export default FigmaBlackAndWhite;
