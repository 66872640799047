import React from 'react';
import CopyElementToPalette from '../CopyElementToPalette';
import ColorTile from '../ColorTile';
import * as styles from './styles.module.scss';
import { IPaletteColor } from '../../types';

interface IProps extends IPaletteColor {}

const PaletteColorTile = ({ hex, name, textColor = `dark`, id }: IProps) => {
  return (
    <CopyElementToPalette className={styles.container} type="color" id={id}>
      <ColorTile
        type="Color"
        hex={hex}
        id={id}
        name={name}
        textColor={textColor}
      />
    </CopyElementToPalette>
  );
};

export default PaletteColorTile;
