import React from 'react';
import {
  LayoutProvider,
  SliceHeader,
  TextImageGrid
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { ISliceBorderRadius } from 'lam-toolkit-figma-plugin';
import { useFigmaImage } from '@lam-agency/toolkit/hooks';

const FigmaBorderRadius = ({
  body,
  caption1,
  caption2,
  images,
  sliceHeader
}: ISliceBorderRadius) => {
  const imageData = useFigmaImage(images);

  const gridItems = [
    {
      image: imageData?.[0]?.image,
      button: {
        text: 'Copy CSS',
        url: '/',
        icon: 'copy'
      },
      caption: caption1,
      borderRadius: false
    },
    {
      image: imageData?.[1]?.image,
      button: {
        text: 'Copy CSS',
        url: '/',
        icon: 'copy'
      },
      caption: caption2,
      borderRadius: false
    }
  ];

  return (
    <LayoutProvider paddingX paddingY>
      <LayoutProvider grid>
        <SliceHeader className={styles.header} {...sliceHeader} />
      </LayoutProvider>

      <TextImageGrid body={body} bodyPosition="left" items={gridItems} />
    </LayoutProvider>
  );
};

export default FigmaBorderRadius;
