const basicText = /^[\s]*[\S]+[\s\S]*$/; // At least 1 non-whitespace character, leading + trailing whitespace is allowed
const email = /^\s*\S+[@]\S+[.]\S+\s*$/; // <love>@<money>.<beta>, leading + trailing whitespace is allowed
const hexRegex = /^#[A-Fa-f0-9]{6}$/;

export const regex = {
  name: basicText,
  email,
  enquiry: basicText,
  message: basicText,
  hex: hexRegex
};

export const HOME_PAGE_NAME = 'Home';

export const glyphCharacters: string[] = [
  'Aa',
  'Bb',
  'Cc',
  'Dd',
  'Ee',
  'Ff',
  'Gg',
  'Hh',
  'Ii',
  'Jj',
  'Kk',
  'Ll',
  'Mm',
  'Nn',
  'Oo',
  'Pp',
  'Qq',
  'Rr',
  'Ss',
  'Tt',
  'Uu',
  'Vv',
  'Xx',
  'Yy',
  'Zz',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '!',
  '#',
  '$',
  '%',
  '&',
  '+'
];
