import React, { useState } from 'react';
import {
  IntersectionAnimation,
  LayoutProvider,
  SegmentedControl,
  SliceHeader,
  WebStyleRow
} from '@lam-agency/toolkit/components';
import { useMVT } from '@lam-agency/toolkit/stores';

import * as styles from './styles.module.scss';

interface IProps {
  data: {
    fields: {
      heading: string;
      body1: string;
      body2: string;
      body3: string;
      body4: string;
      body5: string;
      body6: string;
      body7: string;
      body8: string;
      body9: string;
    };
  };
}

const FigmaWebStyles = ({
  data: {
    fields: {
      heading,
      body1,
      body2,
      body3,
      body4,
      body5,
      body6,
      body7,
      body8,
      body9
    }
  }
}: IProps) => {
  const { typestyles } = useMVT();

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const controlTabs = [
    {
      key: 'Desktop',
      value: 'lg'
    },
    {
      key: 'Tablet',
      value: 'md'
    },
    {
      key: 'Mobile',
      value: 'sm'
    }
  ];

  return (
    <div>
      <LayoutProvider grid paddingX>
        <SliceHeader className={styles.header} data={{ heading }} />

        <div className={styles.controlWrapper}>
          <SegmentedControl
            tabs={controlTabs}
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
          />
        </div>

        <div className={styles.webstyles}>
          {typestyles.map((typestyle, index) => {
            // use index to access body1, body2, body3, etc;
            let displayText = '';

            switch (index) {
              case 0:
                displayText = body1;
                break;
              case 1:
                displayText = body2;
                break;
              case 2:
                displayText = body3;
                break;
              case 3:
                displayText = body4;
                break;
              case 4:
                displayText = body5;
                break;
              case 5:
                displayText = body6;
                break;
              case 6:
                displayText = body7;
                break;
              case 7:
                displayText = body8;
                break;
              case 8:
                displayText = body9;
                break;
              default:
                break;
            }

            return (
              <IntersectionAnimation
                key={`web-style-row-${typestyle?.id}`}
                className={styles.webstyle}
              >
                <WebStyleRow
                  data={typestyle}
                  displayText={displayText}
                  activeBreakpoint={controlTabs[selectedTabIndex].value}
                  borderBottom={index < typestyles.length - 1}
                />
              </IntersectionAnimation>
            );
          })}
        </div>
      </LayoutProvider>
    </div>
  );
};

export default FigmaWebStyles;
