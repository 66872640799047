import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  isLoading: boolean;
}

const LoadingOverlay = ({ isLoading }: IProps) => {
  return (
    <div
      aria-hidden={!isLoading}
      className={cn(styles.loadingContent, { [styles.visible]: isLoading })}
    >
      <div className={styles.spinner} />
    </div>
  );
};

export default LoadingOverlay;
