import React, { useState } from 'react';
import cn from 'classnames';
import Toolbar from './components/Toolbar';

import * as styles from './styles.module.scss';
import { useBreakpoints } from '@lam-agency/toolkit/hooks';
import LayoutProvider from '../../Common/LayoutProvider';
import ResizeTextArea from '../../Common/ResizeTextArea';

export interface ITypeEditorDefaultSettings {
  text: string;
  letterSpacing: number;
  lineHeight: number;
  alignment: 'left' | 'center';
}

interface IProps {
  typestyle: 'Display' | 'Heading' | 'Body';
  placeholder: string;
  noBorder?: boolean;
  defaultSettings: ITypeEditorDefaultSettings;
}

const TypeEditor = ({
  typestyle,
  placeholder,
  defaultSettings,
  noBorder = false
}: IProps) => {
  const { tablet, smallDesktop } = useBreakpoints();

  const [letterSpacing, setLetterSpacing] = useState(
    defaultSettings.letterSpacing
  );
  const [lineHeight, setLineHeight] = useState(defaultSettings.lineHeight);
  const [alignment, setAlignment] = useState(defaultSettings.alignment);
  const [text, setText] = useState(defaultSettings.text);

  const typestyles = {
    Display: {
      class: 'd1'
    },
    Heading: {
      class: 'h1'
    },
    Body: {
      class: 'b2'
    }
  };

  const resetTypography = () => {
    setLineHeight(defaultSettings.lineHeight);
    setLetterSpacing(defaultSettings.letterSpacing);
    setAlignment(defaultSettings.alignment);
    setText(defaultSettings.text);
  };

  const textStyle: React.CSSProperties = {
    letterSpacing: `${letterSpacing}em`,
    lineHeight: `${lineHeight}%`,
    textAlign: alignment
  };

  const rerenderKey = `${letterSpacing}-${lineHeight}-${alignment}`;

  return (
    <div className={cn(styles.container)}>
      <h2 className={cn('b1', styles.mobileTitle)}>{typestyle}</h2>

      <Toolbar
        typestyle={typestyle}
        letterSpacing={letterSpacing}
        setLetterSpacing={setLetterSpacing}
        lineHeight={lineHeight}
        setLineHeight={setLineHeight}
        alignment={alignment}
        setAlignment={setAlignment}
        handleOnReset={resetTypography}
      />

      <LayoutProvider grid>
        <ResizeTextArea
          className={cn(
            typestyles[typestyle].class,
            styles.textarea,
            styles[typestyle]
          )}
          setText={setText}
          text={text}
          style={textStyle}
          key={`${rerenderKey}-${1}`}
        />

        {typestyle === 'Body' && tablet && (
          <ResizeTextArea
            className={cn(
              typestyles[typestyle].class,
              styles.textarea,
              styles[typestyle]
            )}
            setText={setText}
            text={text}
            style={textStyle}
            key={`${rerenderKey}-${2}`}
          />
        )}

        {typestyle === 'Body' && smallDesktop && (
          <>
            <ResizeTextArea
              className={cn(
                typestyles[typestyle].class,
                styles.textarea,
                styles[typestyle]
              )}
              setText={setText}
              text={text}
              style={textStyle}
              key={`${rerenderKey}-${3}`}
            />
            <ResizeTextArea
              className={cn(
                typestyles[typestyle].class,
                styles.textarea,
                styles[typestyle]
              )}
              setText={setText}
              text={text}
              style={textStyle}
              key={`${rerenderKey}-${4}`}
            />
          </>
        )}
      </LayoutProvider>

      {!noBorder && <div className={styles.partition} />}
    </div>
  );
};

export default TypeEditor;
