import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import {
  ISlidePillButtonOption,
  LayoutProvider,
  PreviewGridButton,
  SliceHeader,
  SlidePillButtons
} from '@lam-agency/toolkit/components';
import { ISliceGlyphSet } from 'lam-toolkit-figma-plugin';
import { glyphCharacters } from '@lam-agency/toolkit/constants';
import { useMVT } from '@lam-agency/toolkit/stores';
import { getFontWeightFromStyle } from '@lam-agency/toolkit/utils';

export const FigmaGlyphSet = ({ sliceHeader }: ISliceGlyphSet) => {
  const [activeGlyph, setActiveGlyph] = useState(glyphCharacters[0]);
  const [pillButtons, setPillButtons] = useState<ISlidePillButtonOption[]>([]);

  const { fonts } = useMVT();

  useEffect(() => {
    const buttonOptions: ISlidePillButtonOption[] = fonts.map((font, i) => ({
      active: i === 0,
      label: `${font.family} ${font.style}`,
      value: `${font.family}:${font.style}`
    }));

    setPillButtons(buttonOptions);
  }, [fonts]);

  const activePillButton = pillButtons.find((button) => button.active);
  const activeFontFamily = activePillButton?.value.split(':')[0] || '';
  const activeFontWeight = activePillButton?.value.split(':')[1] || '';

  return (
    <LayoutProvider paddingX paddingY>
      <SliceHeader {...sliceHeader} />

      <div className={cn('caption', styles.pillButtonsContainer)}>
        <SlidePillButtons options={pillButtons} setOptions={setPillButtons} />
      </div>

      <LayoutProvider grid className={styles.grid}>
        <div className={styles.options}>
          <div
            className={styles.optionsGrid}
            style={{
              fontFamily: activeFontFamily,
              fontWeight: getFontWeightFromStyle(activeFontWeight)
            }}
          >
            {glyphCharacters.map((glyph, i) => (
              <PreviewGridButton
                key={i}
                active={activeGlyph === glyph}
                onClick={() => setActiveGlyph(glyph)}
              >
                {glyph}
              </PreviewGridButton>
            ))}
          </div>
        </div>

        <div className={styles.inspector}>
          <div
            className={styles.inspectorContent}
            style={{
              fontFamily: activeFontFamily,
              fontWeight: getFontWeightFromStyle(activeFontWeight)
            }}
          >
            {activeGlyph}
          </div>
        </div>
      </LayoutProvider>
    </LayoutProvider>
  );
};
