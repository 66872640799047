import React from "react";
import Instagram from "../Instagram";
import LinkedIn from "../LinkedIn";
import cn from "classnames";
import { IProps as IActionButtonsProps } from "../ActionButtons";

interface IProps extends IActionButtonsProps {
  platform: string;
  isEditing: boolean;
  handleUpdateActivePostContent: (updatedContent: string) => void;
  className?: string;
}

const PostPreview = ({
  platform,
  activePostContent,
  getPostContent,
  setIsEditing,
  isEditing,
  handleUpdateActivePostContent,
  className
}: IProps) => {
  const getPreview = () => {
    switch (platform) {
      case "Instagram":
        return (
          <Instagram
            content={activePostContent}
            getPostContent={getPostContent}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
            handleUpdateActivePostContent={handleUpdateActivePostContent}
          />
        );

      case "LinkedIn":
        return (
          <LinkedIn
            content={activePostContent}
            getPostContent={getPostContent}
            setIsEditing={setIsEditing}
            isEditing={isEditing}
            handleUpdateActivePostContent={handleUpdateActivePostContent}
          />
        );

      default:
        return null;
    }
  };
  return <div className={cn(className)}>{getPreview()}</div>;
};

export default PostPreview;
