import React, { useEffect, useRef, useState } from 'react';
import MediaWithText from '../MediaWithText';
import { SVG } from '@lam-agency/toolkit/components';
import { IMediaTemplate } from '../MediaGallery';
// @ts-ignore
import domToImage from 'dom-to-image-more';
import * as styles from './styles.module.scss';

interface IProps {
  selectedMedia: IMediaTemplate;
  setShowMediaLibrary: React.Dispatch<React.SetStateAction<boolean>>;
}

const ImageUI = ({ selectedMedia, setShowMediaLibrary }: IProps) => {
  const [mediaContainerElement, setMediaContainerElement] =
    useState<HTMLDivElement>();

  const mediaContainerRef = useRef<HTMLDivElement>(null);

  const downloadDomNodeAsImage = () => {
    if (!mediaContainerElement) return;

    const mediaTextContainerDomNode = mediaContainerElement.querySelector(
      '#media-text-container'
    ) as HTMLDivElement;
    if (!mediaTextContainerDomNode) return;

    domToImage
      .toPng(mediaTextContainerDomNode)
      .then(function (dataUrl: any) {
        var img = new Image();
        img.src = dataUrl;
        const link = document.createElement('a');
        link.href = img.src;
        link.download = 'eup-social-image.png';
        link.click();
      })
      .catch(function (error: any) {
        console.error('oops, something went wrong!', error);
      });
  };

  useEffect(() => {
    if (mediaContainerRef.current) {
      setMediaContainerElement(mediaContainerRef.current);
    }
  }, [selectedMedia]);

  const textColor =
    selectedMedia?.textColor === 'light'
      ? 'var(--color-black-20)'
      : 'var(--color-black-80)';

  return (
    <div ref={mediaContainerRef} className={styles.mediaContainer}>
      <MediaWithText template={selectedMedia} />

      <div className={styles.buttons}>
        <button
          onClick={() => setShowMediaLibrary(true)}
          style={{ color: textColor }}
          className={styles.editMediaButton}
        >
          <SVG svg="edit" className={styles.editIcon} />
        </button>

        <button
          onClick={downloadDomNodeAsImage}
          style={{ color: textColor }}
          className={styles.editMediaButton}
        >
          <SVG svg="download" className={styles.editIcon} />
        </button>
      </div>
    </div>
  );
};

export default ImageUI;
