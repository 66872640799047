// extracted by mini-css-extract-plugin
export var circle = "styles-module--circle--76d06";
export var circle1 = "styles-module--circle1--6d4dd";
export var circle2 = "styles-module--circle2--88a62";
export var circle3 = "styles-module--circle3--23b49";
export var circle4 = "styles-module--circle4--5d40a";
export var circle5 = "styles-module--circle5--42171";
export var container = "styles-module--container--6b1fd";
export var content = "styles-module--content--12363";
export var desktop = "1340px";
export var fadeIn = "styles-module--fade-in--0c3b7";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var logo = "styles-module--logo--6ea66";
export var logoContainer = "styles-module--logoContainer--8ca57";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var spin = "styles-module--spin--a2890";
export var tablet = "769px";