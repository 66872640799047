import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import { IPaletteColor } from "../../types";

const ColorTile = ({ hex, name, textColor = `dark` }: IPaletteColor) => {
  return (
    <div
      className={cn(styles.container, {
        [styles.darkText]: textColor === `dark`,
        [styles.lightText]: textColor === `light`
      })}
      style={{
        backgroundColor: hex,
        border: `1px solid ${hex === "#1a1a1a" ? "hex" : "#1a1a1a"}`
      }}
    >
      <div className={styles.name}>{name}</div>
      <div>{hex}</div>
    </div>
  );
};

export default ColorTile;
