// extracted by mini-css-extract-plugin
export var container = "styles-module--container--bdb61";
export var content = "styles-module--content--56f46";
export var desktop = "1340px";
export var giant = "2200px";
export var gridItem = "styles-module--gridItem--69301";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var oneItem = "styles-module--oneItem--de5b7";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var threeItems = "styles-module--threeItems--97c12";
export var twoItems = "styles-module--twoItems--91ac3";