import React from 'react';
import {
  LayoutProvider,
  SliceHeader,
  TextColumns,
  TextImageGrid
} from '@lam-agency/toolkit/components';
import { ImageGrid } from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';

interface IProps {
  data: {
    fields: {
      heading: string;
      // images: string[];
      body1: string;
      subheading: string;
      bodyLeft: string;
      bodyCenter: string;
      bodyRight: string;
    };
  };
}

const FigmaIllustrations = ({ data: { fields } }: IProps) => {
  const { heading, body1, subheading, bodyLeft, bodyCenter, bodyRight } =
    fields || {};

  const imageGridItemsTop = [
    {
      image: '/mvt-images/illustrations-and-icons/illustrations-1.jpg',
      caption: 'Caption goes here'
    },
    {
      image: '/mvt-images/illustrations-and-icons/illustrations-2.jpg',
      caption: 'Caption goes here'
    },
    {
      image: '/mvt-images/illustrations-and-icons/illustrations-3.jpg',
      caption: 'Caption goes here'
    }
  ];

  const imageGridItemsMiddle = [
    {
      image: '/mvt-images/illustrations-and-icons/illustrations-3.jpg',
      borderRadius: true
    }
  ];

  const imageGridItemsBottom = [
    {
      image: '/mvt-images/illustrations-and-icons/illustrations-4.jpg',
      caption: 'Caption goes here'
    },
    {
      image: '/mvt-images/illustrations-and-icons/illustrations-5.jpg',
      caption: 'Caption goes here'
    },
    {
      image: '/mvt-images/illustrations-and-icons/illustrations-6.jpg',
      caption: 'Caption goes here'
    }
  ];

  return (
    <LayoutProvider paddingX paddingY>
      <LayoutProvider grid>
        <SliceHeader className={styles.header} data={{ heading }} />
      </LayoutProvider>

      {imageGridItemsTop && (
        <ImageGrid className={styles.imageGrid} items={imageGridItemsTop} />
      )}

      <TextImageGrid
        body={body1}
        bodyPosition="right"
        items={imageGridItemsMiddle}
      />

      {imageGridItemsBottom && (
        <ImageGrid className={styles.imageGrid} items={imageGridItemsBottom} />
      )}

      <TextColumns
        data={{ heading: subheading, bodyLeft, bodyCenter, bodyRight }}
      />
    </LayoutProvider>
  );
};

export default FigmaIllustrations;
