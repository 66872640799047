import React from 'react';
import { MediaItem } from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { ISliceFullWidthMedia } from 'lam-toolkit-figma-plugin';

export const FigmaFullWidthMedia = ({ mediaItems }: ISliceFullWidthMedia) => {
  return (
    <div className={styles.container}>
      <MediaItem noBorder aspectRatio="landscapeShorter" {...mediaItems[0]} />
    </div>
  );
};
