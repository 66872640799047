import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import SliceConfig from '../SliceConfig';
import { Button } from '@lam-agency/toolkit/components';
import { WebComponent } from '@lam-agency/toolkit/components';
import { Link } from 'gatsby';
import { webComponents } from '@lam-agency/toolkit/data/webComponents';

interface IProps {
  slug: string;
  title: string;
}

const WebComponentPreview = ({ slug, title }: IProps) => {
  return (
    <SliceConfig>
      <h1 className={cn('h1', styles.title)}>Web Components</h1>
      <div className={styles.columns}>
        <div>
          {webComponents.map((button, i) => (
            <div className={styles.buttonContainer} key={i}>
              <Button
                active={button.slug === slug}
                to={`/web-components/${button.slug}`}
              >
                {button.title}
              </Button>
            </div>
          ))}
        </div>

        {(!slug || !title) && (
          <div className={styles.linkTiles}>
            {webComponents.map(({ title, slug }, i) => (
              <Link
                to={`/web-components/${slug}`}
                className={styles.linkTile}
                key={i}
              >
                <div className={cn('h2', styles.linkTileContent)}>{title}</div>
              </Link>
            ))}
          </div>
        )}

        <WebComponent component={slug} />
      </div>
    </SliceConfig>
  );
};

export default WebComponentPreview;
