import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";
import { usePaletteContext } from "../../context";
import SelectedColor from "./components/SelectedColor";
import SelectedImage from "./components/SelectedImage";
import SelectedLogo from "./components/SelectedLogo";
import SelectedVideo from "./components/SelectedVideo";
import SelectedText from "./components/SelectedText";

const Palette = () => {
  const { paletteContent, setIsCreateModalOpen } = usePaletteContext();

  const { colors, images, logos, text, videos } = paletteContent;

  const paletteHasContent =
    text?.[0] || colors?.[0] || images?.[0] || logos?.[0] || videos?.[0];

  const handleOpenCreateModal = () => {
    setIsCreateModalOpen(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {logos?.[0] && (
          <div className={styles.contentType}>
            <h3 className={styles.typeTitle}>Logos</h3>

            <div className={cn(styles.images)}>
              {logos.map((logo) => (
                <SelectedLogo url={logo.url} key={logo.id} id={logo.id} />
              ))}
            </div>
          </div>
        )}

        {colors?.[0] && (
          <div className={styles.contentType}>
            <h3 className={styles.typeTitle}>Colors</h3>

            <div className={cn(styles.colors)}>
              {colors.map(({ hex, id }) => (
                <SelectedColor hex={hex} key={id} id={id} />
              ))}
            </div>
          </div>
        )}

        {text?.[0] && (
          <div className={styles.contentType}>
            <h3 className={styles.typeTitle}>Text</h3>

            <div className={styles.textContainer}>
              {text.map((text) => (
                <SelectedText
                  style={text.style}
                  text={text.children}
                  key={text.id}
                  id={text.id}
                />
              ))}
            </div>
          </div>
        )}

        {images?.[0] && (
          <div className={styles.contentType}>
            <h3 className={styles.typeTitle}>Images</h3>

            <div className={cn(styles.images)}>
              {images.map((image) => (
                <SelectedImage url={image.url} key={image.id} id={image.id} />
              ))}
            </div>
          </div>
        )}

        {videos?.[0] && (
          <div className={styles.contentType}>
            <h3 className={styles.typeTitle}>Video</h3>

            <div className={cn(styles.videos)}>
              {videos.map((video) => (
                <SelectedVideo url={video.url} key={video.id} id={video.id} />
              ))}
            </div>
          </div>
        )}

        {paletteHasContent && (
          <button
            onClick={handleOpenCreateModal}
            className={styles.createButton}
          >
            Create
          </button>
        )}
      </div>
    </div>
  );
};

export default Palette;
