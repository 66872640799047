import React from 'react';
import {
  LayoutProvider,
  SliceHeader,
  TextImageGrid
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { ISliceImageArticle } from 'lam-toolkit-figma-plugin';
import { useFigmaImage } from '@lam-agency/toolkit/hooks';

const FigmaImageArticle = ({
  body,
  images,
  sliceHeader
}: ISliceImageArticle) => {
  const imageData = useFigmaImage(images);

  return (
    <LayoutProvider paddingX paddingY>
      <LayoutProvider grid>
        <SliceHeader className={styles.header} {...sliceHeader} />
      </LayoutProvider>

      <TextImageGrid body={body} bodyPosition="right" items={imageData} />
    </LayoutProvider>
  );
};

export default FigmaImageArticle;
