import React from 'react';
import {
  IntersectionAnimation,
  PortableTextRenderer,
  SliceConfig
} from '@lam-agency/toolkit/components';
import { IToolkitTypePreview } from '@lam-agency/sanity';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { useInView } from 'react-intersection-observer';

interface IProps {
  data: IToolkitTypePreview;
}

const TypePreview = ({
  data: { _rawContent, properties, style, sliceConfig }
}: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  return (
    <SliceConfig config={sliceConfig}>
      <div className={styles.grid} ref={ref}>
        <IntersectionAnimation trigger={inView}>
          <h4 className={cn(`b1`, styles.styleTitle)}>{style}</h4>
        </IntersectionAnimation>
        <IntersectionAnimation
          className={styles.contentContainer}
          trigger={inView}
          delay={300}
        >
          <PortableTextRenderer
            rawText={_rawContent}
            className={styles.content}
          />
        </IntersectionAnimation>
        <IntersectionAnimation className={cn(`subheading`, styles.properties)}>
          {properties}
        </IntersectionAnimation>
      </div>
    </SliceConfig>
  );
};

export default TypePreview;
