import React from 'react';
import * as styles from './styles.module.scss';
import { IToolkitVideoAtf } from '@lam-agency/sanity';

interface IProps {
  data: IToolkitVideoAtf;
}

const FeaturedText = ({ data: { url } }: IProps) => (
  <div className={styles.container}>
    <video playsInline muted loop autoPlay src={url} />
  </div>
);

export default FeaturedText;
