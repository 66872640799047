import React from 'react';
import {
  LayoutProvider,
  SliceHeader,
  TwoColumnText
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { ISliceTwoColumnText } from 'lam-toolkit-figma-plugin';
import { getTextThemeByBackgroundHex } from '@lam-agency/toolkit/utils';

export const Figma2ColumnText = ({
  sliceHeader,
  twoColumnText,
  backgroundColorHex
}: ISliceTwoColumnText) => {
  const getTextColor = () => {
    if (!backgroundColorHex) {
      return '';
    }

    const textTheme = getTextThemeByBackgroundHex(backgroundColorHex);

    return textTheme === 'dark' ? 'var(--color-black)' : 'var(--color-white)';
  };

  return (
    <div
      style={{ background: backgroundColorHex, color: getTextColor() }}
      className={styles.container}
    >
      <div className={styles.content}>
        <LayoutProvider paddingX paddingY>
          {sliceHeader && <SliceHeader {...sliceHeader} />}

          {twoColumnText && <TwoColumnText {...twoColumnText} />}
        </LayoutProvider>
      </div>
    </div>
  );
};
