import React from 'react';
import { useApp } from '@lam-agency/toolkit/hooks';
import * as styles from './styles.module.scss';
import {
  EditableTextField,
  IEditableTextField
} from '@lam-agency/toolkit/components';
import { SVG } from '@lam-agency/toolkit/components';

interface IProps extends IEditableTextField {}

const CopyValue = ({
  value,
  isEditing,
  onChange,
  error,
  isResetEnabled,
  onReset
}: IProps) => {
  const { updateNotification } = useApp();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(value);
    updateNotification(`Copied to clipboard!`);
  };

  return (
    <div className={styles.container}>
      <button className={styles.button} type="button" onClick={copyToClipboard}>
        <SVG svg="copy" className={styles.icon} />
      </button>

      <EditableTextField
        error={error}
        isEditing={isEditing}
        value={value}
        onChange={onChange}
        isResetEnabled={isResetEnabled}
        onReset={onReset}
      />
    </div>
  );
};

export default CopyValue;
