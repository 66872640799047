// extracted by mini-css-extract-plugin
export var colours = "styles-module--colours--c1c7b";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--18a28";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var logos = "styles-module--logos--ef474";
export var mobile = "400px";
export var sliceHeader = "styles-module--sliceHeader--e4cb5";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";