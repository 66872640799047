// extracted by mini-css-extract-plugin
export var copyDownloadButtons = "styles-module--copyDownloadButtons--d7b95";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--731a4";
export var iconButton = "styles-module--iconButton--4cc2e";
export var iconPreview = "styles-module--iconPreview--93163";
export var inspector = "styles-module--inspector--f925b";
export var inspectorColumn = "styles-module--inspectorColumn--b4e3d";
export var inspectorContent = "styles-module--inspectorContent--d5bab";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var options = "styles-module--options--0695c";
export var optionsGrid = "styles-module--optionsGrid--7f276";
export var pillButtonsContainer = "styles-module--pillButtonsContainer--4110a";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";