import React from "react";
import * as styles from "./styles.module.scss";
import cn from "classnames";

interface IProps {
  size?: number;
  thickness?: number;
  className?: string;
}

const LoadingSpinner = ({ size = 32, thickness = 8, className }: IProps) => {
  return (
    <div
      className={cn(styles.spinner, className)}
      style={{
        width: size,
        height: size,
        borderWidth: `${thickness}px`
      }}
    />
  );
};

export default LoadingSpinner;
