import React from 'react';
import {
  LayoutProvider,
  SliceHeader,
  TextColumns,
  ImageGrid,
  IImageWithCaption
} from '@lam-agency/toolkit/components';

interface IProps {
  data: {
    fields: {
      heading?: string;
      subheading?: string;
      body: string;
    };
  };
}

const FigmaLogomark = ({ data: { fields } }: IProps) => {
  const { heading, subheading, body } = fields || {};

  const delay = 100;

  const imageGridItems: IImageWithCaption[] = [
    {
      image: '/mvt-images/logo/logo-misuse-1.jpg',
      caption: 'Don’t adjust the logo.',
      captionIcon: 'cross',
      captionIconColor: '#F75757',
      borderRadius: true
    },
    {
      image: '/mvt-images/logo/logo-misuse-2.jpg',
      caption: 'Don’t place the logo on an angle.',
      captionIcon: 'cross',
      captionIconColor: '#F75757',
      borderRadius: true
    },
    {
      image: '/mvt-images/logo/logo-misuse-3.jpg',
      caption: 'Don’t add effects to the logo.',
      captionIcon: 'cross',
      captionIconColor: '#F75757',
      borderRadius: true
    },
    {
      image: '/mvt-images/logo/logo-misuse-4.jpg',
      caption: 'Don’t use the logo as a keyline.',
      captionIcon: 'cross',
      captionIconColor: '#F75757',
      borderRadius: true
    }
  ];

  return (
    <LayoutProvider paddingX>
      <SliceHeader data={{ heading }} />

      <TextColumns
        data={{
          heading: subheading,
          bodyLeft: body
        }}
        animationDelay={delay}
      />

      <ImageGrid items={imageGridItems} columns={4} />
    </LayoutProvider>
  );
};

export default FigmaLogomark;
