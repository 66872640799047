import React, { useState } from 'react';
import * as styles from './styles.module.scss';
import { SliceConfig } from '@lam-agency/toolkit/components';
import cn from 'classnames';
import TypestyleRow from './components/TypestyleRow';
import { IToolkitTypestyles } from '@lam-agency/sanity';
import { Button } from '@lam-agency/toolkit/components';
import { useMVT } from '@lam-agency/toolkit/stores';

interface IProps {
  data: IToolkitTypestyles;
}

export type TActiveBreakpoint = `desktop` | `tablet` | `mobile`;

const Typestyles = ({ data: { sliceConfig } }: IProps) => {
  const [activeBreakpoint, setActiveBreakpoint] =
    useState<TActiveBreakpoint>(`mobile`);

  const { typestyles } = useMVT();

  return (
    <SliceConfig config={sliceConfig}>
      <h2 className={cn(`h1`, styles.title)}>Typography</h2>
      <div className={styles.grid}>
        <div className={styles.breakpointButtons}>
          <Button
            variant={activeBreakpoint === `mobile` ? `primary` : `secondary`}
            onClick={() => setActiveBreakpoint(`mobile`)}
          >
            Mobile
          </Button>

          <Button
            variant={activeBreakpoint === `tablet` ? `primary` : `secondary`}
            onClick={() => setActiveBreakpoint(`tablet`)}
          >
            Tablet
          </Button>
          <Button
            variant={activeBreakpoint === `desktop` ? `primary` : `secondary`}
            onClick={() => setActiveBreakpoint(`desktop`)}
          >
            Desktop
          </Button>
        </div>

        <div className={styles.columnHeadings}>
          <span className={styles.columnHeading}>Font-size</span>
          <span className={styles.columnHeading}>Line-height</span>
          <span className={styles.columnHeading}>Letter-spacing</span>
        </div>
      </div>

      {typestyles.map((typestyle) => (
        <TypestyleRow
          key={typestyle.id}
          activeBreakpoint={activeBreakpoint}
          typestyle={typestyle}
          className={styles.typestyleRow}
        />
      ))}
    </SliceConfig>
  );
};

export default Typestyles;
