import React from 'react';
import CopyElementToPalette from '../CopyElementToPalette';
import * as styles from './styles.module.scss';
import { IPaletteText } from '../../types';

const PaletteText = ({ style, children, id }: IPaletteText) => {
  return (
    <div className={styles.textContainer}>
      <CopyElementToPalette type="text" id={id}>
        <div className={style}>{children}</div>
      </CopyElementToPalette>
    </div>
  );
};

export default PaletteText;
