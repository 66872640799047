import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import useMagicPenContext from '../../context';
import { Button, TextInput } from '@lam-agency/toolkit/components';
import MediaWithText from '../MediaWithText';
import { SVG } from '@lam-agency/toolkit/components';

interface ITextLayout {
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;
  width?: string;
  height?: string;
}

export interface IMediaTemplate {
  image: string;
  textLayout: ITextLayout;
  textColor: 'light' | 'dark';
}

const mediaTemplates: IMediaTemplate[] = [
  {
    image: '/images/magic-pen/eup-img-1.jpg',
    textLayout: {
      top: '67%',
      left: '25%',
      width: '72%'
    },
    textColor: 'dark'
  },
  {
    image: '/images/magic-pen/eup-img-2.jpg',
    textLayout: {
      top: '67%',
      left: '17%',
      width: '73%'
    },
    textColor: 'dark'
  },
  {
    image: '/images/magic-pen/eup-img-3.jpg',
    textLayout: {
      top: '67%',
      left: '25%',
      width: '72%'
    },
    textColor: 'light'
  }
];

interface IProps {}

const MediaGallery = (props: IProps) => {
  const {
    setShowMediaLibrary,
    setSelectedMedia,
    selectedMedia,
    imageText,
    setImageText
  } = useMagicPenContext();

  const handleSelectTemplate = (template: IMediaTemplate) => {
    setSelectedMedia(template);
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.topContent}>
          <h2 className={cn('h3')}>MediaGallery</h2>
          <button
            onClick={() => setShowMediaLibrary(false)}
            className={styles.closeButton}
          >
            <SVG svg="x" className={styles.xIcon} />
          </button>
        </div>

        <div className={styles.gallery}>
          {mediaTemplates.map((template, i) => (
            <div
              className={cn(styles.buttonContainer, {
                [styles.selected]: selectedMedia === template
              })}
              key={i}
            >
              <button
                onClick={() => handleSelectTemplate(template)}
                className={styles.imageButton}
              >
                <div className={styles.buttonContent}>
                  <MediaWithText template={template} />
                </div>
              </button>
            </div>
          ))}
        </div>

        <TextInput
          textarea
          onChange={setImageText}
          value={imageText}
          label="Caption"
          className={styles.textInput}
        />

        <Button onClick={() => setShowMediaLibrary(false)}>Save</Button>
      </div>
    </div>
  );
};

export default MediaGallery;
