// extracted by mini-css-extract-plugin
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--82d56";
export var inspector = "styles-module--inspector--1a5c8";
export var inspectorContent = "styles-module--inspectorContent--e0c67";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var options = "styles-module--options--d7dd2";
export var optionsGrid = "styles-module--optionsGrid--77c10";
export var pillButtonsContainer = "styles-module--pillButtonsContainer--b1bf6";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";