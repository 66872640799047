import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  number: number;
  onClick: () => void;
  active?: boolean;
}

export const NumberButton = ({ onClick, number, active }: IProps) => {
  return (
    <button
      onClick={onClick}
      className={cn('button', styles.button, {
        [styles.active]: active
      })}
    >
      <div className={styles.background} />

      <span className={styles.content}>{number}</span>
    </button>
  );
};
