// extracted by mini-css-extract-plugin
export var container = "styles-module--container--4e28b";
export var desktop = "1340px";
export var dragLine = "styles-module--dragLine--244e2";
export var dragTab = "styles-module--dragTab--cfb94";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var widget = "styles-module--widget--5b26d";