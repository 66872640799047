// extracted by mini-css-extract-plugin
export var borderBottom = "styles-module--borderBottom--9e01b";
export var container = "styles-module--container--3ce8c";
export var desktop = "1340px";
export var giant = "2200px";
export var header = "styles-module--header--8eccb";
export var imageGrid = "styles-module--imageGrid--e5cef";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";