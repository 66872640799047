import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import {
  ATFWidget,
  LayoutProvider,
  TextAnimation
} from '@lam-agency/toolkit/components';
import {
  useBreakpoints,
  useFigmaImage,
  useScroll,
  useWindowDimensions
} from '@lam-agency/toolkit/hooks';
import { ISliceCopyOnImage } from 'lam-toolkit-figma-plugin';

const FigmaCopyOnImage = ({
  assetLinks,
  contactURL,
  images,
  quickLinks,
  text: { color, content, typestyle }
}: ISliceCopyOnImage) => {
  const [contentHeight, setContentHeight] = useState(9999); // Prevent scroll animations from triggering on slices below before content is loaded

  const contentRef = useRef<HTMLDivElement>(null);
  const { scrollY } = useScroll();
  const { height } = useWindowDimensions();
  const { smallDesktop } = useBreakpoints();

  const contentOverflowHeight = Math.max(0, contentHeight - height);
  const contentTransformY = smallDesktop
    ? -Math.min(contentOverflowHeight, scrollY)
    : 0;

  const imageItems = useFigmaImage(images);

  const amountToScrollBeforeBlurPx = 300; // sync with amoutToScrollBeforeFill in Header.tsx
  const blurMultiplier = 0.02;
  const normalisedScroll = Math.max(0, scrollY - amountToScrollBeforeBlurPx);
  const blurAmountPx = normalisedScroll * blurMultiplier;

  const darkenMultiplier = 0.002;
  const maxDarkenOpacity = 0.75;
  const darkenOpacity = Math.min(
    maxDarkenOpacity,
    normalisedScroll * darkenMultiplier
  );

  const imageScaleMultiplier = 0.0002;
  const maxScale = 1.1;
  const minScale = 1;

  const imageScale = Math.max(
    minScale,
    maxScale - normalisedScroll * imageScaleMultiplier
  );

  useEffect(() => {
    const calculateContentHeight = () => {
      if (!contentRef.current) return;

      const containerHeight = contentRef.current.clientHeight;
      setContentHeight(containerHeight);
    };

    setTimeout(calculateContentHeight, 100);

    window.addEventListener('resize', calculateContentHeight);

    return () => {
      window.removeEventListener('resize', calculateContentHeight);
    };
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div
          className={styles.content}
          ref={contentRef}
          style={{ transform: `translateY(${contentTransformY}px)` }}
        >
          {imageItems?.[0]?.image && (
            <img
              aria-hidden
              className={styles.background}
              src={imageItems[0].image}
              alt=""
              style={{ transform: `scale(${imageScale})` }}
            />
          )}

          <LayoutProvider grid paddingX paddingY className={styles.layout}>
            <div style={{ color }} className={styles.textWrapper}>
              <TextAnimation
                className={cn(typestyle)}
                text={content}
                animation="fade-grow"
                speed={0.03}
              />
            </div>

            <ATFWidget
              contactURL={contactURL}
              assetLinks={assetLinks}
              quickLinks={quickLinks}
            />
          </LayoutProvider>

          <div
            aria-hidden
            style={{
              backdropFilter: `blur(${blurAmountPx}px)`,
              backgroundColor: `rgba(0, 0, 0, ${darkenOpacity})`
            }}
            className={styles.blur}
          />
        </div>
      </div>
      <div
        aria-hidden
        style={{ height: contentHeight }}
        className={styles.scrollPadding}
      />
    </>
  );
};

export default FigmaCopyOnImage;
