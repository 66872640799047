import React from 'react';
import * as styles from './styles.module.scss';
import IntersectionAnimation from '../../../IntersectionAnimation';
import { IWidgetProps } from '../..';
import ATFWidgetContents from '../Contents';
import { getWhiteIfBackgroundIsTransparent } from '@lam-agency/toolkit/utils';

const DesktopATFWidget = ({
  assetLinks,
  quickLinks,
  contactURL
}: IWidgetProps) => {
  return (
    <IntersectionAnimation className={styles.widgetWrapper}>
      <div className={styles.widgetContainer}>
        <div
          className={styles.widget}
          style={{ background: getWhiteIfBackgroundIsTransparent() }}
        >
          <ATFWidgetContents
            assetLinks={assetLinks}
            contactURL={contactURL}
            quickLinks={quickLinks}
          />
        </div>
      </div>
    </IntersectionAnimation>
  );
};

export default DesktopATFWidget;
