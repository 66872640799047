import React from 'react';
import {
  SliceConfig,
  IntersectionAnimation
} from '@lam-agency/toolkit/components';
import { usePageNavigation } from '@lam-agency/toolkit/hooks';
import { useInView } from 'react-intersection-observer';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { IToolkitSliceConfig } from '@lam-agency/sanity';
import LinkTile from './components/LinkTile';

interface IProps {
  data: {
    sliceConfig: IToolkitSliceConfig;
  };
}

const Menulist = ({ data: { sliceConfig } }: IProps) => {
  const { pageCategories } = usePageNavigation();

  return (
    <nav>
      {pageCategories.map((category, i) => {
        const { ref, inView } = useInView({
          rootMargin: `-90px`
        });

        return (
          <div ref={ref} className={styles.category} key={i}>
            <SliceConfig config={sliceConfig}>
              <h4 className={cn(`h3`, styles.categoryTitle)}>
                {category.title}
              </h4>
              <nav>
                <ul className={styles.linkGrid}>
                  {category.pages.map((page, i) => (
                    <IntersectionAnimation
                      animation="fadeGrow"
                      delay={i * 150}
                      trigger={inView}
                      key={page.id}
                    >
                      <li>
                        <LinkTile page={page} />
                      </li>
                    </IntersectionAnimation>
                  ))}
                </ul>
              </nav>
            </SliceConfig>
          </div>
        );
      })}
    </nav>
  );
};

export default Menulist;
