// extracted by mini-css-extract-plugin
export var card = "styles-module--card--2d056";
export var cardBody = "styles-module--cardBody--ed28f";
export var cardHeader = "styles-module--cardHeader--5c33b";
export var copyButton = "styles-module--copyButton--f95d5";
export var dark = "styles-module--dark--35685";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var light = "styles-module--light--2d5e3";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var split = "styles-module--split--63780";
export var tablet = "769px";
export var text = "styles-module--text--5b5d8";