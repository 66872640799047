// extracted by mini-css-extract-plugin
export var body = "styles-module--body--64008";
export var container = "styles-module--container--0c6d8";
export var desktop = "1340px";
export var giant = "2200px";
export var heading = "styles-module--heading--28e15";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var leftCol = "styles-module--leftCol--1ab9c";
export var mobile = "400px";
export var rightCol = "styles-module--rightCol--2ad16";
export var secondaryHeading = "styles-module--secondaryHeading--e2c29";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var subheading = "styles-module--subheading--e079d";
export var tablet = "769px";