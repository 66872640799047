// extracted by mini-css-extract-plugin
export var content = "styles-module--content--c5e02";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--271cd";
export var gridItem = "styles-module--gridItem--4a80b";
export var gridItemContent = "styles-module--gridItemContent--adb6a";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var threeColumn = "styles-module--threeColumn--99446";