import React, { FC } from 'react';
import { useLiveQuery } from '@sanity/preview-kit';
import { ISlicePreview } from '@lam-agency/toolkit/types';

export interface IProps extends ISlicePreview {
  groqQuery: string;
  sliceComponent: FC<any>;
}

const SlicePreview = ({
  data: initialData,
  pageSlug,
  sliceIndex,
  pageType,
  groqQuery,
  sliceComponent: Slice
}: IProps) => {
  const [data] = useLiveQuery(initialData, groqQuery, {
    pageSlug,
    sliceIndex,
    pageType
  });

  return <Slice data={data} />;
};

export default SlicePreview;
