// extracted by mini-css-extract-plugin
export var backButton = "styles-module--backButton--37469";
export var button = "styles-module--button--da9ce";
export var buttonIcon = "styles-module--buttonIcon--af0ed";
export var container = "styles-module--container--47b17";
export var contentText = "styles-module--contentText--1f56c";
export var desktop = "1340px";
export var editButton = "styles-module--editButton--754aa";
export var editButtonContainer = "styles-module--editButtonContainer--9aa88";
export var editIcon = "styles-module--editIcon--b4027";
export var editInput = "styles-module--editInput--dfe20";
export var editInputContainer = "styles-module--editInputContainer--fe01d";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var messageActionButtons = "styles-module--messageActionButtons--b59e6";
export var mobile = "400px";
export var outputButtons = "styles-module--outputButtons--5e79a";
export var platformButtons = "styles-module--platformButtons--ba581";
export var postPreview = "styles-module--postPreview--d4bab";
export var promptContainer = "styles-module--promptContainer--93af1";
export var saveButton = "styles-module--saveButton--ea79e";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var textInput = "styles-module--textInput--b5618";
export var title = "styles-module--title--ae550";