import React, { useEffect, useRef, useState } from "react";
import * as styles from "./styles.module.scss";
import { IMediaTemplate } from "../MediaGallery";
import cn from "classnames";
import useMagicPenContext from "../../context";

interface IProps {
  template: IMediaTemplate;
}

const MediaWithText = ({
  template: { image, textLayout, textColor }
}: IProps) => {
  const [tileWidth, setTileWidth] = useState(0);

  const tileRef = useRef<HTMLDivElement>(null);

  const { imageText } = useMagicPenContext();
  const { height, width, bottom, left, right, top } = textLayout;

  const color = textColor === "dark" ? "black" : "white";

  useEffect(() => {
    const handleResize = () => {
      if (tileRef.current) {
        setTileWidth(tileRef.current.clientWidth);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const FONT_SIZE_MULTIPLIER = 0.12;

  const fontSize = `${tileWidth * FONT_SIZE_MULTIPLIER}px`;

  return (
    <div ref={tileRef} className={styles.absoluteContainer}>
      <div id="media-text-container" className={styles.container}>
        <img className={styles.image} src={image} alt="" />

        <p
          style={{ height, width, bottom, left, right, top, fontSize, color }}
          className={cn("h2", styles.text)}
        >
          {imageText}
        </p>
      </div>
    </div>
  );
};

export default MediaWithText;
