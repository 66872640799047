export const webComponents = [
  {
    title: 'Accordion',
    slug: 'accordion'
  },
  {
    title: 'Button',
    slug: 'button'
  },
  {
    title: 'Icon Button',
    slug: 'icon-button'
  },
  {
    title: 'Dropdown',
    slug: 'dropdown'
  },
  {
    title: 'Filter',
    slug: 'filter'
  }
];
