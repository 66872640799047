import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  content: string | undefined;
  handleUpdateActivePostContent: (updatedContent: string) => void;
}

const AutoHeightTextArea = ({
  content,
  handleUpdateActivePostContent
}: IProps) => {
  const [inputHeight, setInputHeight] = useState(0);

  const inputRef = React.useRef<HTMLTextAreaElement>(null);

  const updateInputHeight = () => {
    if (!content) {
      setInputHeight(0);
      return;
    }

    const tmpDiv = document.createElement('div');
    const inputWidth = inputRef.current?.getBoundingClientRect().width;

    tmpDiv.style.visibility = 'hidden';
    tmpDiv.style.whiteSpace = 'pre-wrap';
    tmpDiv.style.width = `${inputWidth}px`;
    tmpDiv.textContent = content;

    document.body.appendChild(tmpDiv);
    const contentsHeight = tmpDiv.getBoundingClientRect().height;

    document.body.removeChild(tmpDiv);

    setInputHeight(contentsHeight + 32);
  };

  useEffect(() => {
    updateInputHeight();
  }, [content, inputRef.current]);

  return (
    <textarea
      className={cn(styles.textarea)}
      value={content}
      ref={inputRef}
      style={{ height: inputHeight }}
      onChange={(e) => handleUpdateActivePostContent(e.target.value)}
    />
  );
};

export default AutoHeightTextArea;
