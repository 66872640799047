import React, { ChangeEvent } from 'react';
import * as styles from './styles.module.scss';
import ColorButton from './components/ColorButton';
import cn from 'classnames';
import { ISettings } from '@lam-agency/toolkit/slices/HomepageAtf';

interface IProps {
  settings: ISettings;
  setSettings: React.Dispatch<React.SetStateAction<ISettings>>;
}

const Form = ({ setSettings, settings }: IProps) => {
  const colorOptions = [
    `red`,
    `var(--color-plum)`,
    `var(--color-brown)`,
    `var(--color-lime)`,
    `var(--color-fuschia)`,
    `var(--color-blue)`,
    `var(--color-black-10)`,
    `var(--color-black-40)`,
    `var(--color-black-90)`
  ];

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSettings((prev) => ({
      ...prev,
      characters: e.target.value
    }));
  };

  return (
    <form className="form">
      <label className={styles.label}>
        <span className={cn(`caption`, styles.labelText, styles.inputLabel)}>
          Characters
        </span>
        <input
          maxLength={10}
          onChange={(e) => handleInputChange(e)}
          value={settings.characters}
          type="text"
          className={cn(`b1`, styles.input)}
        />
      </label>

      <label className={styles.label}>
        <span className={cn(`b1`, styles.labelText)}>Text color</span>

        <div className={styles.buttons}>
          {colorOptions.map((color, i) => {
            const handleClick = () => {
              setSettings((prev) => ({
                ...prev,
                textColor: color
              }));
            };

            return (
              <ColorButton
                active={settings.textColor === color}
                color={color}
                key={i}
                handleClick={handleClick}
              />
            );
          })}
        </div>
      </label>

      <label className={styles.label}>
        <span className={cn(`b1`, styles.labelText)}>Background color</span>

        <div className={styles.buttons}>
          {colorOptions.map((color, i) => {
            const handleClick = () => {
              setSettings((prev) => ({
                ...prev,
                backgroundColor: color
              }));
            };

            return (
              <ColorButton
                active={settings.backgroundColor === color}
                color={color}
                key={i}
                handleClick={handleClick}
              />
            );
          })}
        </div>
      </label>

      <label className={styles.label}>
        <span className={cn(`b1`, styles.labelText)}>Blend mode</span>
        <input
          checked={settings.blendMode}
          onChange={(e) => {
            setSettings((prev) => ({
              ...prev,
              blendMode: e.target.checked
            }));
          }}
          type="checkbox"
          className={styles.checkbox}
        />
      </label>
    </form>
  );
};

export default Form;
