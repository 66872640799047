// extracted by mini-css-extract-plugin
export var column = "styles-module--column--8cc36";
export var content = "styles-module--content--a34a5";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--68db5";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var leftColumn = "styles-module--leftColumn--2b8da";
export var lightScheme = "styles-module--lightScheme--06be9";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var threeColumns = "styles-module--threeColumns--bffbb";