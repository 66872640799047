import React, { useRef } from 'react';
import * as styles from './styles.module.scss';
import { usePaletteContext } from '../../context';
import { SVG } from '@lam-agency/toolkit/components';
// @ts-ignore
import domtoimage from 'dom-to-image-more';

const CreateModal = () => {
  const { isCreateModalOpen, setIsCreateModalOpen, paletteContent } =
    usePaletteContext();

  const handleClose = () => {
    setIsCreateModalOpen(false);
  };

  const instaPostRef = useRef<HTMLDivElement>(null);

  const generateImage = async () => {
    const imageNode = instaPostRef.current;
    if (!imageNode) return;

    domtoimage
      .toPng(imageNode)
      .then(function (dataUrl: any) {
        var img = new Image();
        img.src = dataUrl;
        const link = document.createElement('a');
        link.href = img.src;
        link.download = 'toolkit-palette-image.png';
        link.click();
      })
      .catch(function (error: any) {
        console.error('oops, something went wrong!', error);
      });
  };

  if (!isCreateModalOpen) return null;

  const { colors, images, logos, text } = paletteContent;

  const image = images[0]?.url;
  const logo = logos[0]?.url;
  const textString = text[0]?.children;
  const bgColor = colors[1]?.hex || 'black';
  const textColor = colors[0]?.hex || 'white';

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <button className={styles.closeButton} onClick={handleClose}>
          <SVG svg="x" className={styles.xIcon} />
        </button>

        <p className={styles.title}>Preview</p>

        <div
          style={{
            color: textColor,
            background: bgColor
          }}
          ref={instaPostRef}
          className={styles.instaPost}
        >
          {image && <img className={styles.bgImage} src={image} />}

          <div className={styles.postContent}>
            {textString && <p>{textString}</p>}

            {logo && (
              <div className={styles.logoContainer}>
                <img className={styles.logo} src={logo} />
              </div>
            )}
          </div>
        </div>

        <button onClick={generateImage} className={styles.button}>
          Generate Post
        </button>
      </div>
    </div>
  );
};

export default CreateModal;
