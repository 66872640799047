import React from 'react';
import {
  LayoutProvider,
  SliceHeader,
  TextImageGrid
} from '@lam-agency/toolkit/components';

interface IProps {
  data: {
    fields: {
      heading: string;
      body: string;
    };
  };
}

const FigmaShadow = ({ data: { fields } }: IProps) => {
  const { heading, body } = fields || {};

  const textImageGridItems = [
    {
      image: '/mvt-images/graphic-language/shadow-1.jpg',
      borderRadius: true
    }
  ];

  return (
    <LayoutProvider paddingX paddingY>
      <SliceHeader data={{ heading }} />

      <TextImageGrid
        body={body}
        bodyPosition="left"
        items={textImageGridItems}
      />
    </LayoutProvider>
  );
};

export default FigmaShadow;
