import React from 'react';
import cn from 'classnames';
import {
  IntersectionAnimation,
  LayoutProvider
} from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';

interface IProps {
  data: {
    heading?: string;
    bodyLeft?: string;
    bodyCenter?: string;
    bodyRight?: string;
  };
  removeBorder?: boolean;
  animationDelay?: number;
}

const TextColumns = ({
  data,
  removeBorder = false,
  animationDelay
}: IProps) => {
  const { heading, bodyLeft, bodyCenter, bodyRight } = data || {};

  return (
    <article
      className={cn(styles.container, { [styles.noBorder]: removeBorder })}
    >
      <LayoutProvider className={styles.grid} grid>
        {heading && (
          <IntersectionAnimation className={styles.heading}>
            <h2 className="b1">{heading}</h2>
          </IntersectionAnimation>
        )}

        <div className={styles.body}>
          {bodyLeft && (
            <IntersectionAnimation
              delay={animationDelay ? animationDelay * 1 : 0}
            >
              <p className="b1">{bodyLeft}</p>
            </IntersectionAnimation>
          )}
          {bodyCenter && (
            <IntersectionAnimation
              delay={animationDelay ? animationDelay * 3 : 0}
            >
              <p className="b1">{bodyRight}</p>
            </IntersectionAnimation>
          )}
          {bodyRight && (
            <IntersectionAnimation
              delay={animationDelay ? animationDelay * 4 : 0}
            >
              <p className="b1">{bodyRight}</p>
            </IntersectionAnimation>
          )}
        </div>
      </LayoutProvider>
    </article>
  );
};

export default TextColumns;
