import React from 'react';
import cn from 'classnames';
import {
  Button,
  TextAnimation,
  SVG,
  Partition
} from '@lam-agency/toolkit/components';
import {
  IntersectionAnimation,
  LayoutProvider
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { toKebabCase } from '@lam-agency/toolkit/utils';
import { useCopyToClipboard } from '@lam-agency/toolkit/hooks';
import { ISliceHeader } from 'lam-toolkit-figma-plugin';

interface IProps extends ISliceHeader {
  className?: string;
  locked?: boolean;
}

const SliceHeader = ({
  heading,
  buttons,
  className,
  partition,
  locked
}: IProps) => {
  const { copyStringToClipboard } = useCopyToClipboard();

  const delayOffset = heading ? 1 : 0;

  const getPageLink: () => string = () => {
    if (typeof window === 'undefined' || !heading) {
      return '';
    }

    return `${window.location.origin}${window.location.pathname}#${toKebabCase(
      heading.content
    )}`;
  };

  const handleClick = () => {
    copyStringToClipboard(getPageLink(), 'Copied section link to clipboard');
  };

  return (
    <LayoutProvider
      className={cn(className, { [styles.partition]: partition })}
      grid
    >
      {partition && <Partition />}

      <header
        id={heading ? toKebabCase(heading.content) : undefined}
        className={cn(styles.container)}
      >
        {heading && (
          <div className={styles.textContainer}>
            <TextAnimation
              element="h2"
              text={heading.content}
              animation="typed"
              className={heading.typestyle}
            />

            {!locked && (
              <button title="Copy link to section" onClick={handleClick}>
                <SVG svg="link" className={styles.copyIcon} />
              </button>
            )}
          </div>
        )}

        {buttons?.[0] && (
          <div className={styles.buttons}>
            {buttons.map(({ text, url }, index) => {
              if (!text || !url) {
                return null;
              }

              const delay = (index + delayOffset) * 150;

              return (
                <IntersectionAnimation
                  key={`slice-header-button-${text}-${index}`}
                  delay={delay}
                >
                  <Button to={url} iconRight={'arrowRight'} fluid>
                    {text}
                  </Button>
                </IntersectionAnimation>
              );
            })}
          </div>
        )}

        {locked && (
          <div>
            <div className={styles.lockContainer}>
              <SVG className={styles.lockIcon} svg="lock" />
            </div>
          </div>
        )}
      </header>
    </LayoutProvider>
  );
};

export default SliceHeader;
