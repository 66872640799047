// extracted by mini-css-extract-plugin
export var container = "styles-module--container--234a0";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--8ead4";
export var gridItem = "styles-module--gridItem--0ac2f";
export var gridItemContent = "styles-module--gridItemContent--c9c9f";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var toggleButtons = "styles-module--toggleButtons--426c3";
export var uppercase = "styles-module--uppercase--e8636";