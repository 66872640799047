// extracted by mini-css-extract-plugin
export var active = "styles-module--active--f159f";
export var caption = "styles-module--caption--9a0cc";
export var container = "styles-module--container--296ca";
export var controls = "styles-module--controls--ae064";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tab = "styles-module--tab--3822b";
export var tablet = "769px";