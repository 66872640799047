// extracted by mini-css-extract-plugin
export var borderBottom = "styles-module--borderBottom--8ed4c";
export var button = "styles-module--button--f7afa";
export var container = "styles-module--container--686ea";
export var cssProperties = "styles-module--cssProperties--0a4ae";
export var cssProperty = "styles-module--cssProperty--596fe";
export var desktop = "1340px";
export var displayText = "styles-module--displayText--84f7f";
export var fourCol = "styles-module--fourCol--b224a";
export var giant = "2200px";
export var grid = "styles-module--grid--688c5";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var properties = "styles-module--properties--7ef2d";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--75e37";