// extracted by mini-css-extract-plugin
export var background = "styles-module--background--7c5f1";
export var blur = "styles-module--blur--2e236";
export var body = "styles-module--body--a9bbc";
export var contact = "styles-module--contact--6d5ba";
export var contactBody = "styles-module--contactBody--d896c";
export var contactBodyText = "styles-module--contactBodyText--ff0fa";
export var container = "styles-module--container--f8283";
export var content = "styles-module--content--78c27";
export var desktop = "1340px";
export var ellipsis = "styles-module--ellipsis--9f93d";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var layout = "styles-module--layout--f48cf";
export var link = "styles-module--link--23c6f";
export var mobile = "400px";
export var mobileWidget = "styles-module--mobileWidget--e269b";
export var scrollPadding = "styles-module--scrollPadding--90276";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--48806";
export var textWrapper = "styles-module--textWrapper--b1301";
export var widget = "styles-module--widget--b0cab";
export var widgetContainer = "styles-module--widgetContainer--bfe55";
export var widgetWrapper = "styles-module--widgetWrapper--1cae8";