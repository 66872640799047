import React from 'react';
import {
  Imagery,
  LayoutProvider,
  SliceHeader,
  TextImageGrid,
  WebsitePrototype
} from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';
import { ISliceGallery } from 'lam-toolkit-figma-plugin';
import { useFigmaImage } from '@lam-agency/toolkit/hooks';

const FigmaGallery = ({ body1, body2, sliceHeader, images }: ISliceGallery) => {
  const imageData = useFigmaImage(images);

  return (
    <LayoutProvider paddingX paddingY>
      <LayoutProvider grid>
        <SliceHeader className={styles.header} {...sliceHeader} />
      </LayoutProvider>

      {imageData?.[0]?.image && (
        <Imagery
          data={{
            image: imageData[0].image,
            button: { text: 'Download PNG', icon: 'download' }
          }}
        />
      )}

      {imageData?.[1]?.image && imageData?.[2]?.image && (
        <TextImageGrid
          body={body1}
          bodyPosition="right"
          items={[imageData[1], imageData[2]]}
        />
      )}

      <WebsitePrototype />

      {imageData?.[3]?.image && (
        <TextImageGrid
          body={body2}
          bodyPosition="left"
          items={[imageData[3]]}
        />
      )}
    </LayoutProvider>
  );
};

export default FigmaGallery;
