import React, { useState } from 'react';
import {
  SliceConfig,
  PGComponents,
  type TPGComponent
} from '@lam-agency/toolkit/components';
import { camelCaseToTitleCase } from '@lam-agency/toolkit/utils';
import * as styles from './styles.module.scss';
import cn from 'classnames';
import { IToolkitWebComponents } from '@lam-agency/sanity';
import { Button } from '@lam-agency/toolkit/components';

interface IProps {
  data: IToolkitWebComponents;
}

const WebComponents = ({ data: { sliceConfig } }: IProps) => {
  const playgroundComponents = Object.keys(PGComponents) as TPGComponent[];

  const [activeComponent, setActiveComponent] = useState<TPGComponent>(
    playgroundComponents[0]
  );

  const PlaygroundElement = PGComponents[activeComponent];

  return (
    <SliceConfig config={sliceConfig}>
      <h2 className={cn(`h3`, styles.selectText)}>Select Component</h2>
      <div className={styles.selectComponentButtons}>
        {playgroundComponents.map((component, i) => (
          <Button
            key={i}
            variant={activeComponent === component ? `primary` : `secondary`}
            onClick={() => setActiveComponent(component)}
          >
            {camelCaseToTitleCase(component)}
          </Button>
        ))}
      </div>

      <PlaygroundElement />
    </SliceConfig>
  );
};

export default WebComponents;
