import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import * as styles from './styles.module.scss';
import LayoutProvider from '../../Common/LayoutProvider';
import { Button } from '@lam-agency/toolkit/components';
import { useBreakpoints } from '@lam-agency/toolkit/hooks';

interface IProps {
  data: any;
  displayText: string;
  activeBreakpoint: string;
  borderBottom: boolean;
  className?: string;
}

const WebStyleRow = ({
  data,
  displayText = '',
  activeBreakpoint,
  borderBottom = false,
  className
}: IProps) => {
  const { breakpoints, font, name, id } = data || {};

  const { smallMobile, smallTablet, smallDesktop } = useBreakpoints();

  const [cssStyles, setCssStyles] = useState({});
  const [activeProperties, setActiveProperties] = useState({});

  let textColumns = 1;
  let isFourCol = false;

  if (
    name.toLowerCase() === 'b1' ||
    name.toLowerCase() == 'b2' ||
    name.toLowerCase() == 'button' ||
    name.toLowerCase() == 'caption' ||
    name.toLowerCase() == 'label'
  ) {
    textColumns = 4;
    isFourCol = true;
  }

  const paragraphs = Array(textColumns).fill('');

  const formatStringToNumber = (value: string) => Math.round(parseInt(value));

  const getLetterSpacingEmFromPercentage = (
    letterSpacingPercentage: string,
    fontSizePx: string
  ) => {
    // Convert fontSizePx to em
    const fontSizeEm = parseInt(fontSizePx) / 16; // Assuming 1rem = 16px
    // Calculate letter spacing in em
    const letterSpacingEm =
      (parseInt(letterSpacingPercentage) / 100) * fontSizeEm;

    return letterSpacingEm.toFixed(3); // Return the letter spacing in "em" format with two decimal places
  };

  useEffect(() => {
    let properties;

    if (smallMobile) {
      properties = breakpoints.sm;
    }

    if (smallTablet) {
      properties = breakpoints.md;
    }

    if (smallDesktop) {
      properties = breakpoints[activeBreakpoint];
    }

    setActiveProperties(properties);
  }, [activeBreakpoint, smallTablet, smallMobile, smallDesktop]);

  useEffect(() => {
    const styles = {
      fontSize: `${activeProperties.fontSize}px`,
      lineHeight: `${formatStringToNumber(activeProperties.lineHeight)}%`
    };

    setCssStyles(styles);
  }, [activeProperties]);

  return (
    <div
      className={cn(styles.container, className, {
        [styles.borderBottom]: borderBottom
      })}
    >
      <LayoutProvider className={styles.grid} grid>
        <div className={styles.properties}>
          <header>
            <p className="b1">{name}</p>
          </header>

          <ul className={styles.cssProperties}>
            <li className={cn('caption', styles.cssProperty)}>
              <span>Font Family</span>
              <span>{font.family}</span>
            </li>
            <li className={cn('caption', styles.cssProperty)}>
              <span>Weight</span>
              <span>{font.style}</span>
            </li>
            <li className={cn('caption', styles.cssProperty)}>
              <span>Font Size</span>
              <span>{activeProperties?.fontSize}px</span>
            </li>
            <li className={cn('caption', styles.cssProperty)}>
              <span>Line Height</span>
              <span>{formatStringToNumber(activeProperties?.lineHeight)}%</span>
            </li>
            <li className={cn('caption', styles.cssProperty)}>
              <span>Letter Spacing</span>
              <span>
                {getLetterSpacingEmFromPercentage(
                  activeProperties?.letterSpacing,
                  activeProperties?.fontSize
                )}
                em
              </span>
            </li>
          </ul>
        </div>

        <div
          className={cn(styles.text, { [styles.fourCol]: isFourCol })}
          style={cssStyles}
        >
          {paragraphs.map((i, index) => {
            if (name.toLowerCase() === 'button') {
              return (
                <div
                  key={`webstyle-row-${name}-${index}`}
                  className={styles.button}
                >
                  <Button fluid>{displayText}</Button>
                </div>
              );
            }
            return (
              <p
                key={`webstyle-row-${name}-${index}`}
                className={styles.displayText}
              >
                {' '}
                {displayText}
              </p>
            );
          })}
        </div>
      </LayoutProvider>
    </div>
  );
};

export default WebStyleRow;
