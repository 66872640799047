import React from 'react';
import cn from 'classnames';
import {
  Colour,
  IntersectionAnimation,
  LayoutProvider
} from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';
import { useMVT } from '@lam-agency/toolkit/stores';

interface IProps {
  data: {
    heading?: string;
    category?: 'primary' | 'secondary' | 'mono';
  };
}

const ColourRow = ({ data }: IProps) => {
  const { heading, category } = data || {};
  const { colors } = useMVT();

  const filteredColors = colors.filter((color) => color.category === category);
  const isFourCol = filteredColors.length > 4;

  return (
    <div className={styles.container}>
      <LayoutProvider className={styles.grid} grid>
        <p className={cn('b1', styles.heading)}>{heading}</p>

        <div className={styles.colours}>
          {filteredColors.map((colour, index) => {
            return (
              <IntersectionAnimation
                key={`colour-row-${category}-colour-${index}`}
                className={cn(styles.colour, { [styles.fourCol]: isFourCol })}
                animation="fadeGrow"
              >
                <Colour data={colour} />
              </IntersectionAnimation>
            );
          })}
        </div>
      </LayoutProvider>
    </div>
  );
};

export default ColourRow;
