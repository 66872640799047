import React from 'react';
import {
  SliceConfig,
  IntersectionAnimation
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { IToolkitFigmaFrame } from '@lam-agency/sanity';

interface IProps {
  data: IToolkitFigmaFrame;
}

const FigmaFrame = ({ data: { url, sliceConfig } }: IProps) => {
  return (
    <SliceConfig config={sliceConfig}>
      <IntersectionAnimation>
        <div className={styles.frameContainer}>
          <iframe className={styles.iframe} allowFullScreen src={url} />
        </div>
      </IntersectionAnimation>
    </SliceConfig>
  );
};

export default FigmaFrame;
