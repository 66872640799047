import React from 'react';
import {
  ColourTile,
  LayoutProvider,
  SliceHeader,
  TwoColumnText
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import cn from 'classnames';
import { ISliceColourGrid } from 'lam-toolkit-figma-plugin';

export const FigmaColourGrid = ({
  sliceHeader,
  twoColumnText,
  colourTileRows
}: ISliceColourGrid) => {
  return (
    <div className={styles.container}>
      <LayoutProvider paddingX paddingY>
        <SliceHeader {...sliceHeader} />

        {twoColumnText && <TwoColumnText {...twoColumnText} />}

        {colourTileRows.map((colourRow, i) => (
          <div
            key={i}
            className={cn(styles.colourRow, styles[`row${colourRow.length}`])}
          >
            {colourRow.map((colourTile, j) => (
              <ColourTile
                {...colourTile}
                key={j}
                smallText={colourRow.length > 6}
              />
            ))}
          </div>
        ))}
      </LayoutProvider>
    </div>
  );
};
