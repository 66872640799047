import React from "react";
import * as styles from "./styles.module.scss";
import { usePaletteContext } from "../../context";

export interface INotification {
  content: string;
  keyToggle: 0 | 1;
}

interface IProps {}

const Notification = ({}: IProps) => {
  const { notification } = usePaletteContext();

  if (!notification.content) return null;

  return (
    <div className={styles.container} key={notification.keyToggle}>
      <p className={styles.notification}>{notification.content}</p>
    </div>
  );
};

export default Notification;
