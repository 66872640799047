import React, { useState } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import SVG from '../SVG';

interface IProps {
  src: string;
  hasSound?: boolean;
  className?: string;
}

export const Video = ({ src, hasSound, className }: IProps) => {
  const [isMuted, setIsMuted] = useState(true);

  const toggleIsMuted = () => setIsMuted((prev) => !prev);

  return (
    <div className={cn(styles.container, className)}>
      <video
        src={src}
        playsInline
        autoPlay
        muted={isMuted}
        loop
        className={styles.video}
        controls={false}
      />

      {hasSound && (
        <button onClick={toggleIsMuted} className={styles.muteButton}>
          <SVG svg={isMuted ? 'mute' : 'unmute'} className={styles.muteIcon} />
        </button>
      )}
    </div>
  );
};
