import React, { CSSProperties } from 'react';
import {
  IntersectionAnimation,
  SliceConfig
} from '@lam-agency/toolkit/components';
import { IToolkitTypeStyleSlice } from '@lam-agency/sanity';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { useInView } from 'react-intersection-observer';

interface IProps {
  data: IToolkitTypeStyleSlice;
}

interface ITypeStyleProperties {
  name: string;
  weight: string;
  size: string;
  lineHeight: string;
  letterSpacing: string;
}

const TypeStyle = ({ data: { text, style, sliceConfig } }: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  const getStyleProperties: () => ITypeStyleProperties = () => {
    switch (style) {
      case 'ABC Diatype Light':
        return {
          name: `ABC Diatype`,
          weight: `Light`,
          size: `168px`,
          lineHeight: `90%`,
          letterSpacing: `-1%`
        };
      case 'ABC Diatype Regular':
        return {
          name: `ABC Diatype`,
          weight: `Regular`,
          size: `168px`,
          lineHeight: `90%`,
          letterSpacing: `-1%`
        };

      default:
        return {
          name: ``,
          weight: ``,
          size: ``,
          lineHeight: ``,
          letterSpacing: ``
        };
    }
  };

  const { letterSpacing, lineHeight, name, size, weight } =
    getStyleProperties();

  const textStyles: CSSProperties = {
    fontFamily: style,
    fontSize: size
  };

  return (
    <div className={styles.container} ref={ref}>
      <SliceConfig config={sliceConfig}>
        <div className={styles.grid}>
          <IntersectionAnimation trigger={inView}>
            <h4 className={`subheading`}>{name}</h4>
          </IntersectionAnimation>
          <IntersectionAnimation
            className={styles.text}
            trigger={inView}
            delay={150}
          >
            <div style={textStyles}>{text}</div>
          </IntersectionAnimation>
          <IntersectionAnimation trigger={inView} delay={300}>
            <div className={cn(`subheading`, styles.properties)}>
              <div>{weight}</div>
              <div>{size}</div>
              <div>{lineHeight}</div>
              <div>{letterSpacing}</div>
            </div>
          </IntersectionAnimation>
        </div>
      </SliceConfig>
    </div>
  );
};

export default TypeStyle;
