// extracted by mini-css-extract-plugin
export var body = "styles-module--body--24973";
export var container = "styles-module--container--06635";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--c26d6";
export var imageContainer = "styles-module--imageContainer--5b8a9";
export var items = "styles-module--items--502f3";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var left = "styles-module--left--785f6";
export var mobile = "400px";
export var right = "styles-module--right--1d242";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var square = "styles-module--square--f0b7a";
export var tablet = "769px";