import { useMVT } from '../stores';

export const useCurrentPageData = () => {
  const { pageData } = useMVT();

  const getCurrentPageData = () => {
    if (typeof window === 'undefined') return;

    const slug = location.pathname.split('/')[1] || 'home';
    return pageData?.find((page) => page.slug === slug);
  };

  const currentPageData = getCurrentPageData();

  return { currentPageData };
};
