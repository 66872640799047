import React from 'react';
import { useApp } from '@lam-agency/toolkit/hooks';
import cn from 'classnames';
import { PageLink } from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { IPageData } from 'lam-toolkit-figma-plugin';

interface IProps {
  title: string;
  pages: IPageData[];
}

const PageCategoryGroup = ({ title: categoryTitle, pages }: IProps) => {
  const { pathname } = useApp();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h4 className={cn(styles.heading, `b1`)}>{categoryTitle}</h4>

        <ul className={styles.list}>
          {pages.map(({ id, name, slug }) => {
            const isActivePage = pathname?.includes(slug);

            return (
              <li key={id} className={styles.link}>
                <PageLink
                  title={name}
                  isActivePage={isActivePage}
                  slug={slug}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default PageCategoryGroup;
