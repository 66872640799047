import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";
import PromptTemplateOption from "./components/PromptTemplateOption";
import useMagicPenContext from "../../context";

export interface IProps {
  title: string;
  promptTemplates: string[];
}

const PromptCategory = ({ promptTemplates, title }: IProps) => {
  const { setSelectedPromptTemplate } = useMagicPenContext();

  return (
    <div className={styles.container}>
      <h3 className={cn("b1", styles.title)}>{title}</h3>

      {promptTemplates.map((template, i) => (
        <div className={styles.prompt} key={i}>
          <PromptTemplateOption
            promptTemplate={template}
            setSelectedPromptTemplate={setSelectedPromptTemplate}
          />
        </div>
      ))}
    </div>
  );
};

export default PromptCategory;
