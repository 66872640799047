import React from 'react';
import * as styles from './styles.module.scss';
import { IFont } from '@lam-agency/toolkit/types';

interface IProps {
  font: IFont;
}

const FontItem = ({ font }: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.formSection}>
        <p className={styles.label}>Title: {font.title}</p>
      </div>

      <div className={styles.formSection}>
        <p className={styles.label}>Font Weight: {font.weight}</p>
      </div>
    </div>
  );
};

export default FontItem;
