import React from 'react';
import * as styles from './styles.module.scss';
import SelectedContentWrapper from '../SelectedContentWrapper';

interface IProps {
  url: string;
  id: string;
}

const SelectedVideo = ({ url, id }: IProps) => {
  return (
    <SelectedContentWrapper type="videos" id={id}>
      <div className={styles.container}>
        <video
          muted
          autoPlay
          playsInline
          loop
          className={styles.video}
          src={url}
        />
      </div>
    </SelectedContentWrapper>
  );
};

export default SelectedVideo;
