import React from 'react';
import {
  ImageWithCaption,
  IImageWithCaption,
  IntersectionAnimation
} from '@lam-agency/toolkit/components';
import cn from 'classnames';

import * as styles from './styles.module.scss';

interface IProps {
  data: IImageWithCaption;
  className?: string;
}

const Imagery = ({ data, className }: IProps) => {
  const { image, button } = data || {};

  return (
    <div className={cn(styles.container, className)}>
      <IntersectionAnimation className={styles.image} animation="fadeGrow">
        <ImageWithCaption
          data={{
            image
          }}
        />
      </IntersectionAnimation>
    </div>
  );
};

export default Imagery;
