import React, { CSSProperties } from 'react';
import * as styles from './styles.module.scss';
import cn from 'classnames';
import { TActiveBreakpoint } from '../..';
import { ITypestyle, ITypestyleBreakpoint } from 'lam-toolkit-figma-plugin';

interface IProps {
  className?: string;
  typestyle: ITypestyle;
  activeBreakpoint: TActiveBreakpoint;
}

const TypestyleRow = ({ className, typestyle, activeBreakpoint }: IProps) => {
  const { breakpoints, font, properties } = typestyle;

  const getActiveBreakpointStyles: () => ITypestyleBreakpoint = () => {
    switch (activeBreakpoint) {
      case 'mobile':
        return breakpoints.sm;
      case 'tablet':
        return breakpoints.md;
      case 'desktop':
        return breakpoints.lg;
    }
  };
  const { fontSize, letterSpacing, lineHeight } =
    getActiveBreakpointStyles() || {};

  const getTypestyleStyles: () => CSSProperties = () => {
    return {
      fontSize: `${fontSize}px`,
      letterSpacing,
      lineHeight,
      textDecoration: properties.textDecoration,
      textTransform: properties.case as any,
      fontFamily: font.family
    };
  };

  return (
    <div className={cn(styles.container, className)}>
      <div style={getTypestyleStyles()} className={styles.title}>
        {typestyle.name}
      </div>

      <div className={cn('b2', styles.fontProperties)}>
        <div className={styles.propertyField}>
          <p>{fontSize}px</p>
        </div>
        <div className={styles.propertyField}>
          <p>{lineHeight}</p>
        </div>
        <div className={styles.propertyField}>
          <p>{letterSpacing}</p>
        </div>
      </div>
    </div>
  );
};

export default TypestyleRow;
