import React from 'react';
import cn from 'classnames';

import * as styles from './styles.module.scss';
import { SVG, TSVG } from '@lam-agency/toolkit/components';
import { LayoutProvider } from '@lam-agency/toolkit/components';

interface IProps {
  data: {
    maxWidth?: number;
    svg: TSVG;
    scale: number;
  };
  className?: string;
}

const TypeContainer = ({ data, className }: IProps) => {
  const { maxWidth, svg, scale } = data || {};

  return (
    <section className={cn(styles.container, className)}>
      <LayoutProvider className={styles.grid} grid>
        <p className={cn('b1', styles.text)}>Scale {scale}%</p>

        <figure
          style={{
            maxWidth: maxWidth ? `${maxWidth}px` : `none`
          }}
          className={styles.svg}
        >
          <SVG style={{ width: `${scale}%` }} svg={svg} />
        </figure>
      </LayoutProvider>
    </section>
  );
};

export default TypeContainer;
