import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import SelectedContentWrapper from '../SelectedContentWrapper';

interface IProps {
  text: string;
  style: string;
  id: string;
}

const SelectedText = ({ style, text, id }: IProps) => {
  return (
    <SelectedContentWrapper type="text" id={id}>
      <p className={cn(style, styles.text)}>{text}</p>
    </SelectedContentWrapper>
  );
};

export default SelectedText;
