// extracted by mini-css-extract-plugin
export var assetLinks = "styles-module--assetLinks--fe1af";
export var body = "styles-module--body--082a7";
export var contactBody = "styles-module--contactBody--a602f";
export var container = "styles-module--container--828fd";
export var desktop = "1340px";
export var desktopAssetLink = "styles-module--desktopAssetLink--88d5b";
export var ellipsis = "styles-module--ellipsis--0ec9d";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var mobileAssetLink = "styles-module--mobileAssetLink--d757c";
export var quickLinks = "styles-module--quickLinks--4cdac";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";