import React from 'react';
import cn from 'classnames';
import { SVG } from '@lam-agency/toolkit/components';
import { Tag } from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';
import { useCopyToClipboard } from '@lam-agency/toolkit/hooks';

export interface IGradient {
  name: string;
  gradient: string;
  category: string;
}

export const ColorGradientTile = ({ category, gradient, name }: IGradient) => {
  const { copyStringToClipboard } = useCopyToClipboard();

  const handleCopyName = () => {
    copyStringToClipboard(name, 'Copied name to clipboard');
  };

  const handleCopyGradient = () => {
    copyStringToClipboard(gradient, 'Copied gradient to clipboard');
  };

  return (
    <article
      className={cn(styles.card, styles.light)}
      style={{ background: `${gradient}` }}
    >
      <header className={styles.cardHeader}>
        <button
          className={cn('b1', styles.copyButton)}
          onClick={handleCopyName}
        >
          {name}
          <SVG svg="copy" />
        </button>

        <Tag text={category} theme="light" />
      </header>

      <div className={cn('caption', styles.cardBody)}>
        <button className={styles.copyButton} onClick={handleCopyGradient}>
          Copy Gradient CSS
          <SVG svg="copy" />
        </button>
      </div>
    </article>
  );
};
