// extracted by mini-css-extract-plugin
export var container = "styles-module--container--2cbce";
export var content = "styles-module--content--b50c7";
export var desktop = "1340px";
export var form = "styles-module--form--74dc0";
export var giant = "2200px";
export var header = "styles-module--header--4aa19";
export var iconBorder = "styles-module--iconBorder--db999";
export var iconContainer = "styles-module--iconContainer--cde13";
export var input = "styles-module--input--ee25c";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var lockIcon = "styles-module--lockIcon--6e13e";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var textContainer = "styles-module--textContainer--f514a";