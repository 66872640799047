import React from 'react';
import * as styles from './styles.module.scss';
import GridTileWrapper from '../GridTileWrapper';
import CopyValue from './components/CopyValue';
import cn from 'classnames';
import { hexToRGB } from '@lam-agency/toolkit/utils';
import { IColor } from 'lam-toolkit-figma-plugin';

interface IProps {
  color: IColor;
  borderColor: string;
}

const ColorTile = ({ color, borderColor }: IProps) => {
  const rgb = hexToRGB(color?.hex);
  const { blue, green, red } = rgb;
  const rgbString = `RGB (${red}, ${green}, ${blue})`;

  return (
    <GridTileWrapper tileColor={color.hex} borderColor={borderColor}>
      <div className={styles.container}>
        <div className={styles.topContent}>
          <h3 className={cn('h3')}>{color.name}</h3>
        </div>

        <div className={styles.bottomContent}>
          <div>
            <CopyValue value={color.hex} />
            <CopyValue value={rgbString} />
          </div>
        </div>
      </div>
    </GridTileWrapper>
  );
};

export default ColorTile;
