import React, { useState } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { TextInput } from '@lam-agency/toolkit/components';
import useMagicPenContext from '../../context';
import { Button } from '@lam-agency/toolkit/components';

const PostConfig = () => {
  const [input, setInput] = useState('');

  const { setPostTopic } = useMagicPenContext();

  const handleAcceptInput = () => {
    setPostTopic(input);
  };

  return (
    <div className={styles.section}>
      <p className={cn('h2', styles.title)}>Create a social post about...</p>

      <TextInput
        value={input}
        onChange={setInput}
        className={cn('b1', styles.textArea)}
        textarea
        placeholder="Enter post topic"
        warningMessage="The brand TOV will be automatically applied"
      />

      <Button onClick={handleAcceptInput} disabled={!input}>
        Next
      </Button>
    </div>
  );
};

export default PostConfig;
