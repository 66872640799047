// extracted by mini-css-extract-plugin
export var content = "styles-module--content--bffb1";
export var contentContainer = "styles-module--contentContainer--48aa6";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--025cb";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var properties = "styles-module--properties--7477d";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var styleTitle = "styles-module--styleTitle--bcb9f";
export var tablet = "769px";