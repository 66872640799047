import React from 'react';
import * as styles from './styles.module.scss';
import cn from 'classnames';
import { SVG } from '@lam-agency/toolkit/components';
import useMagicPenContext from '../../context';

const Home = () => {
  const { setScreen } = useMagicPenContext();

  return (
    <div className={styles.container}>
      <button
        className={styles.optionButton}
        onClick={() => setScreen('post-config')}
      >
        <div className={styles.buttonContentContainer}>
          <div className={cn('h2', styles.buttonContent)}>
            <SVG svg="pen" className={styles.icon} />
            <h2 className={cn('h2', styles.title)}>{`Start with\na topic`}</h2>
          </div>
        </div>
      </button>

      <button
        className={styles.optionButton}
        onClick={() => setScreen('template-select')}
      >
        <div className={styles.buttonContentContainer}>
          <div className={cn('h2', styles.buttonContent)}>
            <SVG svg="file" className={styles.icon} />
            <h2
              className={cn('h2', styles.title)}
            >{`Start with\na template`}</h2>
          </div>
        </div>
      </button>
    </div>
  );
};

export default Home;
