import {
  IPaletteColor,
  IPaletteImage,
  IPaletteLogo,
  IPaletteText,
  IPaletteVideo
} from "./types";

interface IData {
  paletteColorTiles?: IPaletteColor[];
  paletteVideos?: IPaletteVideo[];
  paletteTexts?: IPaletteText[];
  paletteImages?: IPaletteImage[];
  paletteLogos?: IPaletteLogo[];
}

const data: IData = {
  paletteColorTiles: [
    {
      hex: "#FF3737",
      name: "Red",
      textColor: "light",
      id: "1",
      type: "Color"
    },
    {
      hex: "#75114D",
      name: "Plum",
      textColor: "light",
      id: "2",
      type: "Color"
    },
    {
      hex: "#664A31",
      name: "Brown",
      id: "3",
      type: "Color"
    },
    {
      hex: "#D6FF62",
      name: "Lime",
      id: "4",
      type: "Color"
    },
    {
      hex: "#FF4AED",
      name: "Fuscia",
      id: "5",
      type: "Color"
    },
    {
      hex: "#6E62F4",
      name: "Purple",
      id: "6",
      type: "Color"
    },
    {
      hex: "#4F4F4F",
      name: "Dark Grey",
      id: "7",
      type: "Color"
    },
    {
      hex: "#FFFFFF",
      name: "Pure White",
      id: "8",
      type: "Color"
    }
  ],
  paletteTexts: [
    {
      children: "This is a headline",
      style: "h1",
      id: "4",
      type: "Text"
    },
    {
      children: "Some subtitle text here",
      style: "h2",
      id: "5",
      type: "Text"
    },
    {
      children:
        "Our flexible brand frameworks are built on the logic of Atomic Design: a proven framework for for building efficient, scalable systems across teams with diverse skill sets. Platforms, not uniforms.",
      style: "p",
      id: "6",
      type: "Text"
    }
  ],
  paletteVideos: [
    {
      url: "https://player.vimeo.com/progressive_redirect/playback/810402959/rendition/1080p/file.mp4?loc=external&signature=4b72eb6694de07e9685b9078b4017fe31afba63c0cfe5d1555ba2b5afdf7b636",
      id: "7",
      type: "Video"
    },
    {
      url: "https://player.vimeo.com/progressive_redirect/playback/745632550/rendition/1080p/file.mp4?loc=external&signature=ad5275d5f5bdf0d8483fb37907d21bf6b86147aee7292809461c5dd71f0a2aab",
      id: "8",
      type: "Video"
    }
  ],
  paletteImages: [
    {
      url: "https://images.unsplash.com/photo-1661956602926-db6b25f75947?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=998&q=80",
      id: "9",
      type: "Image"
    },
    {
      url: "https://images.unsplash.com/photo-1685549925654-e86dcebdd21a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1481&q=80",
      id: "10",
      type: "Image"
    },
    {
      url: "https://images.unsplash.com/photo-1685444213745-fab7153181f2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1364&q=80",
      id: "11",
      type: "Image"
    },
    {
      url: "https://images.unsplash.com/photo-1685394396539-258c33f2ae0a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80",
      id: "12",
      type: "Image"
    }
  ],
  paletteLogos: [
    {
      url: "/images/lam-logo.png",
      id: "13",
      type: "Logo"
    },
    {
      url: "/images/lam-wordmark.png",
      id: "14",
      type: "Logo"
    }
  ]
};

export default data;
