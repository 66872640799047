import React from 'react';
import {
  LayoutProvider,
  SliceHeader,
  TextImageGrid,
  ImageGrid
} from '@lam-agency/toolkit/components';

interface IProps {
  data: {
    fields: {
      heading: string;
      body: string;
    };
  };
}

const FigmaPadding = ({ data: { fields } }: IProps) => {
  const { heading, body } = fields || {};

  const textImageGridItems = [
    {
      image: '/mvt-images/graphic-language/padding-1.png',
      borderRadius: true
    }
  ];

  const imageGridItems = [
    {
      image: '/mvt-images/graphic-language/padding-2.png',
      borderRadius: true,
      caption: 'Padding space between elements within a button component.'
    },
    {
      image: '/mvt-images/graphic-language/padding-3.png',
      borderRadius: true,
      caption: 'Top and bottom padding within a button component.'
    },
    {
      image: '/mvt-images/graphic-language/padding-4.png',
      borderRadius: true,
      caption: 'Internal padding of an image-card element.'
    }
  ];

  return (
    <LayoutProvider paddingX paddingY>
      <SliceHeader data={{ heading }} />

      <TextImageGrid
        body={body}
        bodyPosition="right"
        items={textImageGridItems}
      />

      <ImageGrid items={imageGridItems} />
    </LayoutProvider>
  );
};

export default FigmaPadding;
