import React from 'react';
import cn from 'classnames';

import * as styles from './styles.module.scss';

export interface ISegmentedControlTab {
  key: string;
  value: string;
  caption?: string;
}

interface IProps {
  tabs: ISegmentedControlTab[];
  selectedTabIndex: number;
  setSelectedTabIndex: any;
  className?: string;
}

const SegementedControl = ({
  tabs,
  selectedTabIndex,
  setSelectedTabIndex,
  className
}: IProps) => {
  if (!tabs?.[0]) return null;

  return (
    <div className={cn(styles.container, className)}>
      <ul className={cn(styles.controls)}>
        {tabs.map(({ key, value, caption }, index: number) => {
          const isSelected = selectedTabIndex === index;

          return (
            <li
              key={`$segemented-control-tab-${key}-index`}
              className={cn('caption', styles.tab, {
                [styles.active]: isSelected
              })}
              onClick={() => setSelectedTabIndex(index)}
            >
              {key}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SegementedControl;
