// extracted by mini-css-extract-plugin
export var buttons = "styles-module--buttons--2d558";
export var container = "styles-module--container--8d2a1";
export var deleteButton = "styles-module--deleteButton--0274f";
export var deleteButtons = "styles-module--deleteButtons--5687c";
export var deleteIcon = "styles-module--deleteIcon--30224";
export var deleteMenu = "styles-module--deleteMenu--a659b";
export var deleteTitle = "styles-module--deleteTitle--31e1f";
export var desktop = "1340px";
export var fileUpload = "styles-module--fileUpload--84a05";
export var fontUploadSection = "styles-module--fontUploadSection--2d0f8";
export var formSection = "styles-module--formSection--3bed8";
export var giant = "2200px";
export var label = "styles-module--label--6ac46";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var spinner = "styles-module--spinner--8d67a";
export var tablet = "769px";
export var title = "styles-module--title--60905";
export var visible = "styles-module--visible--16ef8";