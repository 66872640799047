import React, { useEffect } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { SliceConfig } from '@lam-agency/toolkit/components';
import PostConfig from './screens/PostConfig';
import Home from './screens/Home';
import TemplateSelect from './screens/TemplateSelect';
import TemplateConfig from './screens/TemplateConfig';
import TemplateResults from './screens/TemplateResults';
import PostResults from './screens/PostResults';
import MediaGallery from './components/MediaGallery';
import useMagicPenContext, { MagicPenProvider } from './context';
import { Button } from '@lam-agency/toolkit/components';

const MagicPen = () => (
  <section className={styles.container}>
    <SliceConfig
      config={{
        slicePadding: {
          paddingX: `none`
        },
        backgroundColor: {
          hex: `var(--color-black-80)`
        },
        textColor: {
          hex: `var(--color-black-20)`
        }
      }}
    >
      <div className={styles.magicPenContainer}>
        <MagicPenProvider>
          <MagicPenContent />
        </MagicPenProvider>
      </div>
    </SliceConfig>
  </section>
);

export default MagicPen;

const MagicPenContent = () => {
  const {
    screen,
    showMediaLibrary,
    setScreen,
    setConstructedPrompt,
    setPostTopic,
    setSelectedMedia,
    setSelectedPromptTemplate
  } = useMagicPenContext();

  const reset = () => {
    setConstructedPrompt('');
    setPostTopic('');
    setSelectedMedia(null);
    setSelectedPromptTemplate('');
  };

  useEffect(() => {
    if (screen === 'home') {
      reset();
    }
  }, [screen]);

  return (
    <div className={styles.magicPenContent}>
      <SliceConfig
        config={{
          slicePadding: { paddingTop: 'small', paddingBottom: 'small' }
        }}
      >
        <div className={styles.introText}>
          <h1 className={cn('h1')}>Magic Pen</h1>

          {screen === 'home' && (
            <p className={cn('b1', styles.description)}>
              Generate copy automagically with LAM's TOV
            </p>
          )}

          {screen !== 'home' && (
            <Button
              onClick={() => setScreen('home')}
              className={styles.backButton}
              iconLeft="arrowLeft"
              variant="text"
            >
              Home
            </Button>
          )}
        </div>

        {screen === 'home' && <Home />}

        {screen === 'template-select' && <TemplateSelect />}

        {screen === 'template-config' && <TemplateConfig />}

        {screen === 'template-results' && <TemplateResults />}

        {screen === 'post-config' && <PostConfig />}

        {screen === 'post-results' && <PostResults />}

        {showMediaLibrary && <MediaGallery />}
      </SliceConfig>
    </div>
  );
};
