import React from "react";
import WCButton from "./components/WCButton";

interface IProps {
  component: string;
}

const WebComponent = ({ component }: IProps) => {
  const getComponent = () => {
    switch (component) {
      case "button":
        return <WCButton />;

      default:
        return null;
    }
  };

  return <div>{getComponent()}</div>;
};

export default WebComponent;
