import React from 'react';
import * as styles from './styles.module.scss';
import SelectedContentWrapper from '../SelectedContentWrapper';

interface IProps {
  url: string;
  id: string;
}

const SelectedImage = ({ url, id }: IProps) => {
  return (
    <SelectedContentWrapper type="images" id={id}>
      <div className={styles.container}>
        <img src={url} className={styles.image} alt="" />
      </div>
    </SelectedContentWrapper>
  );
};

export default SelectedImage;
