import React from 'react';
import { SlicePreview } from '@lam-agency/toolkit/components';
import groqQuery from './queries.groq';
import Slice from '.';
import { ISlicePreview } from '@lam-agency/toolkit/types';

export default ({ data, pageSlug, pageType, sliceIndex }: ISlicePreview) => (
  <SlicePreview
    data={data}
    groqQuery={groqQuery}
    pageSlug={pageSlug}
    pageType={pageType}
    sliceComponent={Slice}
    sliceIndex={sliceIndex}
  />
);
