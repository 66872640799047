import React from 'react';
import {
  SliceConfig,
  Grid,
  PortableTextRenderer,
  IntersectionAnimation
} from '@lam-agency/toolkit/components';
import {
  IToolkitSliceConfig,
  TLinkInternalOrExternal
} from '@lam-agency/sanity';
import { useInView } from 'react-intersection-observer';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { Button } from '@lam-agency/toolkit/components';

interface IProps {
  data: {
    leftColumn?: any;
    rightColumn?: any;
    _rawLeftColumn?: any;
    _rawRightColumn?: any;
    links?: TLinkInternalOrExternal[];
    sliceConfig?: IToolkitSliceConfig;
    tags?: string[];
  };
}

const TextArticle = ({
  data: {
    _rawLeftColumn,
    _rawRightColumn,
    leftColumn,
    rightColumn,
    links,
    tags,
    sliceConfig
  }
}: IProps) => {
  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  const leftColumnContent = _rawLeftColumn || leftColumn;
  const rightColumnContent = _rawRightColumn || rightColumn;

  return (
    <div ref={ref}>
      <SliceConfig config={sliceConfig}>
        <Grid>
          {leftColumnContent && (
            <div className={styles.leftColumn}>
              <IntersectionAnimation trigger={inView}>
                <PortableTextRenderer rawText={leftColumnContent} />
              </IntersectionAnimation>

              {tags?.[0] && (
                <IntersectionAnimation trigger={inView}>
                  <div className={styles.tags}>
                    {tags.map((tag, i) => (
                      <IntersectionAnimation
                        delay={150 * i}
                        key={i}
                        animation="fadeGrow"
                      >
                        <div className={cn(`button-text`, styles.tag)}>
                          {tag}
                        </div>
                      </IntersectionAnimation>
                    ))}
                  </div>
                </IntersectionAnimation>
              )}
            </div>
          )}

          {rightColumnContent && (
            <div className={styles.rightColumn}>
              <IntersectionAnimation delay={150} trigger={inView}>
                <PortableTextRenderer rawText={rightColumnContent} />
              </IntersectionAnimation>

              {links?.[0] && (
                <IntersectionAnimation delay={300} className={styles.buttons}>
                  {links?.map((link, i) => {
                    if (link._type === `linkInternal`) {
                      return (
                        <IntersectionAnimation
                          animation="fadeGrow"
                          delay={300 + 100 * i}
                          key={i}
                        >
                          <Button
                            key={i}
                            to={`/${link.reference.slug.current}`}
                          >
                            {link.title}
                          </Button>
                        </IntersectionAnimation>
                      );
                    }

                    if (link._type === `linkExternal`) {
                      return (
                        <IntersectionAnimation
                          animation="fadeGrow"
                          delay={300 + 100 * i}
                          key={i}
                        >
                          <Button key={i} to={link.url}>
                            {link.title}
                          </Button>
                        </IntersectionAnimation>
                      );
                    }

                    return null;
                  })}
                </IntersectionAnimation>
              )}
            </div>
          )}
        </Grid>
      </SliceConfig>
    </div>
  );
};

export default TextArticle;
