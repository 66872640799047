import React, { useState } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import SVG from '../SVG';
import TextInput from '../TextInput';
import { Button, IntersectionAnimation } from '../..';

interface IProps {
  onSubmitCorrectPassword: () => void;
}

export const Password = ({ onSubmitCorrectPassword }: IProps) => {
  const password = process.env.GATSBY_SITE_PASSWORD;

  const [inputValue, setInputValue] = useState('');
  const [hasError, setHasError] = useState(false);

  const handleUpdateInput = (newValue: string) => {
    setInputValue(newValue);
    setHasError(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isCorrectPassword = inputValue === password;

    if (isCorrectPassword) {
      onSubmitCorrectPassword();
    } else {
      setHasError(true);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <IntersectionAnimation className={styles.iconContainer}>
          <SVG className={styles.lockIcon} svg="lock" />
          <div className={styles.iconBorder} />
        </IntersectionAnimation>

        <IntersectionAnimation delay={100} className={styles.textContainer}>
          <h1 className={cn('h1', styles.header)}>Access Restricted</h1>
          <p className={cn('b1', styles.subheader)}>
            Enter password to access this page.
          </p>
        </IntersectionAnimation>

        <form onSubmit={handleSubmit} className={styles.form}>
          <IntersectionAnimation delay={200}>
            <TextInput
              className={styles.input}
              onChange={handleUpdateInput}
              value={inputValue}
              password
              placeholder="Enter password"
              hasError={hasError}
              hint={hasError ? 'Incorrect Password' : ''}
            />
            <Button type="submit" fluid>
              Submit
            </Button>
          </IntersectionAnimation>
        </form>
      </div>
    </div>
  );
};
