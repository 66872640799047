import React from 'react';
import * as styles from './styles.module.scss';
import cn from 'classnames';
import { SVG } from '@lam-agency/toolkit/components';
import { useScroll } from '@lam-agency/toolkit/hooks';
import { getWhiteIfBackgroundIsTransparent } from '@lam-agency/toolkit/utils';

const ScrollToTop = () => {
  const SCROLL_THRESHOLD_PX = 1500;

  const { scrollY } = useScroll();

  const showButton = scrollY > SCROLL_THRESHOLD_PX;

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <button
      onClick={handleClick}
      className={cn(styles.button, showButton && styles.active)}
      style={{ color: getWhiteIfBackgroundIsTransparent() }}
    >
      <div aria-hidden className={styles.background} />

      <div className={styles.content}>
        <SVG svg="arrowDown" className={styles.arrowIcon} />
        <span className="b1">Back To Top</span>
      </div>
    </button>
  );
};

export default ScrollToTop;
