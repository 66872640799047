import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import {
  AnchorLinks,
  IntersectionAnimation,
  LayoutProvider
} from '@lam-agency/toolkit/components';

interface IProps {
  data: {
    fields: {
      body: string;
      widgetHeading?: string;
      buttonLeft?: string;
      buttonRight?: string;
    };
    anchorLinks: any;
  };
}

const FigmaHero = ({ data: { fields, anchorLinks } }: IProps) => {
  const { body, widgetHeading, buttonLeft, buttonRight } = fields || ``;

  return (
    <div className={styles.container}>
      <LayoutProvider grid paddingX className={styles.layout}>
        <IntersectionAnimation className={styles.textWrapper}>
          <p className={cn('d1', styles.text)}>{body}</p>
        </IntersectionAnimation>

        <AnchorLinks
          className={styles.anchorLinks}
          data={{
            heading: widgetHeading,
            buttonLeft,
            buttonRight,
            links: anchorLinks
          }}
        />

        {/* {(smallDesktop && (
          <IntersectionAnimation className={styles.widgetWrapper}>
            <div className={styles.widgetContainer}>
              <div className={styles.widget}>
                <div className={styles.body}>
                  <p className="caption">{WIDGET_DATA.heading}</p>

                  <ul>
                    {WIDGET_DATA.links.map((link) => (
                      <li className={styles.link}>
                        <span className={cn('b1', styles.widgetLinkTitle)}>
                          {link.title}
                        </span>
                        <span className={styles.ellipsis} />
                        <Button variant="text" iconRight="download">
                          {link.linkText}
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className={styles.contact}>
                  <p className="caption">{WIDGET_DATA.contactHeading}</p>
                  <div className={styles.contactBody}>
                    <p className={cn('b2', styles.contactBodyText)}>
                      {WIDGET_DATA.contactBody}
                    </p>
                    <Button
                      className={styles.contactButton}
                      
                    >
                      {WIDGET_DATA.contactButtonText}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </IntersectionAnimation>
        )) || (
          <DownloadLinks className={styles.mobileWidget} data={WIDGET_DATA} />
        )} */}
      </LayoutProvider>
    </div>
  );
};

export default FigmaHero;
