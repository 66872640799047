import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { ITextMediaColumns } from 'lam-toolkit-figma-plugin';
import LayoutProvider from '../../Common/LayoutProvider';
import { MediaItem } from '../MediaItem';

export const TextMediaColumns = ({
  mediaItems,
  text,
  textAlign
}: ITextMediaColumns) => {
  const singleMediaItem = mediaItems.length === 1;

  return (
    <LayoutProvider grid className={cn(text.typestyle, styles.container)}>
      {textAlign === 'left' && <p className={styles.text}>{text.content}</p>}

      {mediaItems.map((mediaItem, i) => (
        <MediaItem
          key={i}
          aspectRatio={singleMediaItem ? 'landscape' : 'square'}
          className={cn(styles.mediaItem, {
            [styles.singleMediaItem]: singleMediaItem
          })}
          {...mediaItem}
        />
      ))}

      {textAlign === 'right' && <p className={styles.text}>{text.content}</p>}
    </LayoutProvider>
  );
};
