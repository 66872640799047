import React from 'react';
import cn from 'classnames';

import * as styles from './styles.module.scss';

interface IProps {
  text: string;
  theme: 'light' | 'dark';
}

const Tag = ({ text, theme }: IProps) => {
  return <p className={cn('caption', styles.text, styles[theme])}>{text}</p>;
};

export default Tag;
