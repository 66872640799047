import React, { useState } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { IBentoBlock } from 'lam-toolkit-figma-plugin/dist/figma/sliceParsers/bentoBox';
import { NumberButton } from '../../Common/NumberButton';
import { Button } from '../..';
import { Link } from 'gatsby';
import { useFigmaImageSrc } from '@lam-agency/toolkit/hooks';

interface IProps extends IBentoBlock {
  className?: string;
}

export const BentoBlock = ({ title, className, href, slides }: IProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const textColor = slides[activeIndex]?.textColor || 'var(--color-text)';

  return (
    <article className={cn(styles.container, className)}>
      <div className={styles.carouselContainer}>
        <div
          className={styles.carouselRow}
          style={{
            width: `${slides.length * 100}%`,
            transform: `translateX(-${activeIndex * (100 / slides.length)}%)`
          }}
        >
          {slides.map((slide, i) => (
            <BentoSlide key={i} image={slide.image} />
          ))}
        </div>
      </div>

      <Link to={href} className={styles.content}>
        <h2 style={{ color: textColor }} className={cn('b2', styles.title)}>
          {title}
        </h2>

        <div className={styles.linkButton}>
          <Button display backgroundColor="white">
            Learn More
          </Button>
        </div>
      </Link>

      {slides.length > 1 && (
        <div className={styles.carouselButtons}>
          {Array.from(Array(slides.length)).map((_, i) => (
            <NumberButton
              number={i + 1}
              onClick={() => setActiveIndex(i)}
              key={i}
              active={activeIndex === i}
            />
          ))}
        </div>
      )}
    </article>
  );
};

const BentoSlide = ({ image }: { image: string }) => {
  const imageSrc = useFigmaImageSrc(image);

  return (
    <div className={styles.carouselImageContainer}>
      <img className={styles.carouselImage} src={imageSrc} />
    </div>
  );
};
