import React from 'react';
import * as styles from './styles.module.scss';
import CopyElementToPalette from '../CopyElementToPalette';
import { IPaletteImage } from '../../types';

const PaletteImage = ({ url, id }: IPaletteImage) => {
  return (
    <CopyElementToPalette className={styles.container} type="image" id={id}>
      <img className={styles.image} src={url} alt="" />
    </CopyElementToPalette>
  );
};

export default PaletteImage;
