import React from 'react';
import * as styles from './styles.module.scss';
import CopyElementToPalette from '../CopyElementToPalette';
import { IPaletteVideo } from '../../types';

const PaletteVideo = ({ url, id }: IPaletteVideo) => {
  return (
    <CopyElementToPalette type="video" id={id}>
      <video
        className={styles.video}
        autoPlay
        playsInline
        muted
        loop
        src={url}
      />
    </CopyElementToPalette>
  );
};

export default PaletteVideo;
