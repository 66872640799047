// extracted by mini-css-extract-plugin
export var buttons = "styles-module--buttons--35bef";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var leftColumn = "styles-module--leftColumn--6aa22";
export var mobile = "400px";
export var rightColumn = "styles-module--rightColumn--ae2f5";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var tag = "styles-module--tag--0141d";
export var tags = "styles-module--tags--6cb5b";