import React from 'react';
import { LayoutProvider, SliceHeader } from '@lam-agency/toolkit/components';
import { ImageGrid } from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { ISliceImageGrid } from 'lam-toolkit-figma-plugin';
import { useFigmaImage } from '@lam-agency/toolkit/hooks';

const FigmaImageGrid = ({ images, sliceHeader }: ISliceImageGrid) => {
  const imageItems = useFigmaImage(images);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <LayoutProvider paddingX paddingY>
          <SliceHeader {...sliceHeader} />

          {imageItems && <ImageGrid items={imageItems} />}
        </LayoutProvider>
      </div>
    </div>
  );
};

export default FigmaImageGrid;
