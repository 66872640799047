// extracted by mini-css-extract-plugin
export var Body = "styles-module--Body--8d119";
export var Display = "styles-module--Display--9b135";
export var Heading = "styles-module--Heading--100b2";
export var container = "styles-module--container--94311";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var mobileTitle = "styles-module--mobileTitle--42387";
export var partition = "styles-module--partition--f2d3b";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var textarea = "styles-module--textarea--e527e";