import React from 'react';
import cn from 'classnames';
import { useAccordion } from '@lam-agency/toolkit/hooks';
import * as styles from './styles.module.scss';
import { SVG } from '@lam-agency/toolkit/components';
import { IWidgetProps } from '../..';
import ATFWidgetContents from '../Contents';
import { getWhiteIfBackgroundIsTransparent } from '@lam-agency/toolkit/utils';

const MobileATFWidget = ({
  assetLinks,
  contactURL,
  quickLinks
}: IWidgetProps) => {
  const { containerHeight, contentRef, isExpanded, setIsExpanded } =
    useAccordion();

  return (
    <aside
      className={cn(styles.container)}
      style={{ background: getWhiteIfBackgroundIsTransparent() }}
    >
      <header
        className={cn(styles.header, {
          [styles.expanded]: isExpanded
        })}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div
          className={cn(styles.activeBackground, {
            [styles.active]: isExpanded
          })}
          aria-hidden
        />

        <div className={styles.headerContent}>
          <p className="b2">Links & Downloads</p>

          <SVG
            className={cn(styles.chevron, {
              [styles.expanded]: isExpanded
            })}
            svg="chevronDown"
          />
        </div>
      </header>

      <div
        className={styles.contentWrapper}
        style={{ height: containerHeight }}
      >
        <div className={styles.contentContainer} ref={contentRef}>
          <ATFWidgetContents
            assetLinks={assetLinks}
            contactURL={contactURL}
            quickLinks={quickLinks}
          />
        </div>
      </div>
    </aside>
  );
};

export default MobileATFWidget;
