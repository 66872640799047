import React from "react";
import { ISettings } from "../..";
import DesktopForm from "./components/DesktopForm";
import MobileForm from "./components/MobileForm";

interface IProps {
  settings: ISettings;
  setSettings: React.Dispatch<React.SetStateAction<ISettings>>;
}

const SettingsWidget = ({ setSettings, settings }: IProps) => {
  return (
    <>
      <DesktopForm setSettings={setSettings} settings={settings} />
      <MobileForm setSettings={setSettings} settings={settings} />
    </>
  );
};

export default SettingsWidget;
