import React from 'react';
import cn from 'classnames';
import {
  ImageWithCaption,
  IImageWithCaption,
  IntersectionAnimation,
  LayoutProvider
} from '@lam-agency/toolkit/components';
import { useBreakpoints } from '@lam-agency/toolkit/hooks';

import * as styles from './styles.module.scss';

interface IProps {
  body?: string;
  bodyPosition?: 'left' | 'right';
  items: IImageWithCaption[];
  className?: string;
}

const TextImageGrid = ({ body, bodyPosition, items, className }: IProps) => {
  const { tablet } = useBreakpoints();

  const delayOffset = body ? 1 : 0;

  return (
    <div className={cn(styles.container, className)}>
      <LayoutProvider className={styles.grid} grid>
        {body && (
          <IntersectionAnimation
            className={cn(styles.body, {
              [styles.right]: bodyPosition === 'right'
            })}
          >
            <p className="b1">{body}</p>
          </IntersectionAnimation>
        )}

        <div
          className={cn(styles.items, {
            [styles.left]: bodyPosition === 'right'
          })}
        >
          {items.map((item, index) => {
            const delay = (index + delayOffset) * 100;
            return (
              <div
                key={`text-image-grid-item-${index}`}
                style={{
                  flexBasis: tablet
                    ? `calc(calc(100% / ${items.length}) - 12px)`
                    : `100%`
                }}
              >
                <IntersectionAnimation
                  className={cn(styles.imageContainer, {
                    [styles.square]: items.length > 1
                  })}
                  delay={delay}
                  animation="fadeGrow"
                >
                  <ImageWithCaption data={item} />
                </IntersectionAnimation>
              </div>
            );
          })}
        </div>
      </LayoutProvider>
    </div>
  );
};

export default TextImageGrid;
