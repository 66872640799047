import React, { useState } from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {}

type VariableOption = 'small' | 'medium';

const WCButton = (props: IProps) => {
  const [borderRadius, setBorderRadius] = useState<VariableOption>('small');
  const [paddingX, setPaddingX] = useState<VariableOption>('small');
  const [paddingY, setPaddingY] = useState<VariableOption>('small');

  // const borderRadiusValue =
  //   borderRadius === 'small'
  //     ? variables?.border_radius_small || ''
  //     : variables?.border_radius_medium || '';

  // const paddingXValue =
  //   paddingX === 'small'
  //     ? variables?.padding_small || ''
  //     : variables?.padding_medium || '';

  // const paddingYValue =
  //   paddingY === 'small'
  //     ? variables?.padding_small || ''
  //     : variables?.padding_medium || '';

  // const padding = `${paddingYValue} ${paddingXValue}`;

  return (
    <>
      <button
        className={styles.button}
        style={{
          background: 'white',
          color: 'black'
          // borderRadius: borderRadiusValue,
          // padding
        }}
      >
        Click Me
      </button>

      <div>
        <fieldset className={styles.fieldSet}>
          <legend className={cn('h3', styles.fieldTitle)}>Border Radius</legend>
          <div className={styles.checkboxes}>
            <label className={cn('caption', styles.checkbox)}>
              <input
                type="checkbox"
                checked={borderRadius === 'small'}
                onChange={(e) => {
                  if (e.target.checked) {
                    setBorderRadius('small');
                  }
                }}
              />
              Small
            </label>
            <label className={cn('caption', styles.checkbox)}>
              <input
                type="checkbox"
                checked={borderRadius === 'medium'}
                onChange={(e) => {
                  if (e.target.checked) {
                    setBorderRadius('medium');
                  }
                }}
              />
              Medium
            </label>
          </div>
        </fieldset>

        <fieldset className={styles.fieldSet}>
          <legend className={cn('h3', styles.fieldTitle)}>Padding X</legend>
          <div className={styles.checkboxes}>
            <label className={cn('caption', styles.checkbox)}>
              <input
                type="checkbox"
                checked={paddingX === 'small'}
                onChange={(e) => {
                  if (e.target.checked) {
                    setPaddingX('small');
                  }
                }}
              />
              Small
            </label>
            <label className={cn('caption', styles.checkbox)}>
              <input
                type="checkbox"
                checked={paddingX === 'medium'}
                onChange={(e) => {
                  if (e.target.checked) {
                    setPaddingX('medium');
                  }
                }}
              />
              Medium
            </label>
          </div>
        </fieldset>

        <fieldset className={styles.fieldSet}>
          <legend className={cn('h3', styles.fieldTitle)}>Padding Y</legend>
          <div className={styles.checkboxes}>
            <label className={cn('caption', styles.checkbox)}>
              <input
                type="checkbox"
                checked={paddingY === 'small'}
                onChange={(e) => {
                  if (e.target.checked) {
                    setPaddingY('small');
                  }
                }}
              />
              Small
            </label>
            <label className={cn('caption', styles.checkbox)}>
              <input
                type="checkbox"
                checked={paddingY === 'medium'}
                onChange={(e) => {
                  if (e.target.checked) {
                    setPaddingY('medium');
                  }
                }}
              />
              Medium
            </label>
          </div>
        </fieldset>
      </div>
    </>
  );
};

export default WCButton;
