import React, { FC, useEffect, useState } from 'react';
import * as styles from './styles.module.scss';
import { SVG } from '@lam-agency/toolkit/components';

const LoadingScreen = () => {
  const [Component, setComponent] = useState<FC>(() => Spinner);

  const loadCustomLoader = async () => {
    const customComponents = (await import(
      '@lam-agency/toolkit/custom/components'
    )) as any; // to suppress error if custom components file does not exist

    const CustomLoader = customComponents.LoadingAnimation;

    setComponent(() => CustomLoader || Spinner);
  };

  useEffect(() => {
    loadCustomLoader();
  }, []);

  return <div className={styles.container}>{<Component />}</div>;
};

export default LoadingScreen;

export const Spinner = () => {
  return (
    <div className={styles.content}>
      <div className={styles.logoContainer}>
        <SVG svg="wordmark" className={styles.logo} />

        <div className={styles.circle} />
      </div>
    </div>
  );
};
