import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';

interface IProps {
  children: React.ReactNode;
  onClick: () => void;
  active: boolean;
}

export const PreviewGridButton = ({ active, children, onClick }: IProps) => {
  return (
    <button
      onClick={onClick}
      className={cn(styles.inspectorOptionButton, {
        [styles.active]: active
      })}
    >
      <div className={styles.buttonContent}>{children}</div>
    </button>
  );
};
