import React from 'react';
import {
  ProductCard,
  IProductCard,
  LayoutProvider,
  IntersectionAnimation
} from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';

interface IProps {
  data: {
    items: IProductCard[];
  };
  className?: string;
}

const ProductGrid = ({ data }: IProps) => {
  const { items } = data || {};

  if (!items?.[0]) return null;

  return (
    <section>
      <LayoutProvider className={styles.wrapper}>
        {items.map((item: IProductCard, index) => {
          return (
            <IntersectionAnimation
              key={`product-grid-card-${item?.title}-${index}`}
              delay={index * 100}
            >
              <ProductCard data={item} />
            </IntersectionAnimation>
          );
        })}
      </LayoutProvider>
    </section>
  );
};

export default ProductGrid;
