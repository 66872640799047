import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { Button } from '@lam-agency/toolkit/components';
import { IToolkitResourceLink } from '@lam-agency/sanity';

interface IProps extends IToolkitResourceLink {
  className?: string;
}

const ResourceLink = ({ title, buttonText, url, className }: IProps) => {
  return (
    <div className={cn(styles.container, className)}>
      <div className={cn(`h3`, styles.title)}>{title}</div>
      <div className={styles.border} />
      <Button to={url}>{buttonText}</Button>
    </div>
  );
};

export default ResourceLink;
