// extracted by mini-css-extract-plugin
export var active = "styles-module--active--0481d";
export var buttonContent = "styles-module--buttonContent--933a2";
export var desktop = "1340px";
export var giant = "2200px";
export var inspectorOptionButton = "styles-module--inspectorOptionButton--339ba";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";