import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

interface IProps {
  promptTemplate: string;
  setSelectedPromptTemplate: React.Dispatch<React.SetStateAction<string>>;
}

const PromptTemplateOption = ({
  promptTemplate,
  setSelectedPromptTemplate
}: IProps) => {
  const handleClick = () => {
    setSelectedPromptTemplate(promptTemplate);
  };

  return (
    <button onClick={handleClick} className={styles.container}>
      <p className={cn("b2")}>"{promptTemplate}"</p>
    </button>
  );
};

export default PromptTemplateOption;
