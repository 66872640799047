// extracted by mini-css-extract-plugin
export var backButton = "styles-module--backButton--6a4e3";
export var campaignSection = "styles-module--campaignSection--2a9a1";
export var container = "styles-module--container--acbd5";
export var description = "styles-module--description--cda3f";
export var desktop = "1340px";
export var giant = "2200px";
export var introText = "styles-module--introText--9a60b";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var magicPenContainer = "styles-module--magicPenContainer--712fb";
export var magicPenContent = "styles-module--magicPenContent--3875f";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var textArea = "styles-module--textArea--31f7a";