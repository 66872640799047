// extracted by mini-css-extract-plugin
export var container = "styles-module--container--4dbb7";
export var desktop = "1340px";
export var giant = "2200px";
export var header = "styles-module--header--a6040";
export var imageGrid = "styles-module--imageGrid--113c7";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";