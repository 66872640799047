import React from 'react';
import Draggable from 'react-draggable';
import * as styles from './styles.module.scss';
import { ISettings } from '@lam-agency/toolkit/slices/HomepageAtf';
import Form from '../Form';

interface IProps {
  settings: ISettings;
  setSettings: React.Dispatch<React.SetStateAction<ISettings>>;
}

const DesktopForm = ({ setSettings, settings }: IProps) => {
  return (
    <div className={styles.container}>
      <Draggable bounds="parent" cancel=".form">
        <div className={styles.widget}>
          <div className={styles.dragTab}>
            <div className={styles.dragLine} />
            <div className={styles.dragLine} />
          </div>

          <Form setSettings={setSettings} settings={settings} />
        </div>
      </Draggable>
    </div>
  );
};

export default DesktopForm;
