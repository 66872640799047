// extracted by mini-css-extract-plugin
export var container = "styles-module--container--18c80";
export var content = "styles-module--content--ff5e9";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--bd282";
export var header = "styles-module--header--cb939";
export var imageGrid = "styles-module--imageGrid--7121e";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";