import React from 'react';
import cn from 'classnames';
import { SVG, TSVG } from '@lam-agency/toolkit/components';
import {
  IntersectionAnimation,
  LayoutProvider,
  Link
} from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';
import { getWhiteIfBackgroundIsTransparent } from '@lam-agency/toolkit/utils';
import type { ILinkAndResourcesSection } from 'lam-toolkit-figma-plugin';

interface IProps {
  data: ILinkAndResourcesSection;
  borderTop?: boolean;
  delayOffset?: number;
}

const ResourceCards = ({
  data: { tiles, title },
  borderTop = false,
  delayOffset = 0
}: IProps) => {
  if (!tiles?.[0]) return null;

  return (
    <LayoutProvider
      className={cn(styles.container, { [styles.borderTop]: borderTop })}
      grid
    >
      <h4 className={cn('b1', styles.heading)}>{title}</h4>

      <div className={styles.cards}>
        {tiles.map(({ description, title, url }, i) => {
          const delay = (delayOffset + i) * 50;

          return (
            <IntersectionAnimation key={i} delay={delay} animation="fadeGrow">
              <div
                style={{ background: getWhiteIfBackgroundIsTransparent() }}
                className={cn(styles.cardWrapper, { [styles.locked]: !url })}
              >
                <div aria-hidden className={styles.background} />

                {url ? (
                  <Link url={url}>
                    <ResourceCard
                      description={description}
                      title={title}
                      icon={
                        url.includes('dropbox.com')
                          ? 'download'
                          : 'externalLink'
                      }
                    />
                  </Link>
                ) : (
                  <ResourceCard
                    description={description}
                    title={title}
                    icon="lock"
                  />
                )}
              </div>
            </IntersectionAnimation>
          );
        })}
      </div>
    </LayoutProvider>
  );
};

export default ResourceCards;

const ResourceCard = ({
  description,
  title,
  icon
}: {
  description: string;
  title: string;
  icon: TSVG;
}) => {
  return (
    <div className={cn(styles.card)}>
      <p className="caption">{description}</p>

      <div className={cn('b1', styles.cardBody)}>
        <p>{title}</p>

        <SVG className={styles.svg} svg={icon} />
      </div>
    </div>
  );
};
