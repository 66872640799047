// extracted by mini-css-extract-plugin
export var anchorLinks = "styles-module--anchorLinks--1a463";
export var background = "styles-module--background--ac525";
export var body = "styles-module--body--53c19";
export var contact = "styles-module--contact--1b07b";
export var contactBody = "styles-module--contactBody--fcb54";
export var contactBodyText = "styles-module--contactBodyText--e70e2";
export var container = "styles-module--container--a94f8";
export var desktop = "1340px";
export var ellipsis = "styles-module--ellipsis--a9033";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var layout = "styles-module--layout--df034";
export var link = "styles-module--link--d4311";
export var mobile = "400px";
export var mobileWidget = "styles-module--mobileWidget--cd88c";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var text = "styles-module--text--7c9d6";
export var textWrapper = "styles-module--textWrapper--013f8";
export var widget = "styles-module--widget--31b4c";
export var widgetContainer = "styles-module--widgetContainer--32498";
export var widgetWrapper = "styles-module--widgetWrapper--63bde";