import React from 'react';
import { Grid, IntersectionAnimation } from '@lam-agency/toolkit/components';
import { useBreakpoints } from '@lam-agency/toolkit/hooks';

import * as styles from './styles.module.scss';

const WebsitePrototype = () => {
  const { largeTablet } = useBreakpoints();

  return null;

  const prototypeDesktop =
    'https://www.figma.com/proto/ik9H0lmhKZq397Oa5qin9B/CLI_ABS%E2%84%A2_2024-(Community)?page-id=11107%3A68411&type=design&node-id=11334-35267&viewport=-3655%2C428%2C0.25&t=Ug3AiQn2Y0sciwpK-1&scaling=min-zoom&starting-point-node-id=11334%3A35267';
  const prototypeTouch =
    'https://www.figma.com/proto/ik9H0lmhKZq397Oa5qin9B/CLI_ABS%E2%84%A2_2024-(Community)?page-id=11107%3A68411&type=design&node-id=11334-35384&viewport=-4334%2C597%2C0.28&t=rUHOyPtZxwbjm4OO-1&scaling=min-zoom&starting-point-node-id=11334%3A35384';

  return (
    <div className={styles.container}>
      <Grid>
        <IntersectionAnimation className={styles.iframe}>
          <iframe
            title="Figma"
            src={`https://www.figma.com/embed?embed_host=share&url=${
              largeTablet ? prototypeDesktop : prototypeTouch
            }`}
          />
        </IntersectionAnimation>
      </Grid>
    </div>
  );
};

export default WebsitePrototype;
