import React from 'react';
import {
  ColorGradientTile,
  ColorTile,
  IGradient,
  IntersectionAnimation
} from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { IColor } from 'lam-toolkit-figma-plugin';

interface IProps {
  colorRows: (IColor[] | IGradient)[];
}

const ColourInspector = ({ colorRows }: IProps) => {
  return (
    <div className={styles.container}>
      {colorRows.map((row, i) => {
        const isGradient = 'gradient' in row;

        return (
          <div className={styles.row} key={i}>
            {!isGradient &&
              row.map(({ category, hex, id, name }) => (
                <IntersectionAnimation
                  key={id}
                  className={styles.cardContainer}
                  animation="fadeGrow"
                >
                  <ColorTile category={category} hex={hex} name={name} />
                </IntersectionAnimation>
              ))}

            {isGradient && (
              <IntersectionAnimation
                key={row.gradient}
                className={styles.cardContainer}
                animation="fadeGrow"
              >
                <ColorGradientTile
                  category={row.category}
                  gradient={row.gradient}
                  name={row.name}
                />
              </IntersectionAnimation>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ColourInspector;
