import { useMVT } from '../stores';
import { parseImagesFromFigma } from '../utils/figma';
import { useCurrentPageData } from './useCurrentPageData';

export const useFigmaImage = (imageStrings?: string[]) => {
  const { projectString } = useMVT();
  const { currentPageData } = useCurrentPageData();

  if (!imageStrings) {
    return [];
  }

  const imageItems = parseImagesFromFigma({
    images: imageStrings,
    supabaseProject: projectString,
    pageSlug: currentPageData?.slug || 'unknown'
  });

  return imageItems;
};
