import React, { useEffect, useRef, useState } from 'react';
import * as styles from './styles.module.scss';
import Form from '../Form';
import { ISettings } from '@lam-agency/toolkit/slices/HomepageAtf';
import cn from 'classnames';
import { SVG } from '@lam-agency/toolkit/components';

interface IProps {
  settings: ISettings;
  setSettings: React.Dispatch<React.SetStateAction<ISettings>>;
}

const MobileForm = ({ setSettings, settings }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formContentHeight, setFormContentHeight] = useState(200);

  const buttonText = isOpen ? `Close Editor` : `Open Editor`;

  const formContentRef = useRef<HTMLDivElement>(null);

  const calculateFormContentHeight = () => {
    if (!formContentRef.current) return;

    const height = formContentRef.current.clientHeight;

    setFormContentHeight(height);
  };

  useEffect(() => {
    calculateFormContentHeight();
    window.addEventListener(`resize`, calculateFormContentHeight);

    return () => {
      window.removeEventListener(`resize`, calculateFormContentHeight);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.widget}>
        <button
          onClick={() => setIsOpen((prev) => !prev)}
          className={cn(`button-text`, styles.button)}
        >
          {isOpen && <SVG className={styles.xIcon} svg="x" />}
          {buttonText}
        </button>

        <div
          className={styles.formContainer}
          style={{ height: isOpen ? `${formContentHeight}px` : 0 }}
        >
          <div ref={formContentRef} className={styles.formContent}>
            <Form setSettings={setSettings} settings={settings} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileForm;
