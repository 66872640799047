import React from 'react';
import {
  SliceConfig,
  PortableTextRenderer,
  IntersectionAnimation
} from '@lam-agency/toolkit/components';
import { IToolkitTextColumns } from '@lam-agency/sanity';
import { useInView } from 'react-intersection-observer';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { usePageColorScheme } from '@lam-agency/toolkit/hooks';

interface IProps {
  data: IToolkitTextColumns;
}

const TextColumns = ({ data: { sliceConfig, textColumns } }: IProps) => {
  const colorScheme = usePageColorScheme();

  const { ref, inView } = useInView({
    rootMargin: `-90px`,
    triggerOnce: true
  });

  const isThreeColumnLayout = textColumns.length === 3;

  return (
    <div
      ref={ref}
      className={cn(styles.grid, {
        [styles.threeColumns]: isThreeColumnLayout
      })}
    >
      {textColumns.map((column, i) => (
        <div
          className={cn(styles.column, {
            [styles.lightScheme]: colorScheme === `light`
          })}
          key={i}
        >
          <SliceConfig config={sliceConfig}>
            <IntersectionAnimation trigger={inView} delay={i * 150}>
              <div className={styles.content}>
                <PortableTextRenderer
                  className={styles.leftColumn}
                  rawText={column._rawLeftColumn}
                />
                <PortableTextRenderer rawText={column._rawRightColumn} />
              </div>
            </IntersectionAnimation>
          </SliceConfig>
        </div>
      ))}
    </div>
  );
};

export default TextColumns;
