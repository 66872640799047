import React from 'react';
import * as Slices from '@lam-agency/toolkit/slices';
import * as CustomSlices from '@lam-agency/toolkit/custom/slices';
import { ISliceData, TSliceField } from 'lam-toolkit-figma-plugin';
import * as styles from './styles.module.scss';

interface IProps {
  sliceData: ISliceData;
}

interface IFieldDictionary {
  [key: string]: any;
}

const FigmaSlice = ({ sliceData }: IProps) => {
  if (!sliceData) {
    return null;
  }

  const isCustomSlice = sliceData.name.split(':')?.[1] === 'Custom';

  const parsedName = isCustomSlice
    ? sliceData.name.split(':')[2]
    : sliceData.name.split(':')?.[1];

  const getSlice = () => {
    const customSlice =
      CustomSlices?.[`Figma${parsedName}` as keyof typeof CustomSlices];
    if (customSlice) {
      console.log(`⚠ Slice override found for ${parsedName}`);
      return customSlice as any;
    }

    const defaultSlice = Slices?.[`Figma${parsedName}` as keyof typeof Slices];
    if (defaultSlice) {
      return defaultSlice as any;
    }

    console.error(`No slice found for ${parsedName}`);
    return null;
  };

  const Slice = getSlice();

  const fields: IFieldDictionary = {};

  sliceData.fields.forEach((field) => {
    const value = (field as TSliceField).value;
    fields[field.name] = value;
  });

  if (!Slice) {
    return null;
  }

  return (
    <section id={`${sliceData.name}`} className={styles.container}>
      <Slice {...fields} />
    </section>
  );
};

export default FigmaSlice;
