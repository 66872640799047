// extracted by mini-css-extract-plugin
export var card = "styles-module--card--ed094";
export var cardBody = "styles-module--cardBody--55cbd";
export var cardHeader = "styles-module--cardHeader--692cd";
export var copyButton = "styles-module--copyButton--9d9ff";
export var dark = "styles-module--dark--942ee";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var light = "styles-module--light--aad9c";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var split = "styles-module--split--ab139";
export var tablet = "769px";
export var text = "styles-module--text--390c2";