// extracted by mini-css-extract-plugin
export var abbreviation = "styles-module--abbreviation--c0fc3";
export var container = "styles-module--container--75220";
export var content = "styles-module--content--3f160";
export var desktop = "1340px";
export var disabled = "styles-module--disabled--a4068";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";