import React from 'react';
import * as styles from './styles.module.scss';
import { usePaletteContext } from '../../context';
import cn from 'classnames';
import { TPaletteElement } from '../../types';

interface IProps {
  children: JSX.Element;
  type: TPaletteElement;
  id: string;
  className?: string;
}

const CopyElementToPalette = ({ children, type, id, className }: IProps) => {
  const { setNotification, setPaletteContent } = usePaletteContext();

  const updateNotification = (newMessage: string) => {
    setNotification((prev) => ({
      content: newMessage,
      keyToggle: prev.keyToggle === 0 ? 1 : 0
    }));
  };

  const copyImageToClipboard = async (imageUrl: string) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    if (blob.type !== 'image/png') {
      const img = await createImageBitmap(blob);
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        throw new Error('Failed to get canvas context');
      }

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      canvas.toBlob((canvasBlob) => {
        if (!canvasBlob) {
          throw new Error('Failed to get canvas blob');
        }

        navigator.clipboard.write([
          new ClipboardItem({
            [canvasBlob.type]: canvasBlob
          })
        ]);
      });
    } else {
      navigator.clipboard.write([
        new ClipboardItem({
          [blob.type]: blob
        })
      ]);
    }
  };

  const copyContentToClipboard = () => {
    switch (type) {
      case 'color':
        const hexCode = children.props.hex;
        navigator.clipboard.writeText(hexCode);
        updateNotification(`Copied color hex to clipboard`);
        return;

      case 'text':
        const textContent = children.props.children;
        navigator.clipboard.writeText(textContent);
        updateNotification(`Copied text to clipboard`);
        return;

      case 'video':
        const videoUrl = children.props.src;
        navigator.clipboard.writeText(videoUrl);
        updateNotification(`Copied video URL to clipboard`);
        return;

      case 'image':
        const imageUrl = children.props.src;
        copyImageToClipboard(imageUrl);
        updateNotification(`Copied image to clipboard`);
        return;

      case 'logo':
        const logoUrl = children.props.src;
        copyImageToClipboard(logoUrl);
        updateNotification(`Copied image to clipboard`);
        return;

      default:
        return;
    }
  };

  const copyContentToPalette = () => {
    switch (type) {
      case 'text':
        setPaletteContent((prev) => {
          for (let i = 0; i < prev.text.length; i++) {
            if (prev.text[i].id === id) {
              updateNotification(`Already copied to palette`);
              return prev;
            }
          }

          updateNotification(`Copied text to palette`);
          return {
            ...prev,
            text: [
              ...prev.text,
              {
                id,
                type: 'Text',
                children: children.props.children,
                style: children.props.className
              }
            ]
          };
        });
        return;

      case 'color':
        setPaletteContent((prev) => {
          for (let i = 0; i < prev.colors.length; i++) {
            if (prev.colors[i].id === id) {
              updateNotification(`Already copied to palette`);
              return prev;
            }
          }

          updateNotification(`Copied color to palette`);
          return {
            ...prev,
            colors: [
              ...prev.colors,
              {
                id,
                type: 'Color',
                hex: children.props.hex,
                name: children.props.name,
                textColor: children.props.textColor
              }
            ]
          };
        });
        return;

      case 'image':
        setPaletteContent((prev) => {
          for (let i = 0; i < prev.images.length; i++) {
            if (prev.images[i].id === id) {
              updateNotification(`Already copied to palette`);
              return prev;
            }
          }

          updateNotification(`Copied image to palette`);
          return {
            ...prev,
            images: [
              ...prev.images,
              {
                id,
                type: 'Image',
                url: children.props.src
              }
            ]
          };
        });
        return;

      case 'logo':
        setPaletteContent((prev) => {
          for (let i = 0; i < prev.logos.length; i++) {
            if (prev.logos[i].id === id) {
              updateNotification(`Already copied to palette`);
              return prev;
            }
          }

          updateNotification(`Copied logo to palette`);
          return {
            ...prev,
            logos: [
              ...prev.logos,
              {
                id,
                type: 'Logo',
                url: children.props.src
              }
            ]
          };
        });
        return;

      case 'video':
        setPaletteContent((prev) => {
          for (let i = 0; i < prev.videos.length; i++) {
            if (prev.videos[i].id === id) {
              updateNotification(`Already copied to palette`);
              return prev;
            }
          }

          updateNotification(`Copied video to palette`);
          return {
            ...prev,
            videos: [
              ...prev.videos,
              {
                id,
                type: 'Video',
                url: children.props.src
              }
            ]
          };
        });
        return;

      default:
        return;
    }
  };

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (e.altKey) {
      copyContentToClipboard();
      return;
    }

    copyContentToPalette();
  };

  return (
    <div onClick={handleClick} className={cn(styles.container, className)}>
      {children}
    </div>
  );
};

export default CopyElementToPalette;
