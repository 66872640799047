import React from 'react';
import cn from 'classnames';
import { Button, LayoutProvider } from '@lam-agency/toolkit/components';
import * as styles from './styles.module.scss';
import { SVG } from '@lam-agency/toolkit/components';
import { usePages } from '@lam-agency/toolkit/hooks';
import { Link } from 'gatsby';

const Footer = () => {
  const { pageCategories } = usePages();

  return (
    <footer className={styles.container}>
      <LayoutProvider paddingX paddingY grid className={styles.grid}>
        <SVG svg="logo" className={styles.logo} />

        <div className={styles.pages}>
          {pageCategories.map((category, i) => (
            <div key={i}>
              <h2 className={cn('caption', styles.categoryTitle)}>
                {category.title}
              </h2>

              <div className={styles.categoryPages}>
                {category.pages.map((page, j) => (
                  <Link
                    className={cn('button', styles.pageLink)}
                    key={j}
                    to={`/${page.slug}`}
                  >
                    {page.name}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className={styles.contentContainer}>
          <Button
            to="http://www.loveandmoney.agency/toolkit"
            className={styles.button}
            color="white"
          >
            Made with ToolKit™
          </Button>

          <p className={cn(styles.year, 'caption')}>
            © {new Date().getFullYear()}
          </p>
        </div>
      </LayoutProvider>
    </footer>
  );
};

export default Footer;
