import React from 'react';
import cn from 'classnames';
import { LayoutProvider, TextAnimation } from '@lam-agency/toolkit/components';

import * as styles from './styles.module.scss';
import { ISliceHeroText } from 'lam-toolkit-figma-plugin';
import { getTextThemeByBackgroundHex } from '@lam-agency/toolkit/utils';

const FigmaHeroText = ({ body, backgroundColorHex }: ISliceHeroText) => {
  const getTextColor = () => {
    if (!backgroundColorHex) {
      return '';
    }

    const textTheme = getTextThemeByBackgroundHex(backgroundColorHex);

    return textTheme === 'dark' ? 'var(--color-black)' : 'var(--color-white)';
  };

  if (!body) return null;

  return (
    <div style={{ background: backgroundColorHex, color: getTextColor() }}>
      <LayoutProvider grid paddingX paddingY>
        <div className={styles.textWrapper}>
          <TextAnimation
            text={body.content}
            className={cn(body.typestyle, styles.text)}
            animation="typed"
          />
        </div>
      </LayoutProvider>
    </div>
  );
};

export default FigmaHeroText;
