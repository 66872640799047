import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import PaletteColorTile from './components/PaletteColorTile';
import PaletteText from './components/PaletteText';
import PaletteVideo from './components/PaletteVideo';
import PaletteImage from './components/PaletteImage';
import PaletteLogo from './components/PaletteLogo';
import data from './data';
import Notification from './components/Notification';
import Palette from './components/Palette';
import Theme from './components/Theme';
import CreateModal from './components/CreateModal';
import PaletteContextProvider from './context';

const PaletteSlice = () => (
  <section className={styles.container}>
    <PaletteContextProvider>
      <ToolkitPalette />
    </PaletteContextProvider>
  </section>
);

export default PaletteSlice;

const ToolkitPalette = () => {
  return (
    <>
      <Theme />
      <div className={styles.pageLayout}>
        <div className={styles.container}>
          <div className={styles.titleAndPaletteButton}>
            <h1 className={styles.h1}>Toolkit™ Palette</h1>
          </div>

          <h2 className={styles.h2}>Logo</h2>
          <div className={cn(styles.images, styles.section)}>
            {data.paletteLogos?.map((logo, i) => (
              <PaletteLogo {...logo} key={i} />
            ))}
          </div>

          <h2 className={styles.h2}>Colors</h2>
          <div className={cn(styles.colorTiles, styles.section)}>
            {data.paletteColorTiles?.map((tile, i) => (
              <PaletteColorTile {...tile} key={i} />
            ))}
          </div>

          <h2 className={styles.h2}>Text</h2>
          <div className={styles.section}>
            {data.paletteTexts?.map((text, i) => (
              <PaletteText {...text} key={i}>
                {text.children}
              </PaletteText>
            ))}
          </div>

          <h2 className={styles.h2}>Images</h2>
          <div className={cn(styles.images, styles.section)}>
            {data.paletteImages?.map((image, i) => (
              <PaletteImage {...image} key={i} />
            ))}
          </div>

          <h2 className={styles.h2}>Videos</h2>
          <div className={cn(styles.videos, styles.section)}>
            {data.paletteVideos?.map((video, i) => (
              <PaletteVideo {...video} key={i} />
            ))}
          </div>

          {/* Todo - typography, art direction, graphic language? How to capture these? */}
        </div>

        <Palette />
      </div>

      <Notification />

      <CreateModal />
    </>
  );
};
