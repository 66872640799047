// extracted by mini-css-extract-plugin
export var activePage = "styles-module--activePage--eb804";
export var desktop = "1340px";
export var disabledLink = "styles-module--disabledLink--c3b61";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var lightColorScheme = "styles-module--lightColorScheme--65ce6";
export var listItem = "styles-module--listItem--9c7bf";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";