import React from 'react';
import cn from 'classnames';
import {
  ImageGrid,
  LayoutProvider,
  SliceHeader,
  TextColumns
} from '@lam-agency/toolkit/components';
import { TSVG } from '@lam-agency/common/components';

import * as styles from './styles.module.scss';

interface IProps {
  data: {
    fields: {
      heading?: string;
      topBody1?: string;
      topBody2?: string;
      bottomBody1?: string;
      bottomBody2?: string;
    };
  };
}

const FigmaTypeAndColour = ({ data: { fields } }: IProps) => {
  const { heading, topBody1, topBody2, bottomBody1, bottomBody2 } =
    fields || {};

  const topImageGridItems = [
    {
      image: '/mvt-images/colour/type-and-colour-1.jpg',
      caption: '‘Almost White’ text is accessible on ‘Almost Black’.',
      captionIcon: 'check' as TSVG,
      captionIconColor: '#07CB9C',
      borderRadius: true
    },
    {
      image: '/mvt-images/colour/type-and-colour-2.jpg',
      caption: '‘Almost White’ text is accessible on ‘Grey’.',
      captionIcon: 'check' as TSVG,
      captionIconColor: '#07CB9C',
      borderRadius: true
    },
    {
      image: '/mvt-images/colour/type-and-colour-3.jpg',
      caption: '‘Almost Black’ text is accessible on ‘Almost White’.',
      captionIcon: 'check' as TSVG,
      captionIconColor: '#07CB9C',
      borderRadius: true
    },
    {
      image: '/mvt-images/colour/type-and-colour-4.jpg',
      caption: '‘Black-40’ is accessible on ‘Almost Black’',
      captionIcon: 'check' as TSVG,
      captionIconColor: '#07CB9C',
      borderRadius: true
    }
  ];

  const bottomImageGridItems = [
    {
      image: '/mvt-images/colour/type-and-colour-5.jpg',
      caption: '‘Red’ is not accessible on ‘Yellow’.',
      captionIcon: 'cross' as TSVG,
      captionIconColor: '#F75757',
      borderRadius: true
    },
    {
      image: '/mvt-images/colour/type-and-colour-6.jpg',
      caption: '‘Pink’ is not accessible on ‘Blue’.',
      captionIcon: 'cross' as TSVG,
      captionIconColor: '#F75757',
      borderRadius: true
    },
    {
      image: '/mvt-images/colour/type-and-colour-7.jpg',
      caption: '‘Yellow’ is not accessible on ‘Red’.',
      captionIcon: 'cross' as TSVG,
      captionIconColor: '#F75757',
      borderRadius: true
    },
    {
      image: '/mvt-images/colour/type-and-colour-8.jpg',
      caption: '‘Blue’ is not accessible on ‘Pink’.',
      captionIcon: 'cross' as TSVG,
      captionIconColor: '#F75757',
      borderRadius: true
    },
    {
      image: '/mvt-images/colour/type-and-colour-9.jpg',
      caption: '‘Pink’ is not accessible on ‘Yellow’.',
      captionIcon: 'cross' as TSVG,
      captionIconColor: '#F75757',
      borderRadius: true
    },
    {
      image: '/mvt-images/colour/type-and-colour-10.jpg',
      caption: '‘Pink’ is not accessible on ‘Red’.',
      captionIcon: 'cross' as TSVG,
      captionIconColor: '#F75757',
      borderRadius: true
    },
    {
      image: '/mvt-images/colour/type-and-colour-11.jpg',
      caption: '‘Red’ is not accessible on ‘Pink’.',
      captionIcon: 'cross' as TSVG,
      captionIconColor: '#F75757',
      borderRadius: true
    },
    {
      image: '/mvt-images/colour/type-and-colour-12.jpg',
      caption: '‘Yellow’ is not accessible on ‘Blue’.',
      captionIcon: 'cross' as TSVG,
      captionIconColor: '#F75757',
      borderRadius: true
    }
  ];

  return (
    <LayoutProvider paddingX>
      <LayoutProvider grid>
        <SliceHeader className={styles.header} data={{ heading }} />
      </LayoutProvider>

      <TextColumns
        data={{
          heading: topBody1,
          bodyLeft: topBody2
        }}
        removeBorder
      />

      <ImageGrid
        className={cn(styles.imageGrid, styles.borderBottom)}
        items={topImageGridItems}
        columns={4}
      />

      <TextColumns
        data={{
          heading: bottomBody1,
          bodyLeft: bottomBody2
        }}
      />

      <ImageGrid
        className={styles.imageGrid}
        items={bottomImageGridItems}
        columns={4}
      />
    </LayoutProvider>
  );
};

export default FigmaTypeAndColour;
