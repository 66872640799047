import { useMVT } from '../stores';
import { getImageSrcFromFigmaImageString } from '../utils/figma';
import { useCurrentPageData } from './useCurrentPageData';

export const useFigmaImageSrc = (imageString: string) => {
  const { projectString } = useMVT();
  const { currentPageData } = useCurrentPageData();

  const imageSrc = getImageSrcFromFigmaImageString({
    image: imageString,
    supabaseProject: projectString,
    pageSlug: currentPageData?.slug || 'unknown'
  });

  return imageSrc || '';
};
