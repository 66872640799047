import React from 'react';
import cn from 'classnames';
import * as styles from './styles.module.scss';
import { IBentoSection } from 'lam-toolkit-figma-plugin/dist/figma/sliceParsers/bentoBox';
import { BentoBlock } from '../BentoBlock';
import LayoutProvider from '../../Common/LayoutProvider';

interface IProps extends IBentoSection {
  className?: string;
}

export const BentoSection = ({ blocks, variant, className }: IProps) => {
  return (
    <LayoutProvider grid className={cn(styles.grid, className)}>
      {blocks.map((block, i) => {
        const variantClass = `${variant}${block.type}`;

        return (
          <BentoBlock
            key={i}
            {...block}
            className={cn(styles.block, styles[variantClass])}
          />
        );
      })}
    </LayoutProvider>
  );
};
