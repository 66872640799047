// extracted by mini-css-extract-plugin
export var character = "styles-module--character--dcd8d";
export var characters = "styles-module--characters--7324f";
export var container = "styles-module--container--9541b";
export var control = "styles-module--control--29f18";
export var controlWrapper = "styles-module--controlWrapper--a8c5e";
export var desktop = "1340px";
export var giant = "2200px";
export var header = "styles-module--header--b7304";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var lowercase = "styles-module--lowercase--ed451";
export var mobile = "400px";
export var preview = "styles-module--preview--8215e";
export var selected = "styles-module--selected--879ab";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";