import React from "react";
import { Canvas } from "@react-three/fiber";
import { AsciiRenderer } from "@react-three/drei";
import ShoeModel from "./components/ShoeModel";
import { ISettings } from "../..";

interface IProps {
  settings: ISettings;
}

const AsciiCanvas = ({ settings }: IProps) => {
  return (
    <Canvas>
      <color attach="background" args={[`#000000`]} />
      <pointLight position={[-10, -10, -10]} />

      <ShoeModel />

      <AsciiRenderer
        key={settings.characters} // Need this to apply selected colors when characters change
        characters={` ${settings.characters}`}
        fgColor={settings.textColor}
        bgColor={`transparent`}
      />
    </Canvas>
  );
};

export default AsciiCanvas;
