import React from 'react';
import { useApp } from '@lam-agency/toolkit/hooks';
import * as styles from './styles.module.scss';
import cn from 'classnames';

export interface INotification {
  message: string;
  keyToggle: 0 | 1;
}

const Notification = () => {
  const { notification } = useApp();

  if (!notification.message) return null;

  return (
    <div className={styles.container} key={notification.keyToggle}>
      <p className={cn(`b1`, styles.notification)}>{notification.message}</p>
    </div>
  );
};

export default Notification;
